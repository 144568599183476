import { IOrderState } from "Models/OrderModels";

interface IProps {
	order: IOrderState;
	lotNumberRef: any;
	lotNumber: string;
	maxLengthReferenceNumber: number;

	setOrder: (order: any) => void;
}

const ReferenceNumber: React.FunctionComponent<IProps> = ({
	order,
	lotNumberRef,
	lotNumber,
	maxLengthReferenceNumber,

	setOrder,
}) => {
	return (
		<>
			<div
				className="form-group"
				data-testid="lotNumber-wrapper"
				style={{ marginBottom: "12px" }}
			>
				<input
					ref={lotNumberRef}
					type="text"
					className="form-control"
					name="lotNumber"
					id="lotNumber"
					data-testid="lotNumber-input"
					value={lotNumber ? lotNumber : ""}
					data-empty={!lotNumber}
					maxLength={maxLengthReferenceNumber}
					onChange={(e) => setOrder({ ...order, lotNumber: e.target.value })}
					onBlur={(e) =>
						setOrder({ ...order, lotNumber: e.target.value.trim() })
					}
				/>
				<label
					data-testid="lotNumber-label"
					className="form-control-label"
					htmlFor="lotNumber"
				>
					Reference Number
				</label>
			</div>
		</>
	);
};

export default ReferenceNumber;
