import Accordion from "Components/Shared/Accordion";
import { useContext, useEffect, useState } from "react";
import LabelDimensions from "./GeneralLabelTemplateSettingsComponents/LabelDimensions";
import { useFetchLanguages } from "Hooks/queryHooks/useFetchLanguages";
import { GlobalDispatch } from "Containers/Home";
import LanguagePicker from "./GeneralLabelTemplateSettingsComponents/LanguagePicker";
import DefaultSettings from "./GeneralLabelTemplateSettingsComponents/DefaultSettings";
import FontSettings from "./GeneralLabelTemplateSettingsComponents/FontSettings";
import { LabelTemplateDispatch } from "Containers/LabelTemplate";
import LabelMargins from "./LabelTemplateDrawingAreaComponents/LabelMargins";
import { useParams } from "react-router-dom-v5-compat";

const GeneralLabelSettings = () => {
	const { user, setError } = useContext(GlobalDispatch);
	const { account_type_id } = user;

	const { labelTemplateId: labelTemplateIdFromParams } = useParams() as any;

	const {
		accordionIsOpen,
		setAccordionIsOpen,
		template,
		setShowMissingData,
		showMissingData,
		checkGeneralLabelSettings,
	} = useContext(LabelTemplateDispatch);

	const isDataMissing = (itemToCheck: string) => {
		return showMissingData && checkGeneralLabelSettings().includes(itemToCheck);
	};

	const [fontState, setFontState] = useState({
		style: template?.fonts?.style || "",
		size: template?.fonts?.size || 0,
		lineSpacing: template?.fonts?.lineSpacing || 0,
		blackGround: template?.fonts?.blackGround || false,
	});

	const [showExpandButton, setShowExpandButton] = useState(
		labelTemplateIdFromParams
	);
	const [turnOverflowHidden, setTurnOverflowHidden] = useState(true);
	const { languages } = useFetchLanguages(account_type_id, setError);
	const [accordionSpeed, setAccordionSpeed] = useState(
		labelTemplateIdFromParams ? 200 : 0
	);

	useEffect(() => {
		if (accordionIsOpen) {
			setShowExpandButton(false);

			setTimeout(() => {
				setTurnOverflowHidden(true);
			}, accordionSpeed);
		} else {
			setTimeout(() => {
				setShowExpandButton(true);
			}, accordionSpeed);

			setTurnOverflowHidden(false);
		}
	}, [accordionIsOpen]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// set accordion speed back to 200 if new label template page was entered
		if (accordionSpeed === 0 && checkGeneralLabelSettings().length === 0) {
			setAccordionSpeed(200);
		}
	}, [checkGeneralLabelSettings().length]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="general-label-settings">
			<div
				className="flex justify-between bg--light"
				style={{ padding: "0 20px", height: "38px" }}
			>
				<div className="pretitle mt--sm mb--sm">General Label Settings</div>
				{showExpandButton && (
					<button
						className="pill pill--info pill--sm cursor-pointer expand-button"
						onClick={() => {
							setAccordionIsOpen(true);
						}}
					>
						Expand
					</button>
				)}
			</div>

			<Accordion
				isOpen={accordionIsOpen}
				speed={accordionSpeed}
				className={`${turnOverflowHidden && "accordion-style"}`}
			>
				<div className="">
					<div className="bg--light" style={{ padding: "0px 20px" }}>
						<div className="flex">
							<LabelDimensions />
							<LabelMargins />
							<FontSettings fontState={fontState} setFontState={setFontState} />
						</div>
						<div className="pretitle mt--sm mb--sm">
							General presets adjustable by sections
						</div>
						<div className="flex">
							<div
								className={`form-group ${
									isDataMissing("Font size") && "has-danger"
								} mb--base`}
							>
								<input
									type="number"
									className="form-control"
									name="fontSize"
									id="fontSize"
									value={fontState.size <= 0 ? "" : fontState.size}
									data-empty={fontState.size <= 0}
									onChange={(e) =>
										setFontState({
											...fontState,
											size: Number(e.target.value),
										})
									}
									min={4.5}
									step={0.5}
								/>
								<label className="form-control-label" htmlFor="fontSize">
									Font size (pt)
								</label>
							</div>
							<DefaultSettings />
						</div>
					</div>
					<div className="bg--light">
						{languages && <LanguagePicker languages={languages} />}
					</div>

					<div
						className="flex"
						style={{
							justifyContent: "flex-end",
							backgroundColor: "transparent",
							marginTop: "-7.5px",
							marginRight: "20px",
						}}
					>
						<button
							className="pill pill--info pill--sm cursor-pointer collapse-button"
							onClick={() => {
								if (accordionSpeed === 0) {
									setAccordionSpeed(200);
								}

								if (checkGeneralLabelSettings().length > 0) {
									setShowMissingData(true);
								} else {
									setAccordionIsOpen(false);
								}
							}}
						>
							Collapse
						</button>
					</div>
				</div>
			</Accordion>
		</div>
	);
};

export default GeneralLabelSettings;
