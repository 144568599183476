import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { toast } from "react-toastify";

import Icon from "Components/Shared/Icon";
import Loading from "Components/Shared/Loading";

import useOnClickOutside from "Hooks/outsideClickHook";
import useDataApi from "Hooks/fetchHook";

import {
	OrderStatusesFlow,
	shippingMethodsTransform,
} from "Models/OrderModels";
import { EndpointPrefix } from "Models/UserModels";

import { GlobalDispatch } from "Containers/Home";

import LabelMakerShipment from "Components/Orders/LabelMakerShipment";
import { useFetchShippingMethods } from "Hooks/queryHooks/useFetchShippingMethods";

interface IProps {
	labelMakerData: any;

	setLabelMakerData: (data: any) => void;
	onChangeStatus?: (status?: number) => void;
}

const LabelMakerEditStatusModal: React.FunctionComponent<IProps> = ({
	labelMakerData,

	setLabelMakerData,
	onChangeStatus = () => {},
}) => {
	const { user, setError } = useContext(GlobalDispatch);
	const { account_type_id } = user;

	const { shippingMethods } = useFetchShippingMethods(
		account_type_id,
		setError
	);

	let shippingMethodsObj = shippingMethodsTransform(shippingMethods);

	const [status, setStatus] = useState(-1);
	const [modalIsDirty, setModalIsDirty] = useState(false);

	const updateStatus = useDataApi();

	const statusModalRef = useRef(null) as any;

	const { labelMakerOrder } = labelMakerData;

	let orderId: any;
	let lotNumber: any;

	if (labelMakerOrder) {
		({ orderId, lotNumber } = labelMakerOrder);
	}

	useEffect(() => {
		const { status } = labelMakerData;
		if (status) {
			setStatus(status);
		}
	}, [labelMakerData]); // eslint-disable-line react-hooks/exhaustive-deps

	// ************************* START - Update order status ******************************

	useEffect(() => {
		const { data } = updateStatus;
		if (data.status === "ok") {
			toast.success("Order updated.");

			onChangeStatus(status);
		}
	}, [updateStatus.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = updateStatus;
		if (error) {
			toast.error(`Unable to change order status. ${error}`);
		}
	}, [updateStatus.error]); // eslint-disable-line react-hooks/exhaustive-deps

	// ************************* END - Update order status ******************************

	// ************************* START - Functions ******************************

	const saveStatus = () => {
		if (status === OrderStatusesFlow.PLACED.code) {
			alert("You must choose a valid status!");
		}

		const { status: currentStatus } = labelMakerData;
		if (currentStatus !== status) {
			const data = {
				status: parseInt(status as any, 10),
				order_ids: [orderId],
			} as any;
			updateStatus.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/status`,
				data,
				"PUT"
			);
		}
	};

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onCloseModal();
		}
	};

	const onCloseModal = () => {
		if (modalIsDirty) {
			if (
				window.confirm(
					"Unsaved data will be lost. Are you sure you want to leave?"
				)
			) {
				setLabelMakerData({});
			} else return;
		} else {
			setLabelMakerData({});
		}
	};

	useOnClickOutside(statusModalRef, useCallback(onCloseModal, [onCloseModal])); // eslint-disable-line react-hooks/exhaustive-deps

	// ************************* END - Functions ******************************

	return (
		<div className="modal" onKeyDown={(e) => onKeyDown(e)}>
			<div
				className="modal-content block-center show suggestedCareInstructionsModal"
				tabIndex={-1}
				ref={statusModalRef}
				id="statusModal"
			>
				<Loading
					show={updateStatus.isLoading}
					text={"Loading..."}
					imgClass="block-center"
					divClass="main__content"
				/>
				<div>
					<button
						className="drawer__close btn-no-style"
						data-dismiss="drawer"
						aria-label="Close"
						onClick={onCloseModal}
					>
						<Icon name="cross-rounded" />
					</button>
					<header className="drawer__header">
						<h3 className="drawer__title">
							Editing order <b>{lotNumber}</b>
						</h3>
					</header>
				</div>
				<div
					className="form-group flex flex-column height-100"
					style={{
						marginBottom: 0,
					}}
				>
					<label className="form-control-label is-static" htmlFor="status">
						Status
					</label>
					<select
						value={status}
						className="form-control"
						id="status"
						autoFocus={true}
						onChange={(event) => setStatus(Number(event.target.value))}
					>
						<option key={"---"} value={"---"} style={{ display: "none" }}>
							---
						</option>
						{Object.values(OrderStatusesFlow).map((value: any) => {
							return (
								<option key={value.code} value={value.code}>
									{value.name}
								</option>
							);
						})}
					</select>
					<div className="flex flex-center-both-axis">
						<button
							className="button button--primary button--block"
							style={{ margin: "20px 0", width: "30%" }}
							onClick={saveStatus}
						>
							Save status
						</button>
					</div>

					{(status === OrderStatusesFlow.SHIPPED.code ||
						status === OrderStatusesFlow.PARTIALLY_SHIPPED.code) && (
						<LabelMakerShipment
							status={status}
							orderId={orderId}
							shippingMethodsObj={shippingMethodsObj}
							labelMakerData={labelMakerData}
							setModalIsDirty={setModalIsDirty}
							onCloseModal={onCloseModal}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default LabelMakerEditStatusModal;
