import { useContext } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { GlobalDispatch } from "../../Containers/Home";
import { QUERY_KEYS } from "../../react-query/constants";
import {
	FETCH_GET_PARAMS,
	URL_BASE,
	CustomError,
} from "../../Models/QueryModels";
import useErrorHandler from "Hooks/queryHooks/useErrorHandler";
import {
	EndpointPrefix,
	USER_ACCOUNT_TYPE_VALUES,
} from "../../Models/UserModels";
import { IOrderState } from "Models/OrderModels";

async function manageError(response: Response) {
	if (!response.ok) {
		let responseJSON = await response.json();
		throw new CustomError(responseJSON.message, response.status);
	}
	return response;
}

let url = "";

async function getLabelTemplatesInitialValues(
	account_type_id: USER_ACCOUNT_TYPE_VALUES,
	labelTemplateId: any
): Promise<any> {
	url = `${URL_BASE.address}/${EndpointPrefix[account_type_id]}/data/labelTemplateInitialValues?labelTemplateId=${labelTemplateId}`;
	const result = await fetch(url, FETCH_GET_PARAMS).then(manageError);
	return await result.json();
}

export function useFetchLabelTemplatesInitialValues(order: IOrderState): any {
	const { setError } = useContext(GlobalDispatch);
	const navigate = useNavigate();

	const fallback: any = {
		madeIns: [],
		fabricContent: [],
		fibers: [],
		careInstructions: [],
		translationLanguages: [],
	};
	const errorHandler = useErrorHandler();
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const labelTemplateId = order?.labelTemplate?.id;
	const enabledCriteria =
		!!order?.labelTemplate?.id && order?.labelTemplate?.id !== -1;

	const {
		isLoading,
		data = fallback,
		fetchStatus,
	} = useQuery(
		[
			QUERY_KEYS.INITIAL_VALUES_LABEL_TEMPLATES,
			account_type_id,
			labelTemplateId,
		],
		() => getLabelTemplatesInitialValues(account_type_id, labelTemplateId),
		{
			enabled: enabledCriteria,
			onSuccess: (data) => {
				return data;
			},
			onError: (error: CustomError) => {
				let errorMessage = errorHandler(error.status, error.message, url);
				toast.error(
					`Unable to get label template initial values. ${errorMessage}`
				);
				setError({
					title: "Something Went Wrong",
					text: "Please try again later",
					primary: {
						text: "Back to orders",
						action: () => navigate(`/orders`),
					},
					secondary: {
						text: "",
						action: () => {},
					},
				});
			},
			cacheTime: 86400000, // 1 day
			staleTime: 86400000, // 1 day
		}
	);

	return {
		labelTemplateInitialValues: data.message,
		isLoadingLabelTemplateInitialValues: isLoading && fetchStatus !== "idle",
	};
}
