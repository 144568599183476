import { Dispatch, FC, SetStateAction, useContext, useEffect } from "react";
import DropDownSelection from "../SharedComponents/DropDownSelection";
import { LabelTemplateDispatch } from "../../../Containers/LabelTemplate";
import { ITemplateState } from "Models/TemplateModels";

interface IProps {
	fontState: {
		style: any;
		size: any;
		lineSpacing: any;
		blackGround: any;
	};

	setFontState: Dispatch<
		SetStateAction<{
			style: string;
			size: number;
			lineSpacing: number;
			blackGround: boolean;
		}>
	>;
}

const FontSettings: FC<IProps> = ({ fontState, setFontState }) => {
	const fonts = [
		"AkzidenzGroteskBQ-Light",
		"Arial",
		"AvenirLTStd-Book",
		"AWType05-Light",
		"FaktPro-Normal",
		"HelveticaNeueLTStd-Lt",
		"HelveticaNeueLTStd-Bd",
		"HelveticaNeueLTStd-Md",
		"HelveticaNeueLTStd-Roman",
		"SourceHanSansCN-Medium",
		"SourceHanSansJP-Normal",
		"VectoraLt",
	];

	const { setTemplate, checkGeneralLabelSettings, showMissingData } =
		useContext(LabelTemplateDispatch);

	const isDataMissing = (itemToCheck: string) => {
		return showMissingData && checkGeneralLabelSettings().includes(itemToCheck);
	};

	useEffect(() => {
		setTemplate((prevTemplate: ITemplateState) => ({
			...prevTemplate,
			fonts: fontState,
		}));
	}, [fontState]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="flex-1 ml--sm">
			{/* <div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>Font settings</span>
				<HelpTooltip helpFor="FontSettings" />
			</div> */}
			<fieldset className="flex">
				<div>
					<DropDownSelection
						name="Fonts"
						options={fonts}
						state={fontState}
						setState={setFontState}
						item={null}
					/>
					<div
						className={`form-group ${
							isDataMissing("Line space") && "has-danger"
						} mb--base`}
					>
						<input
							type="number"
							className="form-control"
							name="lineSpacing"
							id="lineSpacing"
							value={fontState.lineSpacing <= 0 ? "" : fontState.lineSpacing}
							data-empty={fontState.lineSpacing <= 0}
							onChange={(e) =>
								setFontState({
									...fontState,
									lineSpacing: Number(e.target.value),
								})
							}
							min={1}
							step={0.25}
						/>
						<label className="form-control-label" htmlFor="lineSpacing">
							Line space (pt)
						</label>
					</div>
				</div>
				<div className="mr--sm">
					<div className="form-group">
						<div className="form-check">
							<input
								className="form-check__input"
								type="checkbox"
								checked={fontState.blackGround ? true : false}
								name="blackGroundCheckbox"
								id="blackGroundCheckbox"
								onChange={(e) =>
									setFontState({
										...fontState,
										blackGround: !fontState.blackGround,
									})
								}
							/>
							<label
								className="form-check__label"
								htmlFor="blackGroundCheckbox"
							>
								Default black background
							</label>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export default FontSettings;
