import { useRef, useEffect, useContext, useState, FC } from "react";
import useOnClickOutside from "Hooks/outsideClickHook";
import useOnKeyDown from "Hooks/onKeyDownHook";
import Icon from "./Icon";
import useDataApi from "Hooks/fetchHook";
import { toast } from "react-toastify";
import { EndpointPrefix } from "Models/UserModels";
import { GlobalDispatch } from "Containers/Home";
import Loading from "./Loading";

type printerFineTuning = {
	fromLeft: number;
	heatPoints: number;
	printSpeed: number;
};

interface IProps {
	labelTemplateId: number;
	printBatchId: number;

	setShowPrinterFineTuningModal: (showPrinterFineTuningModal: boolean) => void;
}

const PrinterFineTuningModal: FC<IProps> = ({
	labelTemplateId,
	printBatchId,

	setShowPrinterFineTuningModal,
}) => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const useGetLabelTemplatePrinterSettings = useDataApi();
	const usePrintTestLabel = useDataApi();
	const useSaveLabelTemplatePrinterSettings = useDataApi();

	const [isEnabled, setIsEnabled] = useState(false);

	const modalRef = useRef(null) as any;

	const heatInputRef = useRef<HTMLInputElement>(null);
	const speedInputRef = useRef<HTMLInputElement>(null);
	const fromLeftInputRef = useRef<HTMLInputElement>(null);

	const [newPrinterFineTuning, setNewPrinterFineTuning] = useState<
		printerFineTuning | undefined
	>(undefined);

	const [newPrinterFineTuningInitial, setNewPrinterFineTuningInitial] =
		useState<printerFineTuning | undefined>(undefined);

	useEffect(() => {
		let isEnabled =
			heatInputRef?.current?.validity.valid &&
			speedInputRef?.current?.validity.valid &&
			fromLeftInputRef?.current?.validity.valid;

		setIsEnabled(!!isEnabled);
	}, [
		heatInputRef?.current?.validity.valid,
		speedInputRef?.current?.validity.valid,
		fromLeftInputRef?.current?.validity.valid,
	]);

	/* ************************* Get Printing Settings ********************************* */

	useEffect(() => {
		useGetLabelTemplatePrinterSettings.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/getTemplatePrinterSettings/${labelTemplateId}`
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetLabelTemplatePrinterSettings;
		if (data && data.message) {
			setNewPrinterFineTuning(data.message.printer_settings);
			setNewPrinterFineTuningInitial(data.message.printer_settings);
			setIsEnabled(true);
		}
	}, [useGetLabelTemplatePrinterSettings.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetLabelTemplatePrinterSettings;
		if (error) {
			toast.error(`Get print settings failed. ${error}`);
		}
	}, [useGetLabelTemplatePrinterSettings.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************************************************************** */

	/* ************************* Print test label ********************************* */

	useEffect(() => {
		const { data } = usePrintTestLabel;
		if (data && data.message) {
		}
	}, [usePrintTestLabel.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = usePrintTestLabel;
		if (error) {
			toast.error(`Unable to print test label. ${error}`);
		}
	}, [usePrintTestLabel.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************************************************************** */

	/* ************************* Save Printing Settings  ********************************* */

	useEffect(() => {
		const { data } = useSaveLabelTemplatePrinterSettings;
		if (data && data.message) {
			toast.success("Print settings successfully saved");
			setShowPrinterFineTuningModal(false);
		}
	}, [useSaveLabelTemplatePrinterSettings.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveLabelTemplatePrinterSettings;
		if (error) {
			toast.error(`Unable to save printer settings. ${error}`);
		}
	}, [useSaveLabelTemplatePrinterSettings.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************************************************************** */

	/* *******************************************************************************/
	const onCancelPrinterFineTuningModal = () => {
		let modalIsDirty = isDirty();

		if (modalIsDirty) {
			if (
				window.confirm(
					"You have unsaved changes, are you sure you want to leave?"
				)
			) {
				setShowPrinterFineTuningModal(false);
			}
		} else {
			setShowPrinterFineTuningModal(false);
		}
	};

	const isDirty = () => {
		let key: keyof printerFineTuning;

		if (newPrinterFineTuning && newPrinterFineTuningInitial) {
			for (key in newPrinterFineTuning) {
				if (newPrinterFineTuning[key] !== newPrinterFineTuningInitial[key]) {
					return true;
				}
			}
		}
		return false;
	};

	/* *******************************************************************************/

	useOnKeyDown("Escape", onCancelPrinterFineTuningModal);

	/* *******************************************************************************/
	useOnClickOutside(modalRef, onCancelPrinterFineTuningModal); // eslint-disable-line react-hooks/exhaustive-deps

	const onPrintTestLabel = () => {
		usePrintTestLabel.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/testPrinting`,
			{ batchId: printBatchId, printerSettings: newPrinterFineTuning },
			"POST"
		);
	};

	const onSaveLabelTemplatePrinterSettings = () => {
		useSaveLabelTemplatePrinterSettings.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/savePrinterSettings`,
			{ batchId: printBatchId, printerSettings: newPrinterFineTuning },
			"POST"
		);
	};

	return (
		<div className="modal flex-center-both-axis">
			<div
				className="modal-content-order-summary show"
				ref={modalRef}
				tabIndex={1}
			>
				<div className="flex row reverse" style={{ height: "40px" }}>
					<button
						className="drawer__close btn-no-style"
						data-dismiss="drawer"
						aria-label="Close"
						onClick={onCancelPrinterFineTuningModal}
						style={{ position: "relative" }}
					>
						<Icon name="cross-rounded" />
					</button>
				</div>

				<div className="mt--sm mb--base flex-center-both-axis">
					<h1>Printer fine tuning</h1>
				</div>

				<Loading
					show={useGetLabelTemplatePrinterSettings.isLoading}
					text={`Loading...`}
					imgClass="block-center"
					divClass="printer-fine-settings-modal-loading pd--base"
				/>

				{!useGetLabelTemplatePrinterSettings.isLoading &&
					newPrinterFineTuning && (
						<>
							<div className="pd--base flex">
								<div className="flex-1">
									<div className="upcase strong">X displacement (mm)</div>
									<input
										type="number"
										className="form-control"
										ref={fromLeftInputRef}
										style={{ width: "80%" }}
										value={newPrinterFineTuning.fromLeft}
										onChange={(e) => {
											setNewPrinterFineTuning((prevInfo: any) => {
												return {
													...prevInfo,
													fromLeft: parseInt(
														e.target.value ? e.target.value : "0"
													),
												};
											});
										}}
										min={-5}
										max={5}
										step={1}
									/>
								</div>
								<div className="flex-1">
									<div className="upcase strong">Heat</div>
									<input
										type="number"
										className="form-control"
										ref={heatInputRef}
										style={{ width: "80%" }}
										value={newPrinterFineTuning.heatPoints}
										onChange={(e) => {
											setNewPrinterFineTuning((prevInfo: any) => {
												return {
													...prevInfo,
													heatPoints: parseInt(
														e.target.value ? e.target.value : "0"
													),
												};
											});
										}}
										min={0}
										max={14}
										step={1}
									/>
								</div>
								<div className="flex-1">
									<div className="upcase strong">Speed</div>
									<input
										type="number"
										className="form-control"
										ref={speedInputRef}
										style={{ width: "80%" }}
										value={newPrinterFineTuning.printSpeed}
										onChange={(e) => {
											setNewPrinterFineTuning((prevInfo: any) => {
												return {
													...prevInfo,
													printSpeed: parseInt(
														e.target.value ? e.target.value : "0"
													),
												};
											});
										}}
										min={40}
										max={100}
										step={10}
									/>
								</div>
							</div>
							<div className="flex-center-both-axis">
								<button
									className={`button button--primary button--sm mt--sm mb--sm ${
										!isEnabled && "button--disabled"
									}`}
									onClick={onPrintTestLabel}
								>
									Print Test Label
								</button>
							</div>
							<div
								className="flex flex-center-both-axis mt--base"
								style={{
									justifyContent: "space-evenly",
									borderTop: "2px solid #dfdfdf",
									padding: "20px 0",
								}}
							>
								<button
									className={`button button--primary button--lg`}
									onClick={onCancelPrinterFineTuningModal}
								>
									Cancel
								</button>

								<button
									className={`button button--primary button--lg ${
										!isEnabled && "button--disabled"
									}`}
									onClick={onSaveLabelTemplatePrinterSettings}
								>
									Save
								</button>
							</div>
						</>
					)}
			</div>
		</div>
	);
};

export default PrinterFineTuningModal;
