import React, { useContext } from "react";

import { OrderDispatch } from "Containers/Order";
import Icon from "Components/Shared/Icon";
import { initialOrderItemState, MAX_ITEMS_IN_ORDER } from "Models/OrderModels";
import { deepCopy } from "Utils/utils";

interface IProps {
	forPlaceholder: boolean;

	setShowAddExistingStyleModal: (modalState: boolean) => void;
	setCurrentOrderItemIndex?: (currentOrderItemIndex: number) => void;
}

const OrderItemsPreviewButtons: React.FunctionComponent<IProps> = ({
	forPlaceholder,

	setShowAddExistingStyleModal,
	setCurrentOrderItemIndex,
}) => {
	const { order, setOrderItem, setOrder } = useContext(OrderDispatch);

	const onAddNewOrderItem = () => {
		window.scrollTo(0, 0);

		const { orderId, orderItems, labelTemplate } = order;

		if (
			(labelTemplate?.settings?.form_settings?.units?.hide_size &&
				orderItems.length >= MAX_ITEMS_IN_ORDER.WITHOUT_SIZE) ||
			(!labelTemplate?.settings?.form_settings?.units?.hide_size &&
				orderItems.length >= MAX_ITEMS_IN_ORDER.WITH_SIZE)
		) {
			alert(
				"Maximum number of items in order reached. If you need to order more items please create a new order."
			);
			return;
		}

		let newOrderItem = deepCopy({
			...initialOrderItemState,
			units: [
				{
					...initialOrderItemState.units[0],
					blackLabel:
						labelTemplate?.settings?.default_values?.default_black_label ||
						false,
					quantity:
						labelTemplate?.settings?.form_settings?.quantity?.minimum || 0,
				},
			],
		});

		const { settings } = labelTemplate;

		if (settings) {
			const { default_values } = settings;
			if (default_values) {
				const {
					all_languages: allLabelLanguages = [],
					default_languages,
					symbols_type: symbolsType,
				} = default_values;

				const chosenLabelLanguages = default_languages || allLabelLanguages;

				newOrderItem = {
					...newOrderItem,
					allLabelLanguages,
					chosenLabelLanguages,
					extraInfo: { symbolsType },
				};
			}
		}

		setOrderItem(newOrderItem);
		setCurrentOrderItemIndex && setCurrentOrderItemIndex(orderItems?.length);
		const newItems = [...orderItems, { ...newOrderItem }];

		setOrder({ ...order, orderId, orderItems: newItems });
	};

	return (
		<div style={{ marginTop: "20px" }}>
			<div
				className={`button button-xs ${
					forPlaceholder ? "placeholderButton" : ""
				}`}
				id="addStyleNumberButton"
				data-toggle="drawer"
				onClick={() => onAddNewOrderItem()}
			>
				<div className="blueIcon">
					<Icon className="icon mr--xs valign-middle" name="plus-rounded" />
				</div>
				<span
					className="strong"
					data-testid={
						forPlaceholder
							? "orderItemsPreviewButtons-addNew"
							: "orderItemsPreviewButtons-addNewStyle"
					}
				>
					{forPlaceholder ? "Add a New Style" : "Add New"}
				</span>
			</div>

			<div style={{ marginTop: "10px" }}>
				<div
					className={`button button-xs ${
						forPlaceholder ? "placeholderButton" : ""
					}`}
					id="addStyleNumberButton"
					data-toggle="drawer"
					onClick={() => {
						setShowAddExistingStyleModal(true);
					}}
				>
					<div className="blueIcon">
						<Icon className="icon mr--xs valign-middle" name="plus-rounded" />
					</div>
					<span
						className="strong"
						data-testid={
							forPlaceholder
								? "orderItemsPreviewButtons-addExistingStyle"
								: "orderItemsPreviewButtons-addExisting"
						}
					>
						{forPlaceholder ? "Add an Existing Style" : "Add Existing"}
					</span>
				</div>
			</div>
		</div>
	);
};

export default OrderItemsPreviewButtons;
