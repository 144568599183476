import {
	useState,
	useContext,
	FC,
	Dispatch,
	SetStateAction,
	useRef,
} from "react";
import { LabelTemplateDispatch } from "../../../Containers/LabelTemplate";
import { Tooltip } from "react-tooltip";

interface IProps {
	position: number;
	countForId: number;
	showSections: boolean;
	setShowSections: Dispatch<SetStateAction<boolean>>;
	setCountForId: Dispatch<SetStateAction<number>>;
	setIsSectionSettingsOpen: Dispatch<SetStateAction<any>>;
}

const SectionSelection: FC<IProps> = ({
	position,
	countForId,
	showSections,
	setShowSections,
	setCountForId,
	setIsSectionSettingsOpen,
}) => {
	const {
		template,
		setTemplate,
		setShowMissingData,
		checkGeneralLabelSettings,
	} = useContext(LabelTemplateDispatch);

	const { sectionDefaultSettings: defaultSettings } = template;

	const fontState = template.fonts;

	const tooltipRef = useRef() as any;

	const [mouseHovered, setMouseHovered] = useState(false);

	const placeSelectedItemInPosition = (selectedItem: any) => {
		selectedItem.id = countForId + 1;
		setCountForId((prevCount: number) => prevCount + 1);
		setIsSectionSettingsOpen({ ...selectedItem });
		setShowSections(false);
		setMouseHovered(false);
		let currentSelectedItems = [...template.sections];
		currentSelectedItems.splice(position, 0, selectedItem);

		let newTemplate = { ...template, sections: [...currentSelectedItems] };
		setTemplate(newTemplate);
	};

	return (
		<div
			className={`add-section ${
				(mouseHovered || template.sections.length === 0) &&
				"add-section-hovered"
			}`}
			onMouseEnter={() => {
				setMouseHovered(true);
			}}
			onMouseLeave={() => {
				setMouseHovered(false);
				setShowSections(false);
			}}
		>
			<fieldset className="width-100 pl--base flex flex-center-secondary-axis">
				<div className="add-section-button-wrapper flex-center-primary-axis">
					<div
						role="button"
						className="add-section-button flex-center-secondary-axis"
						onClick={(e) => {
							// some field is not populated
							if (position === 0 && checkGeneralLabelSettings().length > 0) {
								setShowMissingData(true);
							}
							// all fields are populated, but for later checks we turn showMissingData on
							else if (
								position === 0 &&
								checkGeneralLabelSettings().length === 0
							) {
								setShowMissingData(true);
								setShowSections(!showSections);
							}
							// other cases
							else {
								setShowSections(!showSections);
							}
						}}
						data-event="click"
						data-tooltip-id={"section-selection"}
					>
						<div
							style={{ height: "16px" }}
							className="flex-center-secondary-axis"
						>
							<div>Add section</div>
						</div>
					</div>
				</div>

				<Tooltip
					className="reactTooltip reactTooltipArrow box--shadowed fixed"
					id={"section-selection"}
					aria-haspopup="true"
					clickable={true}
					place="bottom"
					offset={0}
					openOnClick
					isOpen={mouseHovered && showSections}
				>
					<div ref={tooltipRef}>
						<div className="form-control add-section-dropdown-menu-wrapper">
							<ul className="add-section-dropdown-menu show pos-relative mb--none">
								<li
									value="Made in"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										let found = template.sections.find((item: any) => {
											return item.type === "MADE IN";
										});
										if (found) {
											alert("You already added made in section!");
										} else {
											placeSelectedItemInPosition({
												type: "MADE IN",
												capitalization: defaultSettings.capitalization,
												align: defaultSettings.align,
												fontSize: fontState.size,
												separator: defaultSettings.separator,
											});
										}
									}}
								>
									MADE IN
								</li>
								<li
									value="Content composition"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										let found = template.sections.find((item: any) => {
											return item.type === "CONTENT COMPOSITION";
										});
										if (found) {
											alert("You already added content composition section!");
										} else {
											placeSelectedItemInPosition({
												type: "CONTENT COMPOSITION",
												capitalization: defaultSettings.capitalization,
												align: defaultSettings.align,
												splitCharacter: defaultSettings.splitCharacter,
												addNewLine: false,
												fontSize: fontState.size,
												separator: defaultSettings.separator,
											});
										}
									}}
								>
									CONTENT COMPOSITION
								</li>
								<li
									value="Care instructions"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										let found = template.sections.find((item: any) => {
											return item.type === "CARE INSTRUCTIONS";
										});
										if (found)
											alert("You already added care instructions section!");
										else {
											placeSelectedItemInPosition({
												type: "CARE INSTRUCTIONS",
												capitalization: defaultSettings.capitalization,
												align: defaultSettings.align,
												splitCharacter: defaultSettings.splitCharacter,
												symbolSet: "usa",
												symbolSize: fontState.size,
												fontSize: fontState.size,
												symbolBeforeText: false,
												separator: defaultSettings.separator,
											});
										}
									}}
								>
									CARE INSTRUCTIONS
								</li>
								<li
									value="Separator"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										placeSelectedItemInPosition({
											type: "SEPARATOR",
											height: defaultSettings.separator,
										});
									}}
								>
									SEPARATOR
								</li>
								<li
									value="Image"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										placeSelectedItemInPosition({
											type: "IMAGE",
											file: "",
											width: 75,
											separator: defaultSettings.separator,
										});
									}}
								>
									IMAGE
								</li>
								<li
									value="QR"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										let found = template.sections.find((item: any) => {
											return item.type === "QR";
										});
										if (found) {
											alert("You already added QR code section!");
										} else {
											placeSelectedItemInPosition({
												type: "QR",
												qrPhase: 1,
												link: "",
												width: 50,
												separator: defaultSettings.separator,
											});
										}
									}}
								>
									QR
								</li>
								<li
									value="Fixed info"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										placeSelectedItemInPosition({
											type: "FIXED INFO",
											text: "",
											fontSize: fontState.size,
											align: defaultSettings.align,
											separator: defaultSettings.separator,
										});
									}}
								>
									FIXED INFO
								</li>
								<li
									value="Style number"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										placeSelectedItemInPosition({
											type: "STYLE NUMBER",
											text: "",
											align: defaultSettings.align,
											fontSize: fontState.size,
											separator: defaultSettings.separator,
										});
									}}
								>
									STYLE NUMBER
								</li>
								<li
									value="Line"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										placeSelectedItemInPosition({
											type: "LINE",
											dashed: false,
											separator: defaultSettings.separator,
										});
									}}
								>
									LINE
								</li>
								<li
									value="New page"
									className="dropdown-menu__item"
									role="button"
									onClick={(e) => {
										placeSelectedItemInPosition({
											type: "NEW PAGE",
										});
									}}
								>
									NEW PAGE
								</li>
							</ul>
						</div>
					</div>
				</Tooltip>
			</fieldset>
		</div>
	);
};

export default SectionSelection;
