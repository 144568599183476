import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import useDataApi from "Hooks/fetchHook";
import Loading from "../Shared/Loading";
import PasswordInput from "./PasswordInput";

const SettingsAccount: React.FunctionComponent = () => {
	const useChangePassword = useDataApi();

	const [newPassword, setNewPassword] = useState("");
	const [confirmationPassword, setConfirmationPassword] = useState("");
	const [currentPassword, setCurrentPassword] = useState("");

	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmationPassword, setShowConfirmationPassword] =
		useState(false);
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);

	useEffect(() => {
		const { error } = useChangePassword;
		if (error) {
			toast.error(`Unable to change password. ${error}`);
		}
	}, [useChangePassword.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useChangePassword;
		if (data.message === "success") {
			toast.success("Password changed successfully.");
			setNewPassword("");
			setConfirmationPassword("");
			setCurrentPassword("");
			setShowNewPassword(false);
			setShowConfirmationPassword(false);
			setShowCurrentPassword(false);
		}
	}, [useChangePassword.data]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChangePassword = () => {
		if (newPassword.length < 10) {
			alert(
				"Password needs to be at least 10 characters (letters, numbers or symbols) long!"
			);
		} else if (newPassword !== confirmationPassword) {
			alert("Your password and confirmation password do not match.");
		} else if (!currentPassword) {
			alert("You must enter your current password!");
		} else {
			const data = { oldPassword: currentPassword, newPassword: newPassword };
			useChangePassword.doFetch("/users/passwordReset", data, "POST");
		}
	};

	return (
		<div
			className="tab-pane fade in active show settingsTab"
			role="tabpanel"
			id="tab-general"
			aria-labelledby="tab-toggle-general"
		>
			<fieldset
				className="box box--light mb--md"
				style={{ padding: "40px 60px" }}
			>
				<h3 className="drawer__title">Change Password</h3>
				<div className="form-text">
					Password needs to be at least 10 characters (letters, numbers or
					symbols) long.
				</div>

				<PasswordInput
					password={currentPassword}
					showPassword={showCurrentPassword}
					minLength={undefined}
					passwordLabel="Enter your current password to confirm"
					setPassword={setCurrentPassword}
					setShowPassword={setShowCurrentPassword}
				/>

				<PasswordInput
					password={newPassword}
					showPassword={showNewPassword}
					minLength={10}
					passwordLabel="New password"
					setPassword={setNewPassword}
					setShowPassword={setShowNewPassword}
				/>

				<PasswordInput
					password={confirmationPassword}
					showPassword={showConfirmationPassword}
					minLength={10}
					passwordLabel="Enter new password again"
					setPassword={setConfirmationPassword}
					setShowPassword={setShowConfirmationPassword}
				/>

				<div
					className="form-actions mt--md"
					style={{ justifyContent: "flex-end" }}
				>
					{!useChangePassword.isLoading && (
						<button
							className="button button--primary"
							onClick={onChangePassword}
							data-testid="changePasswordButton"
						>
							Change password
						</button>
					)}
					<Loading
						show={useChangePassword.isLoading}
						text={"Loading..."}
						imgClass="saveOrderLoading"
						divClass=""
					/>
				</div>
			</fieldset>
		</div>
	);
};

export default SettingsAccount;
