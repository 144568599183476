import { FC, useContext } from "react";

import HelpTooltip from "Components/Shared/HelpTooltip";
import { OrderDispatch } from "Containers/Order";
import AdditionalInformationComponent from "./AdditionalInformation/AdditionalInformationComponent";
import { useFetchLabelTemplatesInitialValues } from "Hooks/queryHooks/useFetchLabelTemplateInitialValues";

const AdditionalInformation: FC = () => {
	const { orderItem, order } = useContext(OrderDispatch);

	const { labelTemplateInitialValues, isLoadingLabelTemplateInitialValues } =
		useFetchLabelTemplatesInitialValues(order);

	if (isLoadingLabelTemplateInitialValues) {
		return null;
	}

	return (
		<>
			{Object.keys(labelTemplateInitialValues).map((key) => {
				let selectedAdditionalInformationKey =
					`selectedAdditionalInformation---${key}` as keyof typeof orderItem;
				const additionalInformation: any =
					orderItem[selectedAdditionalInformationKey] || [];

				const uniqueAdditionalInformation = [...new Set(additionalInformation)];

				return (
					<div key={key}>
						<div
							className="pretitle flex-center-secondary-axis"
							data-testid="additionalInformationSection"
						>
							<span style={{ lineHeight: 1 }}>
								Additional Information - {key}
							</span>
							<HelpTooltip helpFor="AdditionalInformation" />
						</div>
						<AdditionalInformationComponent
							list={labelTemplateInitialValues[key]}
							additionalInformationName={key}
							selectedItemsValues={uniqueAdditionalInformation}
						/>
					</div>
				);
			})}
		</>
	);
};

export default AdditionalInformation;
