import { useEffect, useContext, FC, SetStateAction, Dispatch } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import Icon from "Components/Shared/Icon";
import { LabelTemplateDispatch } from "../../Containers/LabelTemplate";
import { GlobalDispatch } from "Containers/Home";
import useDataApi from "Hooks/fetchHook";

import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";
import { ITemplateState } from "Models/TemplateModels";

interface IProps {
	saveTemplateDisabled: boolean;

	setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const LabelTemplateHeader: FC<IProps> = ({
	saveTemplateDisabled,

	setIsDirty,
}) => {
	const navigate = useNavigate();

	const useSaveTemplate = useDataApi();

	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const {
		activeTab,
		setActiveTab,
		template,
		setTemplate,
		showMissingData,
		checkGeneralLabelSettings,
	} = useContext(LabelTemplateDispatch);

	const { labelTemplateId: labelTemplateIdFromParams } = useParams() as any;

	const { name } = template;

	const isDataMissing = (itemToCheck: string) => {
		return showMissingData && checkGeneralLabelSettings().includes(itemToCheck);
	};

	useEffect(() => {
		const { data } = useSaveTemplate;
		if (data.status === "ok") {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.LABEL_TEMPLATES, account_type_id],
			});
			navigate("/templates");
			toast.success("Template added successfully.");
		}
	}, [useSaveTemplate.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveTemplate;
		if (error) {
			toast.error(`Unable to save Label template . ${error}`);
		}
	}, [useSaveTemplate.error]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="new-label-header">
			<div className="">
				{/* First row */}
				<div className="flex mb--sm">
					<button
						className="link--back btn-no-style"
						style={{ position: "static" }}
						onClick={(e) => {
							navigate({
								pathname: "/templates",
							});
						}}
					>
						<Icon name="arrow-left-rounded" />
						Back to templates
					</button>
					<div className="ml--lg">
						<h1 className="section__title" style={{ margin: 0 }}>
							{!labelTemplateIdFromParams ? "New" : "Edit"} label template
						</h1>
					</div>
				</div>
				{/* Second row */}
				<div
					className="flex bg--light justify-between flex-center-secondary-axis"
					style={{ padding: "20px 20px", borderBottom: "2px solid #dfdfdf " }}
				>
					<div className="flex cursor-pointer">
						<div
							className="flex"
							onClick={() => {
								setActiveTab("label");
							}}
						>
							<div
								className={`label-dpp-tabs ${
									activeTab === "label" && "active-tab"
								}`}
							>
								<span>Label</span>
							</div>
							<div
								className="label-arrow-right"
								style={{
									borderLeftColor: `${
										activeTab === "label" ? "#2f80ed" : "white"
									}`,
									// backgroundColor: `${
									// 	activeTab === "label" ? "white" : "#2f80ed"
									// }`,
									backgroundColor: `${
										activeTab === "label" ? "transparent" : "#2f80ed"
									}`,
								}}
							></div>
						</div>
						{/* <div
							className="flex"
							onClick={() => {
								setActiveTab("dpp");
							}}
						>
							<div
								className={`label-dpp-tabs ${
									activeTab === "dpp" && "active-tab"
								}`}
							>
								<span>Dpp</span>
							</div>
							<div
								className="dpp-arrow-right"
								style={{
									borderLeftColor: `${
										activeTab === "dpp" ? "#2f80ed" : "white"
									}`,
								}}
							></div>
						</div> */}
					</div>
					<div className="flex">
						<div
							className={`pos-relative mr--lg ${
								isDataMissing("Template name") && "has-danger"
							} mb--base`}
							style={{ width: "350px" }}
						>
							<input
								type="text"
								className="form-control"
								name="labelName"
								id="labelName"
								value={name === "" ? "" : name}
								data-empty={name === ""}
								onChange={(e) => {
									setTemplate((prevTemplate: ITemplateState) => ({
										...prevTemplate,
										name: e.target.value,
									}));
								}}
								style={{
									backgroundColor: "transparent",
								}}
							/>
							<label className={`form-control-label`} htmlFor="labelName">
								Template Name
							</label>
						</div>

						<button
							className="button btn-link btn-no-style mr--sm pd--sm"
							style={{ fontWeight: 500 }}
							onClick={() => {}}
						>
							Cancel
						</button>

						<button
							className={`button button--primary ${
								saveTemplateDisabled && "button--disabled"
							} `}
							type="button"
							form="orderItemForm"
							onClick={() => {
								setIsDirty(false);
								useSaveTemplate.doFetch(
									"/brand/data/saveTemplate",
									{ template },
									"POST"
								);
							}}
						>
							Save Template
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LabelTemplateHeader;
