import { OrderStatusesFlow } from "./../../Models/OrderModels";
import { useNavigate } from "react-router-dom-v5-compat";
import useDataApi from "Hooks/fetchHook";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { deepCopy } from "Utils/utils";
import { ILabelTemplate, initialOrderItemState } from "Models/OrderModels";
import { UserAccountType } from "Models/UserModels";

import { GlobalDispatch } from "Containers/Home";

const useGetLabelTemplates = (
	setLabelTemplates: any,
	orderIdFromParams: any,
	setOrder: any,
	setLoadedOrder: any,
	setOrderItem: any
) => {
	const navigate = useNavigate();
	const { user, setError } = useContext(GlobalDispatch);

	const useGetLabelTemplates = useDataApi();

	/* ************************* Get Label Templates *********************************** */
	useEffect(() => {
		const { data } = useGetLabelTemplates;
		if (data && data.message) {
			if (data.message.length === 1) {
				onCreateNewOrder(data.message[0]);
			} else if (data.message.length > 1) {
				setLabelTemplates(data.message);
			} else {
				toast.error("Unable to find label templates for the given brand.");
				setError({
					title: "Something Went Wrong",
					text: "Unable to get label templates. Please try again later.",
					primary: {
						text: "Back to orders",
						action: () => navigate(`/orders`),
					},
					secondary: {
						text: "Refresh page",
						action: () =>
							navigate(
								orderIdFromParams ? `/order/${orderIdFromParams}` : "/order/",
								{ replace: true }
							),
					},
				});
			}
		}
	}, [useGetLabelTemplates.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetLabelTemplates;
		if (error) {
			setError({
				title: "Something Went Wrong",
				text: "Unable to get label templates. Please try again later.",
				primary: {
					text: "Back to orders",
					action: () => navigate(`/orders`),
				},
				secondary: {
					text: "Refresh page",
					action: () => navigate(`/order/`, { replace: true }),
				},
			});
		}
	}, [useGetLabelTemplates.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* ********************************************************************************** */

	const onCreateNewOrder = (labelTemplate: ILabelTemplate) => {
		const { brand_id: brandId } = labelTemplate;

		let newOrder = {
			orderId: "",
			labelTemplate,
			lotNumber: "",
			orderItems: [],
			brandId,
			factoryId:
				user.account_type_id === UserAccountType.FACTORY
					? user.external_id
					: null,
			log: { createdBy: "", doneAt: "", lastChangeBy: "", lastChangeAt: "" },
			status: OrderStatusesFlow.OPEN.code,
			note: undefined,
			shippingAddress: undefined,
			billingAddress: undefined,
			preferredShippingMethod: undefined,
		};

		setOrder(newOrder);
		setLoadedOrder(newOrder);
		setOrderItem(
			deepCopy({
				...initialOrderItemState,
				units: [
					{
						...initialOrderItemState.units[0],
						quantity:
							labelTemplate?.settings?.form_settings?.quantity?.minimum || 0,
					},
				],
			})
		);
	};

	/* *************************************************************************************** */

	let getLabelTemplatesDoFetch = useGetLabelTemplates.doFetch;
	let getLabelTemplatesIsLoading = useGetLabelTemplates.isLoading;

	return {
		getLabelTemplatesDoFetch,
		getLabelTemplatesIsLoading,
		onCreateNewOrder,
	};
};

export default useGetLabelTemplates;
