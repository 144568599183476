import React, { useState, createContext, useRef, useMemo } from "react";
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom-v5-compat";
import * as Sentry from "@sentry/react";

import "react-toastify/dist/ReactToastify.css";

import { IUser, isBrand, isFactory } from "Models/UserModels";
import HelpModal from "Components/Home/HelpModal";
import Navigation from "Components/Home/Navigation";
import Order from "./Order";
import Orders from "./Orders";
import Styles from "./Styles";
import Confirmation from "./Confirmation";
import Settings from "./Settings";
import Help from "./Help";
import Admin from "./Admin";
import Demo from "Demo/Demo";
import OrderPreview from "./OrderPreview";
import OrderApprove from "./OrderApprove";
import DppStatistic from "./DppStatistic";
import Templates from "./Templates";
import LegacyBrowsersErrors from "../Components/Home/LegacyBrowsersErrors";
import WhatIsNewModal from "Components/Home/WhatIsNewModal";

import {
	IConfirmationProps,
	IGlobalDispatch,
	IMissingTranslations,
} from "Models/GlobalModels";

import Printers from "./Printers";
import {
	brandDppAppLabelTemplates,
	brandPrintLabelTemplates,
} from "Utils/utils";
import { useFetchLabelTemplates } from "Hooks/queryHooks/useFetchLabelTemplates";
import LabelTemplate from "Containers/LabelTemplate";
import PrintBatches from "./PrintBatches";
import PrintBatch from "./PrintBatch";

interface IProps {
	user: IUser;

	onLogout: () => void;
	setError: (error: any) => void;
}

export const GlobalDispatch = createContext<IGlobalDispatch>(
	{} as IGlobalDispatch
);

const Home: React.FunctionComponent<IProps> = (props: IProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, onLogout, setError } = props;

	const { account_type_id, org_settings: { brand_specific } = {} } = user;

	const { labelTemplates } = useFetchLabelTemplates(account_type_id, setError);

	const [missingTranslations, setMissingTranslations] =
		useState<IMissingTranslations>({} as IMissingTranslations);
	const [currentHelpTopic, setCurrentHelpTopic] = useState(undefined) as any;

	const [resetOrdersFilters, setResetOrdersFilters] = useState(false);
	const [resetStylesFilters, setResetStylesFilters] = useState(false);
	const [resetTemplatesFilters, setResetTemplatesFilters] = useState(false);
	const [resetPrintBatchesFilters, setResetPrintBatchesFilters] =
		useState(false);

	const [notSupported, setNotSupported] = useState<unknown>("");

	const [confirmationProps, setConfirmationProps] =
		useState<IConfirmationProps>({
			message: "",
			link: "",
		});

	const fromOrders = useRef(false);

	let factoryBrandsRequestApproval = useMemo(() => {
		if (account_type_id === 8) {
			let arr: string[] = [];
			Object.keys(brand_specific).forEach((key) => {
				if (brand_specific[key].approval_required) arr.push(key);
			});
			return arr;
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!location.pathname.startsWith("/order/")) {
		window.onbeforeunload = null;
	}

	if (
		location.pathname === "/confirmation" &&
		confirmationProps.message === "" &&
		confirmationProps.link === ""
	) {
		navigate("/orders");
	}

	// Here we will add unhandled errors that Sentry catch
	try {
		// test ResizeObserver Api
		new ResizeObserver(() => {});

		// test String.replaceAll()
		let browserTest = "example";
		browserTest.replaceAll("", "");
	} catch (error) {
		if (!notSupported) {
			setNotSupported(error);
			Sentry.captureException(error);
		}
	}

	if (notSupported) return <LegacyBrowsersErrors />;
	if (!user.whats_new_seen) return <WhatIsNewModal />;

	let createTemplatesAllowed = isBrand(account_type_id) && user.brand_id === 6;

	return (
		<>
			{user.account_type_id !== 7 && (
				<GlobalDispatch.Provider
					value={{
						user,
						missingTranslations,
						setMissingTranslations,
						setError,
						setCurrentHelpTopic,
						setResetOrdersFilters,
						resetOrdersFilters,
						setResetStylesFilters,
						resetStylesFilters,
						setResetTemplatesFilters,
						resetTemplatesFilters,
						setResetPrintBatchesFilters,
						resetPrintBatchesFilters,
						fromOrders,
						factoryBrandsRequestApproval,
						setConfirmationProps,
					}}
				>
					{location.pathname === "/order" ||
					location.pathname.indexOf("/order/") === 0 ||
					location.pathname.startsWith("/labelTemplate") ||
					location.pathname.indexOf("/printBatch/") === 0 ? (
						<Routes>
							<Route path="/order/:orderId" element={<Order />} />
							<Route path="/order" element={<Order />} />
							<Route
								path="/order/preview/:orderId"
								element={<OrderPreview />}
							/>
							<Route
								path="/order/approve/:orderId"
								element={<OrderApprove />}
							/>
							{createTemplatesAllowed && (
								<Route path="/labelTemplate" element={<LabelTemplate />} />
							)}
							{createTemplatesAllowed && (
								<Route
									path="/labelTemplate/:labelTemplateId"
									element={<LabelTemplate />}
								/>
							)}
							{((isBrand(account_type_id) &&
								brandPrintLabelTemplates(labelTemplates).length > 0) ||
								isFactory(account_type_id)) && (
								<Route
									path="/printBatch/:printBatchId"
									element={<PrintBatch />}
								/>
							)}
						</Routes>
					) : (
						<main className="main">
							<Navigation onLogout={onLogout} />
							<Routes>
								<Route path="/orders" element={<Orders />} />
								<Route path="/printBatches" element={<PrintBatches />} />
								<Route path="/styles" element={<Styles />} />
								<Route path="/admin" element={<Admin />} />
								{createTemplatesAllowed && (
									<Route path="/templates" element={<Templates />} />
								)}

								{((isBrand(account_type_id) &&
									brandPrintLabelTemplates(labelTemplates).length > 0) ||
									isFactory(account_type_id)) && (
									<Route path="/printers" element={<Printers />} />
								)}

								<Route
									path="/confirmation"
									element={
										<Confirmation
											confirmationProps={confirmationProps}
											fromOrders={fromOrders}
										/>
									}
								/>
								<Route path="/settings" element={<Settings />} />
								{isBrand(account_type_id) &&
									brandDppAppLabelTemplates(labelTemplates).length > 0 && (
										<Route path="/dpp-statistics" element={<DppStatistic />} />
									)}
								<Route path="/help/*" element={<Help />} />

								<Route path="*" element={<Navigate to="/orders" />} />
							</Routes>
						</main>
					)}

					<HelpModal
						currentHelpTopic={currentHelpTopic}
						setCurrentHelpTopic={setCurrentHelpTopic}
					/>
				</GlobalDispatch.Provider>
			)}

			{user.account_type_id === 7 && <Demo user={user} onLogout={onLogout} />}
		</>
	);
};

export default Home;
