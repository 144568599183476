import Icon from "Components/Shared/Icon";
import Loading from "Components/Shared/Loading";
import { GlobalDispatch } from "Containers/Home";
import useDataApi from "Hooks/fetchHook";
import { EndpointPrefix } from "Models/UserModels";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";

interface IProps {
	selectedOrdersForBulkAction: any;

	setSelectedOrdersForBulkAction: any;
	forceRefreshOrders: () => void;
}

const OrdersHeaderActionsToolbar = ({
	selectedOrdersForBulkAction,

	setSelectedOrdersForBulkAction,
	forceRefreshOrders,
}: IProps) => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const useGetPdf = useDataApi();

	/* ******************** Download PDF API call ***************************/
	useEffect(() => {
		const { error } = useGetPdf;
		if (error) {
			toast.error(`Unable to download PDF. ${error}`);
		}
	}, [useGetPdf.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetPdf;

		if (data.type === "application/pdf") {
			const blobURL = window.URL.createObjectURL(new Blob([data]));
			const a = document.createElement("a");
			a.href = blobURL;
			a.download = "Cacotec Label Layouts.pdf";
			a.click();
			a.remove();
			setTimeout(() => window.URL.revokeObjectURL(blobURL), 100);
			setSelectedOrdersForBulkAction({});
		}
	}, [useGetPdf.data]); // eslint-disable-line react-hooks/exhaustive-deps
	/* *******************************************************************************/

	const downloadPdf = () => {
		let localSelectedOrdersForBulkAction = { ...selectedOrdersForBulkAction };
		delete localSelectedOrdersForBulkAction.orderStatus;

		const orderIds = Object.keys(localSelectedOrdersForBulkAction);
		if (orderIds.length > 0) {
			const parameterizeIds = (key: any, ids: any) =>
				"?" + key + "[]=" + ids.join("&" + key + "[]=");

			useGetPdf.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/pdf${parameterizeIds(
					"ids",
					orderIds
				)}`
			);
		}
	};

	return (
		<div className="toolbar">
			{/* Refresh action */}
			<div
				className="orders-action mr--md left"
				onClick={() => forceRefreshOrders()}
			>
				<div className="pill pill--outline-quiet pill--toolbar">
					<div className="toolbarIconContainer">
						<Icon name="refresh" className="toolbarIcon" />
					</div>
				</div>
			</div>
			{/* Actions available only when some orders are selected */}
			{Object.keys(selectedOrdersForBulkAction).length > 1 && (
				<>
					{/* Download PDF action */}
					<div className="left">
						<div className="flex-center-both-axis" style={{ width: "162px" }}>
							<Loading
								show={useGetPdf.isLoading}
								text={`Loading...`}
								imgClass="orders-action-loading"
								divClass=""
							/>
						</div>
						{!useGetPdf?.isLoading && (
							<div
								className="orders-action mr--md left"
								title="Download PDF"
								onClick={() => downloadPdf()}
							>
								<div className="pill pill--outline-quiet pill--toolbar">
									<div className="toolbarIconContainer">
										<Icon name="download" className="toolbarIcon" />
										<div className="toolbarText">Download PDF</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default OrdersHeaderActionsToolbar;
