import React, { useEffect, useState } from "react";

import IncrementalSearchInput from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import IncrementalSearchResults from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchResults";
import useIncrementalSearchHook from "Hooks/incrementalSearchHook";
import { IDefaultOption } from "Models/OrderModels";

interface IProps {
	compositionIndex: number;
	component?: string;
	componentsList: IDefaultOption[];
	componentNames: string[];
	invalidMessages: any;

	onChangeAddedComposition: (composition: any, index: number) => void;
	changeComponentInvalidMessages: (
		invalidMessage: string,
		index: number
	) => void;
}

const Component: React.FunctionComponent<IProps> = ({
	compositionIndex,
	component,
	changeComponentInvalidMessages,
	componentsList,
	componentNames,
	onChangeAddedComposition,
	invalidMessages,
}) => {
	const [focusOnSearchList, setFocusOnSearchList] = useState(false);

	const incrementalSearchHookProps = useIncrementalSearchHook(
		componentsList,
		[],
		true
	);
	const { inputText, setInputText, setShowList } = incrementalSearchHookProps;

	let invalidComponentMessage = "";
	if (invalidMessages[compositionIndex]) {
		({ component: invalidComponentMessage } =
			invalidMessages[compositionIndex]);
	}

	useEffect(() => {
		if (inputText && !componentNames.includes(inputText)) {
			changeComponentInvalidMessages(
				"You must add a valid component!",
				compositionIndex
			);
		} else {
			changeComponentInvalidMessages("", compositionIndex);
		}
	}, [inputText]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setInputText(component);
	}, [component]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChooseItemCallback = (item: any) => {
		if (item) {
			onChangeAddedComposition({ component: item.name }, compositionIndex);
			setInputText(item.name);
		}
	};

	const onChooseItemFromList = (item: any) => {
		setShowList(false);
		onChooseItemCallback(item);
	};

	const onSetItemOptionText = (text: string) => {
		if (!text) {
			onChangeAddedComposition({ component: "" }, compositionIndex);
		}
		setInputText(text);
	};

	incrementalSearchHookProps.setInputText = onSetItemOptionText;

	return (
		<div
			className={`form-group ${invalidComponentMessage && "has-danger"}`}
			id="fabricContentFormInput"
		>
			<IncrementalSearchInput
				labelText="Component Name"
				name={`fabricContentComponent${compositionIndex}`}
				autoFocus={false}
				invalidMessage={invalidComponentMessage}
				setFocusOnSearchList={setFocusOnSearchList}
				onInputBlur={onChooseItemCallback}
				incrementalSearchHookProps={incrementalSearchHookProps}
			/>
			<IncrementalSearchResults
				focusOnSearchList={focusOnSearchList}
				testId="fabricContentForm-component"
				onChooseItemCallback={onChooseItemFromList}
				incrementalSearchHookProps={incrementalSearchHookProps}
			/>
		</div>
	);
};

export default Component;
