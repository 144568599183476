import { useRef, useCallback, FC, useState } from "react";

import Icon from "Components/Shared/Icon";
import useOnClickOutside from "Hooks/outsideClickHook";
import useOnKeyDown from "Hooks/onKeyDownHook";

interface IProps {
	showPrinterEditModal: any;

	setShowPrinterEditModal: React.Dispatch<React.SetStateAction<any>>;
	setChosenPrinterForEditing: React.Dispatch<React.SetStateAction<any>>;
}

const PrinterEditModalForPrintBatchOrOrder: FC<IProps> = ({
	showPrinterEditModal,

	setShowPrinterEditModal,
	setChosenPrinterForEditing,
}) => {
	const modalRef = useRef(null) as any;

	const [newPrinterSettings, setNewPrinterSettings] = useState(
		showPrinterEditModal.info
	);

	const onCancelPrinterEditModal = () => {
		// if (window.confirm("Are you sure?")) {
		setShowPrinterEditModal(undefined);
		// }
	};

	const onSavePrinterEditModal = () => {
		setChosenPrinterForEditing((prevChosenPrinter: any) => {
			let settings = prevChosenPrinter.settings;
			let newSettings = {
				...settings,
				[showPrinterEditModal.item]: newPrinterSettings,
			};

			return {
				...prevChosenPrinter,
				settings: newSettings,
			};
		});
		setShowPrinterEditModal(undefined);
	};

	/* *******************************************************************************/

	useOnKeyDown("Escape", onCancelPrinterEditModal);

	/* *******************************************************************************/
	useOnClickOutside(
		modalRef,
		useCallback(onCancelPrinterEditModal, [setShowPrinterEditModal])
	); // eslint-disable-line react-hooks/exhaustive-deps

	const widthInputRef = useRef<HTMLInputElement>(null);

	let isEnabled = widthInputRef?.current?.validity.valid;

	return (
		<div className="modal flex-center-both-axis">
			<div
				className="modal-content-order-summary show"
				style={{ width: "1000px" }}
				ref={modalRef}
				tabIndex={1}
			>
				<div className="flex row reverse" style={{ height: "40px" }}>
					<button
						className="drawer__close btn-no-style"
						data-dismiss="drawer"
						aria-label="Close"
						onClick={onCancelPrinterEditModal}
						style={{ position: "relative" }}
					>
						<Icon name="cross-rounded" />
					</button>
				</div>
				<div className="pd--base">
					<p className="strong upcase">Edit {showPrinterEditModal.item} info</p>
					<div className="flex ">
						{showPrinterEditModal.item === "paper" && (
							<div className="flex-1">
								<div className="upcase strong">material</div>
								<select
									value={newPrinterSettings.material}
									className="form-control"
									style={{ width: "80%" }}
									onChange={(e) => {
										setNewPrinterSettings((prevInfo: any) => {
											return {
												...prevInfo,
												material: e.target.value,
											};
										});
									}}
								>
									<option value="nylon">Nylon</option>
									<option value="silk">Silk</option>
									<option value="polyester">Polyester</option>
								</select>
							</div>
						)}
						{showPrinterEditModal.item === "ribbon" && (
							<div className="flex-1">
								<div className="upcase strong"> Name</div>
								<input
									type="text"
									defaultValue={showPrinterEditModal.info.name}
									className="form-control"
									style={{ width: "80%" }}
									onChange={(e) => {
										setNewPrinterSettings((prevInfo: any) => {
											return { ...prevInfo, name: e.target.value };
										});
									}}
								/>
							</div>
						)}
						<div className="flex-1">
							<div className="upcase strong">Width (mm)</div>
							<input
								type="number"
								className="form-control"
								ref={widthInputRef}
								step={0.01}
								style={{ width: "80%" }}
								defaultValue={showPrinterEditModal.info.width}
								onChange={(e) => {
									setNewPrinterSettings((prevInfo: any) => {
										return { ...prevInfo, width: parseFloat(e.target.value) };
									});
								}}
							/>
						</div>
						<div className="flex-1">
							<div className="upcase strong">Color</div>
							<select
								value={newPrinterSettings.color}
								className="form-control"
								style={{ width: "80%" }}
								onChange={(e) => {
									setNewPrinterSettings((prevInfo: any) => {
										return { ...prevInfo, color: e.target.value };
									});
								}}
							>
								<option value="white">White</option>
								<option value="black">Black</option>
								<option value="silver">Silver</option>
							</select>
						</div>
					</div>
				</div>
				<div
					className="flex mt--base"
					style={{ justifyContent: "space-evenly" }}
				>
					<button
						className={`button button--primary button--lg mb--lg ${
							!isEnabled && "button--disabled"
						}`}
						onClick={onSavePrinterEditModal}
					>
						Save
					</button>
					<button
						className="button button--primary button--lg mb--lg"
						onClick={onCancelPrinterEditModal}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export default PrinterEditModalForPrintBatchOrOrder;
