import { FC } from "react";
import usePageTitle from "Hooks/pageTitleHook";
import ReleaseDec23 from "./HelpWhatIsNew/Dec2023/Dec23";


interface IProps {}

const HelpWhatIsNew: FC<IProps> = () => {
	usePageTitle("Cacotec Help - What’s new");
	return (
		<div>
			<ReleaseDec23 />
		</div>
	);
};

export default HelpWhatIsNew;
