import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom-v5-compat";

import { toast } from "react-toastify";

import useDataApi from "Hooks/fetchHook";
import useReorderHook from "Hooks/reorderHook";

import Loading from "Components/Shared/Loading";
import OrderPreviewDetails from "Components/OrderPreview/OrderPreviewDetails";
import OrderPreviewSummary from "Components/OrderPreview/OrderPreviewSummary";
import OrderPreviewAdditionalInfo from "Components/OrderPreview/OrderPreviewAdditionalInfo";
import Icon from "Components/Shared/Icon";

import { OrderStatusesFlow, isPrintStatus } from "Models/OrderModels";
import { EndpointPrefix, isLabelMaker } from "Models/UserModels";

import { GlobalDispatch } from "./Home";
import { transformOrderItemForUseOnFrontend } from "Utils/utils";
import PrintBatchComponent from "Components/Shared/PrintBatchComponent";

const OrderPreview: React.FunctionComponent = () => {
	const {
		user: { account_type_id },
		setError,
		fromOrders,
	} = useContext(GlobalDispatch);

	const navigate = useNavigate();

	const useGetOrder = useDataApi();
	const useGetPrintBatch1 = useDataApi();
	const useGetPrintBatch2 = useDataApi();

	const useGetPrinters = useDataApi();

	const [order, setOrder] = useState({}) as any;
	const [printBatch1, setPrintBatch1] = useState<any>(undefined);
	const [printBatch2, setPrintBatch2] = useState<any>(undefined);
	const [printers, setPrinters] = useState([]);

	const [preparingForPrint, setPreparingForPrint] = useState(false);

	const [globalLoadingGetPrintBatches, setGlobalLoadingGetPrintBatches] =
		useState(true);

	const { copyOrReorderWholeOrder, reorderStyleNumber, useReorder } =
		useReorderHook(order);

	const { orderId: orderIdFromParams } = useParams() as any;

	let orderHasTwoPrintBatches = order?.printData?.length === 2;

	useEffect(() => {
		fetchOrder();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (order.status && isPrintStatus(order.status)) {
			fetchPrinters();
		}
	}, [order.status]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ************************* Load an existing order START ********************************* */
	useEffect(() => {
		const { data } = useGetOrder;
		if (data && data.message) {
			const {
				order_items,
				status,
				brand_id,
				label_template,
				lot_number,
				extra_info,
				billing_address,
				shipping_address,
				preferred_shipping_method,
				placed_by,
				note,
				print_data,
				log,
			} = data.message;

			if (status < OrderStatusesFlow.PLACED.code) {
				navigate(`/order/${orderIdFromParams}`, { replace: true });
				return;
			} else if (!label_template?.id || !brand_id) {
				setError({
					title: "Something Went Wrong",
					text: "Received incomplete data. Please try again later.",
					primary: {
						text: "Back to orders",
						action: () => navigate(`/orders`),
					},
					secondary: {
						text: "Refresh page",
						action: () =>
							navigate(`/order/${orderIdFromParams}`, { replace: true }),
					},
				});
			}

			if (order_items) {
				const orderItemsForState = order_items.map((orderItem: any) =>
					transformOrderItemForUseOnFrontend(orderItem)
				);
				setOrder({
					orderId: orderIdFromParams,
					labelTemplate: label_template,
					lotNumber: lot_number,
					orderItems: orderItemsForState,
					brandId: brand_id,
					status,
					extraInfo: extra_info,
					billingAddress: billing_address,
					shippingAddress: shipping_address,
					shippingMethod: preferred_shipping_method,
					placedBy: placed_by,
					note,
					printData: print_data?.data,
					log,
				});
			}

			if (print_data?.data[0]) {
				fetchPrintBatch1(print_data.data[0].print_batch_id);
			}

			if (print_data?.data[1]) {
				fetchPrintBatch2(print_data.data[1].print_batch_id);
			}
		}
	}, [useGetOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetOrder;
		if (error) {
			setError({
				title: "Order Not Found",
				text: "",
				primary: {
					text: "Back to orders",
					action: () => navigate(`/orders`),
				},
				secondary: {
					text: !isLabelMaker(account_type_id) ? "Create a New Order" : "",
					action: () => navigate(`/order/`),
				},
			});
		}
	}, [useGetOrder.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ********************************  Load an existing order END *************************** */

	/* ************************************** Get printers START ************************ */

	useEffect(() => {
		const { data } = useGetPrinters;
		if (data && data.message) {
			setPrinters(data.message.printers);
		}
	}, [useGetPrinters.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPrinters;
		if (error) {
			toast.error("Unable to get Printers list.");
		}
	}, [useGetPrinters.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ************************************** Get printers END  ************************ */

	/* *********************************** Get Print batch 1 START *********************************************** */

	useEffect(() => {
		const { data } = useGetPrintBatch1;
		if (data && data.message) {
			let freshPrintBatch = data.message;

			setPrintBatch1(freshPrintBatch);
		}
	}, [useGetPrintBatch1.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPrintBatch1;
		if (error) {
			toast.error("Unable to get Print batch info.");
		}
	}, [useGetPrintBatch1.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************** Get Print batch 1 END   *********************************************** */

	/* *********************************** Get Print batch 2 START *********************************************** */

	useEffect(() => {
		const { data } = useGetPrintBatch2;
		if (data && data.message) {
			let freshPrintBatch = data.message;

			setPrintBatch2(freshPrintBatch);
		}
	}, [useGetPrintBatch2.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPrintBatch2;
		if (error) {
			toast.error("Unable to get Print batch info.");
		}
	}, [useGetPrintBatch2.error]); // eslint-disable-line react-hooks/exhaustive-deps

	/* *********************************** Get Print batch 2 END   *********************************************** */

	/* *********************************** Set Global loading for Get Print Batch START   *********************************************** */

	useEffect(() => {
		if (order.status) {
			if (
				!isPrintStatus(order.status) ||
				(orderHasTwoPrintBatches && printBatch1 && printBatch2) ||
				(!orderHasTwoPrintBatches && printBatch1)
			) {
				setGlobalLoadingGetPrintBatches(false);
			}
		}
	}, [order.status, orderHasTwoPrintBatches, printBatch1, printBatch2]);

	/* *********************************** Set Global loading for Get Print Batch END   *********************************************** */

	/* *********************************** Functions START   *********************************************** */

	const fetchPrinters = () => {
		useGetPrinters.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printersList`
		);
	};

	const fetchOrder = () => {
		useGetOrder.doFetch(
			`/${EndpointPrefix[account_type_id]}/orders/${orderIdFromParams}`
		);
	};

	const fetchPrintBatch1 = (printBatchId: number) => {
		useGetPrintBatch1.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printBatch/${printBatchId}`
		);
	};

	const fetchPrintBatch2 = (printBatchId: number) => {
		useGetPrintBatch2.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printBatch/${printBatchId}`
		);
	};

	/* *********************************** Functions END *********************************************** */

	return (
		<div className="orderPreviewContainer">
			<Loading
				show={
					useGetOrder.isLoading ||
					useReorder.isLoading ||
					globalLoadingGetPrintBatches
				}
				text={`Loading...`}
				imgClass="block-center"
				divClass=""
			/>
			{!useGetOrder.isLoading &&
				!useReorder.isLoading &&
				!useReorder.data?.message &&
				!globalLoadingGetPrintBatches && (
					<div className="orderPreviewContent">
						<div className="orderPreviewContentHeader">
							<div className="pos-relative">
								<div className="flex">
									<div className="mt--xs">
										<button
											className="link--back btn-no-style pos-relative mt--xs"
											style={{ top: 0, whiteSpace: "nowrap" }}
											onClick={() => {
												if (fromOrders.current) {
													navigate(-1);
													fromOrders.current = false;
												} else {
													navigate("/orders");
												}
											}}
										>
											<Icon name="arrow-left-rounded" />
											Back to orders
										</button>
									</div>

									<h1
										className="mt--xs flex-1 truncate"
										style={{ padding: "0 15px" }}
										title={`Order #${order.lotNumber}`}
									>
										Order #{order.lotNumber}
									</h1>
									{!isLabelMaker(account_type_id) && (
										<button
											className="button right button--md button--primary ml--base"
											id="orderPreviewButton"
											type="button"
											onClick={() => {
												fromOrders.current = false;
												copyOrReorderWholeOrder();
											}}
										>
											Reorder
										</button>
									)}
								</div>
							</div>
						</div>
						<div className="mt--lg mb--lg">
							<div>
								<OrderPreviewDetails order={order} />
								{isLabelMaker(account_type_id) && (
									<OrderPreviewAdditionalInfo
										order={order}
										setOrder={setOrder}
									/>
								)}
								{isPrintStatus(order.status) && (
									<div>
										<div className="orderPreviewInfo mt--md">
											<div
												className="pretitle flex-center-secondary-axis"
												style={{
													justifyContent: "space-between",
													height: "18px",
												}}
											>
												<span className="line-height-1">PRINTING DETAILS</span>
												{preparingForPrint && (
													<span className="animated-gradient-generating-layouts">
														Preparing for printing
													</span>
												)}
											</div>

											{printBatch1 && (
												<PrintBatchComponent
													labelTemplate={order.labelTemplate}
													printBatch={printBatch1}
													printers={printers}
													isLoadingPrintBatch={useGetPrintBatch1.isLoading}
													isLoadingFetchPrinters={useGetPrinters.isLoading}
													preparingForPrint={preparingForPrint}
													fetchPrinters={fetchPrinters}
													fetchPrintBatch={fetchPrintBatch1}
													setPrintBatch={setPrintBatch1}
													setPreparingForPrint={setPreparingForPrint}
												/>
											)}

											{printBatch2 && (
												<PrintBatchComponent
													labelTemplate={order.labelTemplate}
													printBatch={printBatch2}
													printers={printers}
													isLoadingPrintBatch={useGetPrintBatch2.isLoading}
													isLoadingFetchPrinters={useGetPrinters.isLoading}
													preparingForPrint={preparingForPrint}
													fetchPrinters={fetchPrinters}
													fetchPrintBatch={fetchPrintBatch2}
													setPrintBatch={setPrintBatch2}
													setPreparingForPrint={setPreparingForPrint}
												/>
											)}
										</div>
									</div>
								)}
								<OrderPreviewSummary
									order={order}
									reorderStyleNumber={reorderStyleNumber}
								/>
							</div>
						</div>
					</div>
				)}
		</div>
	);
};

export default OrderPreview;
