import React from "react";
import { Link } from "react-router-dom-v5-compat";

import usePageTitle from "Hooks/pageTitleHook";

import { IConfirmationProps } from "Models/GlobalModels";

import "./styles/Confirmation.css";

interface IProps {
	confirmationProps: IConfirmationProps;
	fromOrders: React.MutableRefObject<boolean>;
}

const Confirmation: React.FunctionComponent<IProps> = ({
	confirmationProps,
	fromOrders,
}) => {
	const { message, link } = confirmationProps;

	usePageTitle("Confirmation");

	return (
		<>
			<div className="main__content">
				<div className="container center">
					<svg
						className="checkmark"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 52 52"
					>
						<circle
							className="checkmark__circle"
							cx="26"
							cy="26"
							r="25"
							fill="none"
						/>
						<path
							className="checkmark__check"
							fill="none"
							d="M14.1 27.2l7.1 7.2 16.7-16.8"
						/>
					</svg>
					<h1 className="txtc">{message}</h1>
					<br />

					<Link
						className="normal txtc center mb--md"
						to={link}
						onClick={() => {
							fromOrders.current = false;
						}}
					>
						Back to order
					</Link>

					<Link className="normal txtc center" to="/orders">
						View all orders
					</Link>
				</div>
			</div>
		</>
	);
};

export default Confirmation;
