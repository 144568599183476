import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./Containers/App";
import * as serviceWorker from "./serviceWorker";

import * as Sentry from "@sentry/react";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "react-query/queryClient";

import "react-tooltip/dist/react-tooltip.css";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

const environment = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: "unknown";

Sentry.init({
	environment,
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new Sentry.BrowserTracing()],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 0,
});

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
	<BrowserRouter>
		<CompatRouter>
			<QueryClientProvider client={queryClient}>
				<App />
				<ReactQueryDevtools />
			</QueryClientProvider>
		</CompatRouter>
	</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
