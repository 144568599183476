import { QueryCache, QueryClient } from "@tanstack/react-query";

// const defaultQueryFn = async ({ queryKey }) => {
//   let URL_BASE = process.env.REACT_APP_BACKEND_URL;
// 	if (!URL_BASE) {
// 		URL_BASE = "http://localhost:8080/l";
// 	}
//   const data = await fetch(`$URL_BASE`);
//   return data;
// }

function queryErrorHandler(error: unknown): void {
	const title = error instanceof Error ? error.message : "Error: default";
	console.log("global react query error handler", title);
}

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 30000, // 30 seconds is data considered fresh
			//queryFn: defaultQueryFn,
		},
	},
	queryCache: new QueryCache({
		onError: queryErrorHandler,
	}),
});
