import React, { useEffect } from "react";

import { IOrderState } from "Models/OrderModels";
import { truncateString } from "Utils/utils";

interface IProps {
	order: IOrderState;
	showNoteModal: boolean;

	setShowNoteModal: (showNoteModal: boolean) => void;
	setLocalNote: (note: string | undefined) => void;
}

const OrderNote: React.FunctionComponent<IProps> = ({
	order,
	showNoteModal,

	setShowNoteModal,
	setLocalNote,
}) => {
	const { note } = order;

	/* *************************************************************************************** */

	useEffect(() => {
		setLocalNote(note);
	}, [note, showNoteModal]); // eslint-disable-line react-hooks/exhaustive-deps

	const onEditNote = () => {
		setShowNoteModal(true);
	};

	return (
		<>
			{!note && (
				<div className="orderInfoInput">
					<div className="upcase text--quiet" id="orderInfoHeading">
						Note
					</div>
					<button
						className="btn-no-style btn-link"
						onClick={onEditNote}
						data-testid="orderItemPreview-assignNumber"
					>
						Add Note
					</button>
				</div>
			)}

			{note && (
				<div className="orderInfoInput">
					<div className="upcase text--quiet" id="orderInfoHeading">
						Note
					</div>
					<small id="flexElement">
						<div className="orderInfoValue" data-testid="orderItemPreview-note">
							{truncateString(20, note)}
						</div>
						<button className="btn-no-style btn-link" onClick={onEditNote}>
							Edit
						</button>
					</small>
				</div>
			)}
		</>
	);
};

export default OrderNote;
