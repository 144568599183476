import React, {
	useState,
	useContext,
	useEffect,
	useRef,
	useCallback,
} from "react";
import { toast } from "react-toastify";

import { OrderDispatch } from "Containers/Order";
import { GlobalDispatch } from "Containers/Home";

import SelectedItemsList from "Components/Order/OrderSharedComponents/SelectedItemsList";
import IncrementalSearchInput from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import Icon from "Components/Shared/Icon";

import { ILanguage } from "Models/OrderModels";

import { useFetchInitialValues } from "Hooks/queryHooks/useFetchInitialValues";
import useDataApi from "Hooks/fetchHook";
import useIncrementalSearchHook from "Hooks/incrementalSearchHook";
import useOnClickOutside from "Hooks/outsideClickHook";

import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";
import { EndpointPrefix, isFactory } from "Models/UserModels";

import {
	checkMissingTranslationById,
	getDifferenceBetweenArrays,
	isDpp,
	splitContentCompositionTranslations,
} from "Utils/utils";

interface IProps {
	allLanguagesList: ILanguage[];
}

const Languages: React.FunctionComponent<IProps> = ({ allLanguagesList }) => {
	const { orderItem, setOrderItem, order } = useContext(OrderDispatch);

	const {
		user: { account_type_id },
		setMissingTranslations,
		missingTranslations,
	} = useContext(GlobalDispatch);

	const [showSelect, setShowSelect] = useState(false);
	const [filteredLanguages, setFilteredLanguages] = useState([]) as any;

	const { initialValues } = useFetchInitialValues(order, order.brandId);

	const useSaveFabricContent = useDataApi();

	const { chosenLabelLanguages } = orderItem;

	const { qr_phase } = order.labelTemplate?.settings?.default_values;

	const dropdownRef = useRef(null);

	// Will be removed once translation shortcuts are implemented completely
	const savedLanguageCombinations: any = [
		// {
		// 	name: "EN, JA",
		// 	languageCodes: ["en", "ja"],
		// },
		// {
		// 	name: "EN, FR, CN-S",
		// 	languageCodes: ["en", "fr", "zh-Hans"],
		// },
	];

	const incrementalSearchHookProps = useIncrementalSearchHook(
		allLanguagesList,
		savedLanguageCombinations
	);
	const { setInputText, filteredList, filteredCombinationList } =
		incrementalSearchHookProps;

	let customLanguagesList = () => {
		return allLanguagesList.map((languageObject) => {
			return { id: languageObject.code, name: languageObject.name };
		});
	};

	useEffect(() => {
		const { data } = useSaveFabricContent;
		if (data.message) {
			const { missingTranslations: missingContentComposition } = data.message;

			if (missingContentComposition) {
				setMissingTranslations({
					...missingTranslations,
					missingContentComposition,
				});
			} else {
				queryClient.invalidateQueries({
					queryKey: [QUERY_KEYS.INITIAL_VALUES, account_type_id, order.brandId],
				});
			}
		}
	}, [useSaveFabricContent.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useSaveFabricContent;
		if (error) {
			toast.error(`Unable to get content composition translations. ${error}`);
		}
	}, [useSaveFabricContent.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setFilteredLanguages(chosenLabelLanguages);
	}, [chosenLabelLanguages]); // eslint-disable-line react-hooks/exhaustive-deps

	const addLanguage = (languageCode: string) => {
		const isChecked = filteredLanguages.find(
			(chosenLanguageCode: string) => chosenLanguageCode === languageCode
		);

		if (!isChecked) {
			setFilteredLanguages([...filteredLanguages, languageCode]);
		} else {
			setFilteredLanguages([
				...filteredLanguages.filter((code: string) => code !== languageCode),
			]);
		}
	};

	const removeLanguage = (languageCode: any) => {
		const filteredLanguages = chosenLabelLanguages.filter(
			(addedLanguageCode: string) => addedLanguageCode !== languageCode
		);
		setOrderItem({ chosenLabelLanguages: filteredLanguages });
	};

	const addTranslations = (
		chosenLanguagesCombination: string[] | undefined
	) => {
		let useLanguages = chosenLanguagesCombination;
		if (!useLanguages) {
			useLanguages = filteredLanguages;
		}
		// If label template is DPP that means that we already checked order item for all languages
		// so we do not need to do it again on added label languages
		if (!isDpp(qr_phase) && Array.isArray(useLanguages)) {
			let fabricContent = initialValues.fabricContent.find(
				(fabricContent: any) => fabricContent.id === orderItem.fabricContent
			);

			let addedLanguages = getDifferenceBetweenArrays(
				useLanguages,
				chosenLabelLanguages
			);

			let {
				missingAdditionalComponentsTranslations,
				missingCareInstructionTranslations,
				missingMadeInTranslations,
				missingComponentTranslations,
				missingFabricTranslations,
				missingContentCompositionTranslations,
			} = checkAvailableTranslations(orderItem, addedLanguages);

			if (
				Object.keys(missingComponentTranslations).length > 0 ||
				Object.keys(missingFabricTranslations).length > 0
			) {
				setOrderItem({ fabricContent: -1 });
			} else if (
				Object.keys(missingContentCompositionTranslations).length > 0 &&
				fabricContent
			) {
				const brandIdForFactory = isFactory(account_type_id)
					? `?brandId=${order.brandId}`
					: "";
				useSaveFabricContent.doFetch(
					`/${EndpointPrefix[account_type_id]}/translations/fabricContent${brandIdForFactory}`,
					{
						fabricContent: fabricContent.name,
						languages: addedLanguages,
					},
					"POST"
				);
			}

			if (
				Object.keys(missingMadeInTranslations).length > 0 ||
				Object.keys(missingCareInstructionTranslations).length > 0 ||
				Object.keys(missingAdditionalComponentsTranslations).length > 0 ||
				Object.keys(missingComponentTranslations).length > 0 ||
				Object.keys(missingFabricTranslations).length > 0
			)
				setMissingTranslations({
					missingMadeIn: missingMadeInTranslations,
					missingCareInstructions: missingCareInstructionTranslations,
					missingAdditionalComponents: missingAdditionalComponentsTranslations,
					missingComponents: missingComponentTranslations,
					missingFibers: missingFabricTranslations,
				});
		}

		setOrderItem({ chosenLabelLanguages: useLanguages });
		setShowSelect(false);
		setInputText("");
	};

	const onCancel = useCallback(() => {
		setFilteredLanguages(chosenLabelLanguages);
		setShowSelect(false);
	}, [chosenLabelLanguages]);

	const changeOrderOfLanguages = (filteredLanguages: any) => {
		setFilteredLanguages(filteredLanguages);
		setOrderItem({ chosenLabelLanguages: filteredLanguages });
	};

	const onCombinationClicked = (codes: any) => {
		addTranslations(codes);
		setShowSelect(false);
	};

	useOnClickOutside(
		dropdownRef,
		useCallback(() => {
			onCancel();
		}, [onCancel])
	);

	const checkAvailableTranslations = (item: any, addedLanguages: any) => {
		let missingComponentTranslations = {};
		let missingFabricTranslations = {};
		let missingAdditionalComponentsTranslations = checkMissingTranslationById(
			initialValues.additionalComponents,
			item.selectedAdditionalComponents,
			addedLanguages
		);
		let missingCareInstructionTranslations = checkMissingTranslationById(
			initialValues.careInstructions,
			item.selectedCareInstructions,
			addedLanguages
		);
		let missingMadeInTranslations = checkMissingTranslationById(
			initialValues.madeIns,
			item.madeIn && item.madeIn !== -1 ? [item.madeIn] : [],
			addedLanguages
		);
		let missingContentCompositionTranslations = checkMissingTranslationById(
			initialValues.fabricContent,
			item.fabricContent && item.fabricContent !== -1
				? [item.fabricContent]
				: [],
			addedLanguages
		);

		if (Object.keys(missingContentCompositionTranslations).length > 0) {
			let fabricContent = initialValues.fabricContent.find(
				(fabricContent: any) => fabricContent.id === item.fabricContent
			);
			({
				missingComponent: missingComponentTranslations,
				missingFabric: missingFabricTranslations,
			} = splitContentCompositionTranslations(
				fabricContent.name,
				initialValues.components,
				initialValues.fibers,
				addedLanguages
			));
		}
		return {
			missingAdditionalComponentsTranslations,
			missingCareInstructionTranslations,
			missingMadeInTranslations,
			missingComponentTranslations,
			missingFabricTranslations,
			missingContentCompositionTranslations,
		};
	};

	return (
		<>
			<div className="pretitle">Translations</div>
			<fieldset
				className={`box box--light box--shadowed mb--md ${
					!orderItem.styleNumber ? "blink" : ""
				}`}
			>
				<div
					onClick={() => setShowSelect(!showSelect)}
					className="button button--outline-dark button--sm"
					id="blueBorderButton"
				>
					Add language
				</div>

				{showSelect && (
					<>
						<div className="form-control" id="dropdownList" ref={dropdownRef}>
							<div
								className="form-group-search mt--md"
								id="languagesDropdownGroup"
							>
								<IncrementalSearchInput
									labelText="Type to search"
									name="languages"
									type="search"
									incrementalSearchHookProps={incrementalSearchHookProps}
								/>
							</div>

							{filteredCombinationList &&
								filteredCombinationList.length > 0 && (
									<div style={{ margin: "20px" }}>
										<span className="listing-header__label">Saved</span>

										{filteredCombinationList.map((combination: any) => {
											const { name, languageCodes } = combination;
											return (
												<div
													className="form-check languagesDropdownCheckElement"
													key={name}
													role="button"
													onClick={() => onCombinationClicked(languageCodes)}
												>
													{name}
												</div>
											);
										})}
									</div>
								)}

							<div
								style={{ width: "auto", overflowY: "auto", maxHeight: "16vh" }}
							>
								<div id="languagesDropdownGroup">
									<span className="listing-header__label">Other</span>

									{filteredList.map((language: any) => {
										const isChecked = filteredLanguages.find(
											(chosenLanguageCode: string) =>
												chosenLanguageCode === language.code
										);
										return (
											<div
												className="form-check languagesDropdownCheckElement"
												key={language.code}
											>
												<input
													type="checkbox"
													className="form-check__input"
													value={language.code}
													checked={isChecked ? true : false}
													name={language.code}
													id={language.code}
													onChange={(e) => addLanguage(e.target.value)}
												/>
												<label
													className="form-check__label"
													id="checkLabel"
													htmlFor={language.code}
												>
													{language.name}
												</label>
											</div>
										);
									})}
								</div>
							</div>

							<div className="languagesButtons" id="addLanguages">
								<button
									className={`${
										filteredLanguages.length > 0
											? "button--primary"
											: "button--disabled"
									} button  button--sm `}
									onClick={() => addTranslations(undefined)}
								>
									Confirm
								</button>

								<button
									className="link--danger small btn-no-style"
									onClick={() => onCancel()}
								>
									Cancel
								</button>
							</div>
						</div>
					</>
				)}

				{chosenLabelLanguages.length === 0 && (
					<div className="infoTextContainer">
						<div className="blueIcon">
							<Icon name="info-rounded" />
						</div>
						<div className="text--quiet" style={{ marginTop: 0 }}>
							Once you choose a language it will appear here
						</div>
					</div>
				)}

				<SelectedItemsList
					selectedItemsIds={chosenLabelLanguages}
					changeOrderOfItems={changeOrderOfLanguages}
					allItems={customLanguagesList()}
					itemId={"code"}
					removeItem={removeLanguage}
					testId="languages-selectedLanguages"
				/>
			</fieldset>
		</>
	);
};

export default Languages;
