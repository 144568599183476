import React, { useCallback, useEffect, useRef, useState } from "react";

import StylesListItem from "Components/Styles/StylesListItem";
import Loading from "../Shared/Loading";

interface IProps {
	styles: any;
	stylesListHeight?: number;
	stylesListHeaderHeight?: number;
	invalidInfo: any;
	isLoadingStyles?: boolean;
	offset?: number;
	limitStylesPerPage?: number;
	accordionFixedOpen?: boolean;
	currentData?: any[];

	setOffset?: (offset: number) => void;
	setNumberOfInvalidStyles?: (numberOfInvalidStyles: number) => void;
}

const StylesList: React.FunctionComponent<IProps> = ({
	styles,
	stylesListHeight,
	stylesListHeaderHeight,
	invalidInfo,
	isLoadingStyles = false,
	offset = 0,
	limitStylesPerPage = 10,
	accordionFixedOpen = false,
	currentData = [],

	setNumberOfInvalidStyles = () => {},
	setOffset = () => {},
}) => {
	const TABLE_HEADER_MARGIN_BOTTOM = 20;

	// Calculate heading right padding depending on the thickness of the scroll bar  === START
	const stylesListRef = useRef(null) as any;

	const [listWidth, setListWidth] = useState<any>(0);

	const [scrollPadding, setScrollPadding] = useState(0);

	useEffect(() => {
		let scrollPadding = stylesListRef.current?.offsetWidth - 30 - listWidth;

		if (scrollPadding < 100) {
			setScrollPadding(Math.round(scrollPadding));
		}
		// Return empty cleanup function apparently solved: " Warning: Can't perform a React state update on an unmounted component. ....."
		return () => {};
	}, [styles, listWidth]);

	// Calculate heading right padding depending on the thickness of the scroll bar  === END

	// Infinite scroll === START
	const observer = useRef<IntersectionObserver>();

	// we use useCallback so that we can detect when the ref element (node) is changed
	const lastStyleElementRef = useCallback(
		(node: Element) => {
			if (isLoadingStyles) return;

			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (
					entries[0].isIntersecting &&
					styles.length > offset &&
					currentData &&
					currentData.length === limitStylesPerPage
				) {
					// step_scroll_down
					setOffset(styles.length);
				}
			});

			if (node) observer.current.observe(node);
		},
		[
			isLoadingStyles,
			setOffset,
			styles.length,
			offset,
			currentData,
			limitStylesPerPage,
		]
	);

	// Infinite scroll === END

	return (
		<div style={{ height: stylesListHeight ? stylesListHeight : "100%" }}>
			<section
				className="listing-header listing-header--styles"
				style={{
					height: stylesListHeaderHeight ? stylesListHeaderHeight : "auto",
					paddingRight: scrollPadding > 0 ? scrollPadding + "px" : 0,
				}}
			>
				<span className="listing-header__label">Style Number</span>
				<span className="listing-header__label">Style Description</span>
				<span className="listing-header__label">Season</span>
				<span className="listing-header__label">Made In</span>
				<span className="listing-header__label">Content Composition</span>
				<span className="listing-header__label">Care Instructions</span>
				<span className="listing-header__label">Additional Components</span>
				<span className="listing-header__label">QR Code</span>
			</section>

			<section
				ref={stylesListRef}
				style={{
					height:
						stylesListHeight && stylesListHeaderHeight
							? stylesListHeight -
							  stylesListHeaderHeight -
							  TABLE_HEADER_MARGIN_BOTTOM
							: "90%",
					overflowY: "auto",
				}}
			>
				<>
					{styles.map((style: any, index: number) => {
						return (
							<StylesListItem
								key={`${style.style_number}-${index}`}
								style={style}
								invalidInfo={invalidInfo}
								setNumberOfInvalidStyles={setNumberOfInvalidStyles}
								setListWidth={setListWidth}
								refVar={
									styles.length === index + 1 ? lastStyleElementRef : null
								}
								accordionFixedOpen={accordionFixedOpen}
							/>
						);
					})}
					{isLoadingStyles && (
						<div>
							<Loading
								show={isLoadingStyles}
								text={`Loading...`}
								imgClass=""
								divClass="center-lg"
								imgStyle={{ margin: "-15px 0" }}
							/>
						</div>
					)}
				</>
			</section>
		</div>
	);
};

export default StylesList;
