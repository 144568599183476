import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";

import { IOrderItemState, IOrderState } from "Models/OrderModels";
import { EndpointPrefix } from "Models/UserModels";
import useDataApi from "./fetchHook";
import { GlobalDispatch } from "Containers/Home";

const useReorderHook: any = (order: IOrderState) => {
	const useReorder = useDataApi();
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const navigate = useNavigate();

	useEffect(() => {
		const { data } = useReorder;
		if (data && data.message && data.message.newOrderId) {
			navigate(`/order/${data.message.newOrderId}`);
		}
	}, [useReorder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useReorder;
		if (error) {
			toast.error(`Unable to reorder. ${error}`);
		}
	}, [useReorder.error]); // eslint-disable-line react-hooks/exhaustive-deps
	/* ********************************************************************************** */

	const reorderStyleNumber = (styleNumber: string) => {
		const { orderId } = order;
		if (!useReorder.isLoading) {
			useReorder.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/copy`,
				{ order_id: orderId, style_numbers: [styleNumber] },
				"POST"
			);
		}
	};

	const copyOrReorderWholeOrder = () => {
		const { orderId, orderItems } = order;
		const styleNumbers = orderItems.map(
			(orderItem: IOrderItemState) => orderItem.styleNumber
		);
		if (!useReorder.isLoading) {
			useReorder.doFetch(
				`/${EndpointPrefix[account_type_id]}/orders/copy`,
				{ order_id: orderId, style_numbers: styleNumbers },
				"POST"
			);
		}
	};

	return { copyOrReorderWholeOrder, reorderStyleNumber, useReorder };
};

export default useReorderHook;
