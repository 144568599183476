import { FC } from "react";
import Loading from "Components/Shared/Loading";

interface IProps {
	orderItems: any;
	isLoading: boolean;

	onPrintOrder: () => void;
}

const OrderPrintButton: FC<IProps> = ({
	orderItems,
	isLoading,
	onPrintOrder,
}) => {
	return (
		<div data-tooltip-id="printOrder">
			{!isLoading && (
				<button
					className={`button ${
						orderItems?.length > 0 ? "button--primary" : "button--disabled"
					}
        button-custom-print-order
        `}
					type="button"
					form="orderItemForm"
					onClick={onPrintOrder}
					data-testid="orderHeader-printOrder"
				>
					Print
				</button>
			)}
			{isLoading && (
				<div style={{ width: "107px" }} className="flex-center-both-axis">
					<Loading
						show={true}
						text={`Loading...`}
						imgClass=""
						divClass="createPrintDataLoader"
					/>
				</div>
			)}
		</div>
	);
};

export default OrderPrintButton;
