import { FC, useContext } from "react";
import { OrderDispatch } from "Containers/Order";
import { OrderStatusesFlow } from "Models/OrderModels";

interface IProps {
	forPrintFlow?: boolean;

	onPlaceOrder: () => void;
}

const OrderPlaceButton: FC<IProps> = ({ forPrintFlow, onPlaceOrder }) => {
	const {
		order: { orderItems, status },
	} = useContext(OrderDispatch);

	let isEnabled =
		orderItems.length > 0 && status !== OrderStatusesFlow.CHANGE_REQUESTED.code;

	return (
		<div data-tooltip-id="placeOrder" className="ml--lg">
			<button
				className={` button ${
					isEnabled ? "button--primary" : "button--disabled"
				}
        ${forPrintFlow && "button-custom-place-order"}
          
          `}
				type="button"
				form="orderItemForm"
				onClick={onPlaceOrder}
				data-testid="orderHeader-placeOrder"
			>
				Place
			</button>
		</div>
	);
};

export default OrderPlaceButton;
