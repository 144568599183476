import { PrintStatusesFlow } from "Models/OrderModels";

interface IProps {
	filteredStatus: any;
	filteredPrinter: any;
	printers: any;

	setFilteredStatus: (filterStatus: any) => void;
	setFilteredPrinter: (filteredPrinter: any) => void;
	setSelectedPrintBatchesForBulkAction: (selectedOrders: any) => void;
}

const PrintBatchesHeaderFilters = ({
	filteredStatus,
	filteredPrinter,
	printers,

	setFilteredStatus,
	setFilteredPrinter,
	setSelectedPrintBatchesForBulkAction,
}: IProps) => {
	const findPrinterById = (filteredPrinterId: number) => {
		return printers.find((printer: any) => {
			return printer.id === filteredPrinterId;
		});
	};

	return (
		<section className="listing-filters" id="filters">
			<div className="listing-filters__options" id="filtersOptions">
				<div className="form-group" id="filterContainer">
					<label
						className="listing-header__label filterLabel"
						htmlFor="statusFilter"
						style={{ flexShrink: 0 }}
					>
						Print on
					</label>
					<select
						className="form-control"
						data-testid="ordersStatusFilter"
						id="statusFilter"
						value={
							findPrinterById(filteredPrinter)?.id
								? findPrinterById(filteredPrinter)?.id
								: "All"
						}
						style={{ padding: "0px 5px 1px 5px" }}
						onChange={(event) => {
							setSelectedPrintBatchesForBulkAction({});

							setFilteredPrinter(parseInt(event.target.value));
						}}
					>
						<option value=""> All</option>
						{printers.map((printer: any) => (
							<option key={printer.id} value={printer.id}>
								{printer.name}
							</option>
						))}
					</select>
				</div>
				<div className="form-group" id="filterContainer">
					<label
						className="listing-header__label filterLabel"
						htmlFor="statusFilter"
					>
						Status
					</label>
					<select
						className="form-control"
						data-testid="ordersStatusFilter"
						id="statusFilter"
						value={filteredStatus ? filteredStatus : "All"}
						style={{ padding: "0px 5px 1px 5px" }}
						onChange={(event) => {
							setSelectedPrintBatchesForBulkAction({});
							setFilteredStatus(event.target.value);
						}}
					>
						<option value=""> All</option>
						{Object.values(PrintStatusesFlow)
							.slice(2)
							.map((status: any) => (
								<option key={status.code} value={status.code}>
									{status.name}
								</option>
							))}
					</select>
				</div>
			</div>
		</section>
	);
};
export default PrintBatchesHeaderFilters;
