import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { Tooltip } from "react-tooltip";

import { GlobalDispatch } from "Containers/Home";
import Icon from "Components/Shared/Icon";

interface IProps {
	options: any;
	type: string;
	action: string;

	onChooseOption: (chosenOption: any) => void;
}

const ChooseOption: React.FunctionComponent<IProps> = ({
	options,
	type,
	onChooseOption,
	action,
}) => {
	const [selectedOption, setSelectedOption] = useState(undefined) as any;

	const navigate = useNavigate();

	const { fromOrders } = useContext(GlobalDispatch);

	return (
		<div className="main__content">
			<div className="container">
				<div id="chooseOptionContainer">
					{action === "Create Order" && (
						<div>
							<button
								onClick={() => {
									if (fromOrders.current) {
										navigate(-1);
										fromOrders.current = false;
									} else {
										navigate("/orders");
									}
								}}
								className="link--back btn-no-style"
								style={{ marginLeft: "-160px" }}
							>
								<Icon name="arrow-left-rounded" />
								Back to orders
							</button>
						</div>
					)}
					<fieldset
						className="box box--light box--shadowed"
						id="chooseOptionContent"
					>
						<h1 style={{ marginBottom: "20px" }}>{`Choose a ${type}`}</h1>

						{options.map((option: any, index: number) => {
							return (
								<div
									className="form-check"
									id="chooseOptionRadio"
									key={option.id}
								>
									<input
										className="form-check__input"
										type="radio"
										id={option.id}
										name="radio"
										value={option.id}
										onChange={(e) =>
											setSelectedOption(
												options.find(
													(option: any) => option.id === Number(e.target.value)
												)
											)
										}
									/>

									<label
										className="form-check__label"
										id="chooseOptionLabel"
										htmlFor={option.id}
										data-testid={`chooseOption-${index}`}
									>
										{option.logo ? (
											<img src={option.logo} alt="logo" width="100" />
										) : (
											option.name
										)}
									</label>
								</div>
							);
						})}

						<div className="text--quiet" id="chooseOptionInfoText">
							{`Once you choose you will not be able to change to another ${type.toLowerCase()}
                later without repeating the process from the start.`}
						</div>
						<div
							className="flex-center-both-axis"
							style={{ margin: "40px 35px 0 35px" }}
						>
							<div
								style={{ borderRadius: "50px" }}
								data-tooltip-id="chooseAction"
							>
								<button
									className={
										selectedOption
											? "button button--primary"
											: "button button--disabled"
									}
									data-testid="createOrderButton"
									onClick={() => onChooseOption(selectedOption)}
									type="button"
									id="createOrderButton"
									disabled={!selectedOption}
								>
									{action}
								</button>
								{!selectedOption && (
									<Tooltip
										id="chooseAction"
										className="reactTooltip box--shadowed"
										float={true}
									>
										<span>Choose an option to continue.</span>
									</Tooltip>
								)}
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	);
};

export default ChooseOption;
