import React, {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";

import { ILabelImage } from "Models/OrderModels";
import { LabelTemplateDispatch } from "../../../Containers/LabelTemplate";
import Icon from "Components/Shared/Icon";
import sprite from "images/icons.svg";
import { Tooltip } from "react-tooltip";
import DynamicSections from "./DynamicSections";
import useOnClickOutside from "Hooks/outsideClickHook";

interface IProps {
	zoom: number;
	imagesHoveredSectionId: number | undefined;
	horizontalOrientation: boolean;

	setImagesHoveredSectionId: Dispatch<SetStateAction<number | undefined>>;
	removeSectionById: (id: number) => void;
}

const TemplateImagesPreview: FC<IProps> = ({
	zoom,
	imagesHoveredSectionId,
	horizontalOrientation,

	setImagesHoveredSectionId,
	removeSectionById,
}) => {
	const { images, imagesSectionsData, template, checkGeneralLabelSettings } =
		useContext(LabelTemplateDispatch);

	let { sections: imageSections, margins } = imagesSectionsData;

	const imageRef = useRef<HTMLImageElement>(null);

	const tooltipRef = useRef() as any;

	const [zoomState, setZoomState] = useState(zoom);

	const [imageHeight, setImageHeight] = useState(0);

	const [isSectionSettingsOpen, setIsSectionSettingsOpen] =
		useState<any>(undefined);

	const [
		clickedOutsideEditSectionTooltip,
		setClickedOutsideEditSectionTooltip,
	] = useState(false);

	useOnClickOutside(
		tooltipRef,
		useCallback(() => setClickedOutsideEditSectionTooltip(true), [])
	);

	useEffect(() => {
		setZoomState(zoom);
	}, [zoom]);

	useEffect(() => {
		if (imageRef.current) {
			setImageHeight(imageRef.current.offsetHeight);
		}
	}, [imageRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

	let imageHeightScaled = (imageHeight * zoomState) / 100;

	return (
		<>
			{checkGeneralLabelSettings().length === 0 &&
			imageSections?.length > 0 &&
			images?.length > 0 ? (
				<div className="label-images-wrapper">
					<div
						className={`label-images`}
						style={{
							transform: `scale(${zoomState / 100})`,
							transformOrigin: "0% 0%",
							height: imageHeightScaled + "px",
							display: horizontalOrientation ? "flex" : "inline-block",
						}}
					>
						{images.map((imageObject: ILabelImage, indexOfImages: number) => (
							<div
								key={`${imageObject.name}-${indexOfImages}`}
								className={`${
									horizontalOrientation ? "mr--sm" : "mb--sm"
								} pos-relative `}
							>
								<div
									style={{
										position: "absolute",
										width: "100%",
									}}
								>
									<div
										style={{
											padding: `${margins.top}px ${margins.right}px ${margins.bottom}px ${margins.left}px`,
										}}
									>
										{imageSections[indexOfImages]?.map(
											(section: any, cellIndex: number) => {
												return (
													<div
														id={`${section.name + cellIndex}`}
														key={`${section.name + cellIndex}`}
														style={{
															height: section.height,
															marginBottom: "1px",
														}}
														onMouseEnter={() => {
															setImagesHoveredSectionId(section.id);
														}}
														onMouseLeave={() => {
															setImagesHoveredSectionId(undefined);
														}}
													>
														<div
															className={`label-images-section ${
																imagesHoveredSectionId === section.id &&
																!isSectionSettingsOpen &&
																"label-images-section-hovered"
															}
                                ${
																	imagesHoveredSectionId === section.id &&
																	isSectionSettingsOpen &&
																	"label-images-section-edit-on"
																}
                                `}
															style={{ height: "100%" }}
														>
															{
																<div
																	style={{
																		visibility:
																			imagesHoveredSectionId === section.id &&
																			!isSectionSettingsOpen
																				? "visible"
																				: "hidden",
																	}}
																>
																	{section.name}
																</div>
															}

															<div className="flex">
																<div
																	className="text-uppercase"
																	onClick={() => {
																		let currentSection = template.sections.find(
																			(templateSection: any) => {
																				return (
																					templateSection.id === section.id
																				);
																			}
																		);
																		setIsSectionSettingsOpen({
																			...currentSection,
																		});
																	}}
																>
																	<svg
																		className="icon mr--base"
																		data-event="click"
																		data-tooltip-id={section.name + section.id}
																		style={{
																			width: "20px",
																			height: "20px",
																			cursor: "pointer",
																		}}
																	>
																		<use xlinkHref={`${sprite}#icon-edit`} />
																	</svg>
																</div>
																<div>
																	<button
																		className="btn-no-style mr--xs"
																		style={{
																			position: "relative",
																			fill: "red",
																		}}
																		onClick={() =>
																			removeSectionById(section.id)
																		}
																	>
																		<Icon name="cross-rounded-filled" />
																	</button>
																</div>
															</div>
														</div>
														<Tooltip
															className="drawing-area-react-tooltip box--shadowed fixed"
															id={section.name + section.id}
															aria-haspopup="true"
															clickable={true}
															place="right"
															openOnClick
															isOpen={isSectionSettingsOpen?.id === section?.id}
														>
															<div
																ref={
																	isSectionSettingsOpen?.id === section?.id
																		? tooltipRef
																		: null
																}
															>
																<DynamicSections
																	currentSelectedItem={isSectionSettingsOpen}
																	setIsSectionSettingsOpen={
																		setIsSectionSettingsOpen
																	}
																	clickedOutsideEditSectionTooltip={
																		clickedOutsideEditSectionTooltip
																	}
																	setClickedOutsideEditSectionTooltip={
																		setClickedOutsideEditSectionTooltip
																	}
																	setImagesHoveredSectionId={
																		setImagesHoveredSectionId
																	}
																	removeSectionById={removeSectionById}
																/>
															</div>
														</Tooltip>
													</div>
												);
											}
										)}
									</div>
								</div>
								<img
									ref={indexOfImages === 0 ? imageRef : null}
									className=""
									alt="label template"
									src={imageObject.image}
								/>
							</div>
						))}
						<div
							style={{
								width: "320px",
								height: "50px",
								flexShrink: 0,
							}}
						></div>
					</div>
				</div>
			) : (
				<div className="no-images-placeholder">
					<div className="flex flex-column flex-center-secondary-axis">
						<div style={{ width: "80px", height: "80px", margin: "20px" }}>
							<Icon
								name="new-template"
								className="no-images-placeholder-icon"
							/>
						</div>

						{checkGeneralLabelSettings().length > 0 && (
							<div
								className="flex"
								style={{ flexWrap: "wrap", justifyContent: "center" }}
							>
								<div style={{ textAlign: "center" }}>
									To show Label images please fill next fields:
								</div>
								{checkGeneralLabelSettings().map(
									(missingItem: string, index: number) => {
										return (
											<div key={missingItem} className="ml--xs">
												{missingItem}
												{checkGeneralLabelSettings().length - 1 === index
													? "."
													: ","}
											</div>
										);
									}
								)}
							</div>
						)}
						{template.sections?.length === 0 && (
							<div className="mt--base">Please add section</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default TemplateImagesPreview;
