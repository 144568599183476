import { GlobalDispatch } from "Containers/Home";
import useDataApi from "Hooks/fetchHook";
import { IOrderState } from "Models/OrderModels";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { isFactory } from "./../../Models/UserModels";

const useUpdateOrder = (
	order: IOrderState,
	showSaveResultNotificationRef: { current: any },
	setOrder: any,
	setLoadedOrder: any,
	saveOrderFinishedSuccessfully: React.MutableRefObject<boolean>
) => {
	const useUpdateOrder = useDataApi();
	const { user } = useContext(GlobalDispatch);

	useEffect(() => {
		const { data } = useUpdateOrder;
		if (data.status === "ok") {
			if (showSaveResultNotificationRef.current) {
				toast.success("The order was saved successfully.");
			}

			let newOrderStatus = order.status;
			if (
				isFactory(user.account_type_id) &&
				order.status === 4 &&
				showSaveResultNotificationRef.current
			) {
				newOrderStatus = 1;
			}

			const updatedOrder: IOrderState = {
				...order,
				log: {
					...order.log,
					lastChangeBy: user.name,
					lastChangeAt: "a few moments ago",
				},
				status: newOrderStatus,
			};

			setOrder(updatedOrder);
			setLoadedOrder(updatedOrder);
			if (!showSaveResultNotificationRef.current) {
				saveOrderFinishedSuccessfully.current = true;
			}
		}
	}, [useUpdateOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useUpdateOrder;
		if (error) {
			toast.error(
				`Unable to save order. ${error[0].toUpperCase() + error.slice(1)}.`
			);

			let {
				shippingAddress,
				billingAddress,
				preferredShippingMethod,
				...orderWithoutShippingDetails
			} = order;
			setOrder(orderWithoutShippingDetails);
		}
	}, [useUpdateOrder.error]); // eslint-disable-line react-hooks/exhaustive-deps

	let updateOrderDoFetch = useUpdateOrder.doFetch;
	let updateOrderIsLoading = useUpdateOrder.isLoading;

	return { updateOrderDoFetch, updateOrderIsLoading };
};

export default useUpdateOrder;
