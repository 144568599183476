import React, {
	useState,
	useEffect,
	useContext,
	useRef,
	useCallback,
	useMemo,
} from "react";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";

import { EndpointPrefix, isBrand } from "Models/UserModels";
import { PSEUDO_URL_QUERY_VALUES } from "Models/OrderModels";

import useDataApi from "Hooks/fetchHook";
import useWindowSize from "Hooks/windowSizeHook";
import usePageTitle from "Hooks/pageTitleHook";
import { GlobalDispatch } from "./Home";
import LabelMakerActions from "Components/Orders/LabelMakerActions";
import Loading from "Components/Shared/Loading";
import OrdersListItem from "Components/Orders/OrdersListItem";
import SearchFilter from "Components/Shared/SearchFilter";

import "./styles/Orders.css";
import Icon from "Components/Shared/Icon";
import OrdersHeaderActionsToolbar from "Components/Orders/OrdersHeaderActionsToolbar";
import OrdersHeaderFilters from "Components/Orders/OrdersHeaderFilters";
import OrdersListHeader from "Components/Orders/OrdersListHeader";
import { useFetchAccExec } from "Hooks/queryHooks/useFetchAccExec";
import { useFetchBrands } from "Hooks/queryHooks/useFetchBrands";
import { toast } from "react-toastify";

// use Cases for Orders page
// 1. go to /orders page - on mount - step_1, step_2
// 2. scroll down ( infinite scroll ) - step_scroll_down, step_4

// 3. apply filter
//       a) while offset = 0   - step_3a
//       b) while offset > 0   - step_3b, step_4

// 4. reset by clicking Orders link in nav bar
//       a) while offset = 0 and no filter is set    - step_5
//       b) while offset = 0 and some filter is set  - step_5, step_3a
//       c) while offset > 0 and no filter is set    - step_5, step_4
//       d) while offset > 0 and some filter is set  - step_5, step_3b, step_4

// 5. force refresh on app refresh button
//       a) while offset = 0 and no filter is set    - step_6a
//       b) while offset > 0 and no filter is set    - step_6b

// 6. window size change - step_1, step_2, step_4

// 7. refresh browser tab ( handled with use Case 1 ) - step_1, step_2
// 8. copy/paste link ( handled with use Case 1 ) - step_1, step_2

const Orders: React.FunctionComponent = () => {
	const {
		user: { account_type_id, id: userId },
		setResetOrdersFilters,
		setError,
		resetOrdersFilters,
		fromOrders,
	} = useContext(GlobalDispatch);

	const navigate = useNavigate();
	const location = useLocation();

	const useGetOrders = useDataApi();
	const { brands } = useFetchBrands(account_type_id, setError);

	const windowSize = useWindowSize();

	const [orders, setOrders] = useState<any[]>([]);
	const [isLoadingOrders, setIsLoadingOrders] = useState(false);

	const [filteredStatus, setFilteredStatus] = useState(undefined) as any;
	const [searchText, setSearchText] = useState("");
	const [filteredBrand, setFilteredBrand] = useState(undefined) as any;
	const [filteredAccountExecutive, setFilteredAccountExecutive] = useState(
		undefined
	) as any;

	const [offset, setOffset] = useState<any>(0);
	const [limitOrdersPerPage, setLimitOrdersPerPage] = useState(0);

	const [accountExecutiveBrands, setAccountExecutiveBrands] = useState(
		undefined
	) as any;

	const [labelMakerData, setLabelMakerData] = useState({});
	const [selectedOrdersForBulkAction, setSelectedOrdersForBulkAction] =
		useState({}) as any;

	const [ordersHeight, setOrdersHeight] = useState(0);

	const [previousFilterQuery, setPreviousFilterQuery] = useState(
		undefined
	) as any;

	const ordersRef = useRef(null) as any;
	const ordersListRef = useRef(null) as any;

	usePageTitle("Orders");

	useEffect(() => {
		fromOrders.current = false;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// +++++++++++++++++++++++++++++++ React query start ++++++++++++++++++++++++++++++

	const { accountExecutivesInfo } = useFetchAccExec(account_type_id, setError);

	// ++++++++++++++++++++++++++++++++  React query end  +++++++++++++++++++++++++++++

	// get Account Executives brands list in { AccountExecutiveId : [brands] } format
	let accountExecutiveBrandsList = useMemo(() => {
		let accountExecutiveBrandsList: { [id: string]: any } = {};

		if (!isBrand(account_type_id) && accountExecutivesInfo) {
			accountExecutivesInfo.forEach(
				(accExec: { user_id: any; user_brands: any }) => {
					accountExecutiveBrandsList = {
						...accountExecutiveBrandsList,
						[accExec.user_id]: accExec.user_brands,
					};
				}
			);
		}

		return accountExecutiveBrandsList;
	}, [accountExecutivesInfo, account_type_id]);

	// get All brands list in [{ brand_id: id, brand_name: name   },....] format
	let accountExecutiveAllBrandsList = useMemo(() => {
		let accountExecutiveAllBrandsList: {
			brand_id: string;
			brand_name: string;
		}[] = [];

		if (!isBrand(account_type_id) && brands && brands.length > 0) {
			brands.forEach((brand: any) => {
				accountExecutiveAllBrandsList.push({
					brand_id: brand.id,
					brand_name: brand.name,
				});
			});
		}

		return accountExecutiveAllBrandsList;
	}, [account_type_id, brands]);

	// get current user if he is also Account executive otherwise set undefined
	let currentUserWhoIsAlsoAccountExecutive = useMemo(() => {
		if (!isBrand(account_type_id) && accountExecutivesInfo) {
			let currentUserWhoIsAlsoAccountExecutive = accountExecutivesInfo.find(
				(accExec: { user_id: number }) => {
					return accExec.user_id === userId;
				}
			);

			return currentUserWhoIsAlsoAccountExecutive;
		}
	}, [accountExecutivesInfo, account_type_id, userId]);

	useEffect(() => {
		// set Filtered Account Executive on mount to current user who is also Account Executive
		if (currentUserWhoIsAlsoAccountExecutive) {
			setFilteredAccountExecutive(currentUserWhoIsAlsoAccountExecutive.user_id);
		}
	}, [currentUserWhoIsAlsoAccountExecutive]);

	useEffect(() => {
		if (!isBrand(account_type_id) && filteredAccountExecutive) {
			setAccountExecutiveBrands(
				accountExecutiveBrandsList[filteredAccountExecutive]
			);
		} else if (!isBrand(account_type_id) && !filteredAccountExecutive) {
			setAccountExecutiveBrands(accountExecutiveAllBrandsList);
		}
	}, [
		filteredAccountExecutive,
		account_type_id,
		accountExecutiveBrandsList,
		accountExecutiveAllBrandsList,
	]);

	/* ******************** Infinite scroll START ************************ */

	const observer = useRef<IntersectionObserver>();

	// we use useCallback so that we can detect when the ref element (node) is changed
	const lastOrderElementRef = useCallback(
		(node: Element) => {
			if (isLoadingOrders) return;

			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (
					entries[0].isIntersecting &&
					orders.length > offset &&
					useGetOrders.data &&
					useGetOrders.data.message.length === limitOrdersPerPage
				) {
					// step_scroll_down
					setOffset(orders.length);
				}
			});

			if (node) observer.current.observe(node);
		},
		[
			isLoadingOrders,
			orders.length,
			offset,
			useGetOrders.data,
			limitOrdersPerPage,
		]
	);

	/* ******************** Infinite scroll END ************************ */

	/* ******************** Align heading and columns in table START ************************ */

	const headerRef = useRef<any>(null);

	const [listWidth, setListWidth] = useState<any>(0);

	const resizeObserver = useRef<any>(
		new ResizeObserver((entries) => {
			const { width } = entries[0].contentRect;

			setListWidth(width);
		})
	);

	const [scrollPadding, setScrollPadding] = useState(0);

	useEffect(() => {
		if (ordersListRef.current) {
			resizeObserver.current.observe(ordersListRef.current);
		}
		let el = ordersListRef.current;
		let obs = resizeObserver.current;
		return () => {
			if (el) {
				obs.unobserve(el);
			}
		};
	}, [ordersListRef, orders]);

	useEffect(() => {
		let scrollPadding = headerRef.current?.offsetWidth + 30 - listWidth;

		if (scrollPadding < 100) {
			setScrollPadding(Math.round(scrollPadding));
		}
	}, [listWidth]);

	/* ******************** Align heading and columns in table END ************************ */

	/* ******************** Calculate Orders table height for limit START ************************ */

	const resizeObserverOrders = useRef<any>(
		new ResizeObserver((entries) => {
			const { height } = entries[0].contentRect;

			setOrdersHeight(height);
		})
	);

	useEffect(() => {
		if (ordersRef.current) {
			resizeObserverOrders.current.observe(ordersRef.current);
		}
		let el = ordersRef.current;
		let obs = resizeObserverOrders.current;
		return () => {
			if (el) {
				obs.unobserve(el);
			}
		};
	}, [ordersRef]);

	/* ******************** Calculate Orders table height for limit END ************************ */

	/* ******************** Use cases START ************************ */
	// step_1

	useEffect(() => {
		const LIST_ITEM_ROW_SIZE = 72;

		// on mount
		if (ordersHeight && limitOrdersPerPage === 0) {
			setLimitOrdersPerPage(
				// get round down number of orders and add two more so we have scroll
				((ordersHeight / LIST_ITEM_ROW_SIZE) >> 0) + 2
			);
		}
	}, [ordersHeight]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const LIST_ITEM_ROW_SIZE = 72;

		// on window height change
		if (ordersHeight) {
			setLimitOrdersPerPage(
				// get round down number of orders and add two more so we have scroll
				((ordersHeight / LIST_ITEM_ROW_SIZE) >> 0) + 2
			);
		}
	}, [windowSize[1]]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_2
	useEffect(() => {
		if (
			limitOrdersPerPage > 0 &&
			!getAndSetFilters() &&
			orders &&
			orders.length === 0
		) {
			refreshOrders();
		} else if (limitOrdersPerPage > 0 && orders.length > 0) {
			setOffset(orders.length);
		}
	}, [limitOrdersPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_3
	useEffect(() => {
		// step_3a
		if (offset === 0 && limitOrdersPerPage > 0) {
			refreshOrders(false, true);
		}
		//step_3b
		else if (offset > 0 && limitOrdersPerPage > 0) {
			setOrders([]);
			setOffset(0);
		}
	}, [searchText, filteredStatus, filteredBrand, filteredAccountExecutive]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_4
	useEffect(() => {
		if (limitOrdersPerPage > 0) {
			refreshOrders();
		}
	}, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

	// step_5
	useEffect(() => {
		if (resetOrdersFilters) {
			setResetOrdersFilters(false);

			if (filteredStatus || filteredBrand || searchText) {
				setFilteredStatus(undefined);
				setFilteredBrand(undefined);
				setSearchText("");
			} else if (offset > 0) {
				setOrders([]);
				setOffset(0);
			}

			// reset filter to current user or to All
			if (
				!isBrand(account_type_id) &&
				accountExecutivesInfo &&
				currentUserWhoIsAlsoAccountExecutive &&
				filteredAccountExecutive !==
					currentUserWhoIsAlsoAccountExecutive.user_id
			) {
				setFilteredAccountExecutive(
					currentUserWhoIsAlsoAccountExecutive.user_id
				);
			} else if (
				!isBrand(account_type_id) &&
				accountExecutivesInfo &&
				!currentUserWhoIsAlsoAccountExecutive
			) {
				setFilteredAccountExecutive(undefined);
			}

			setSelectedOrdersForBulkAction({});

			let accExecPseudoQueryParams = "";

			if (!isBrand(account_type_id) && filteredAccountExecutive) {
				accExecPseudoQueryParams = `&${PSEUDO_URL_QUERY_VALUES.ACCOUNT_EXECUTIVE}=${filteredAccountExecutive}`;
			}

			navigate(
				{
					pathname: location.pathname,
					search: `${PSEUDO_URL_QUERY_VALUES.OFFSET}=0&${PSEUDO_URL_QUERY_VALUES.LIMIT}=${limitOrdersPerPage}${accExecPseudoQueryParams}`,
				},
				{ replace: true }
			);
		}
	}, [resetOrdersFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ******************** Use cases END ************************ */

	/* ******************** Get orders API call ************************ */
	useEffect(() => {
		const { error } = useGetOrders;
		if (error) {
			toast.error(`Unable to get orders. ${error}`);
		}
	}, [useGetOrders.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetOrders;
		if (data.message) {
			setOrders((prevOrders) => [...prevOrders, ...data.message]);
		}
	}, [useGetOrders.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setIsLoadingOrders(useGetOrders.isLoading);
	}, [useGetOrders.isLoading]);
	/* ****************************************************************** */

	/* ******************** functions START ***************************/

	const getAndSetFilters = () => {
		const params = new URLSearchParams(location.search);

		const status = params.get(PSEUDO_URL_QUERY_VALUES.STATUS);
		const searchText = params.get(PSEUDO_URL_QUERY_VALUES.SEARCH_TEXT);
		const brand = params.get(PSEUDO_URL_QUERY_VALUES.BRAND);
		const accExec = params.get(PSEUDO_URL_QUERY_VALUES.ACCOUNT_EXECUTIVE);

		status && setFilteredStatus(status);
		searchText && setSearchText(searchText);
		brand && setFilteredBrand(brand);
		accExec && setFilteredAccountExecutive(accExec);

		return status || searchText || brand;
	};

	const refreshOrders = (
		forceRefresh: boolean = false,
		resetOrdersList: boolean = false
	) => {
		const searchTextCleared = searchText?.trim();
		const filterBySearchText =
			searchTextCleared?.length > 0
				? `&filter=${encodeURIComponent(searchTextCleared)}`
				: "";
		const filterByStatus = filteredStatus
			? `&status=${encodeURIComponent(filteredStatus)}`
			: "";
		const filterByBrand = filteredBrand ? `&brandId=${filteredBrand}` : "";

		const filterByAccountExecutive = filteredAccountExecutive
			? `&accExecId=${filteredAccountExecutive}`
			: "";

		const filterQuery = `/${EndpointPrefix[account_type_id]}/orders?offset=${offset}&limit=${limitOrdersPerPage}${filterBySearchText}${filterByStatus}${filterByBrand}${filterByAccountExecutive}`;

		if (resetOrdersList && filterQuery !== previousFilterQuery) setOrders([]);

		if (filterQuery !== previousFilterQuery || forceRefresh) {
			if (forceRefresh) {
				setOrders([]);
			}

			// set pseudo URL
			navigate(
				{
					pathname: location.pathname,
					search: toPseudonymUrlQuery(filterQuery),
				},
				{ replace: true }
			);

			useGetOrders.doFetch(filterQuery);
			setPreviousFilterQuery(filterQuery);
		}
	};

	const toPseudonymUrlQuery = (filterQuery: string) => {
		const params = new URLSearchParams(filterQuery);

		const offset = params.get(
			`/${EndpointPrefix[account_type_id]}/orders?offset`
		);
		const searchText = params.get("filter")
			? `&${PSEUDO_URL_QUERY_VALUES.SEARCH_TEXT}=${params.get("filter")}`
			: "";
		const status = params.get("status")
			? `&${PSEUDO_URL_QUERY_VALUES.STATUS}=${params.get("status")}`
			: "";
		const brand = params.get("brandId")
			? `&${PSEUDO_URL_QUERY_VALUES.BRAND}=${params.get("brandId")}`
			: "";
		const accountExecutive = params.get("accExecId")
			? `&${PSEUDO_URL_QUERY_VALUES.ACCOUNT_EXECUTIVE}=${params.get(
					"accExecId"
			  )}`
			: "";

		const pseudoFilterQuery = `${PSEUDO_URL_QUERY_VALUES.OFFSET}=${offset}&${PSEUDO_URL_QUERY_VALUES.LIMIT}=${limitOrdersPerPage}${searchText}${status}${brand}${accountExecutive}`;

		return pseudoFilterQuery;
	};

	const forceRefreshOrders = () => {
		//step_6b
		if (offset > 0) {
			setOrders([]);
			setOffset(0);
		}
		// step_6a
		else {
			refreshOrders(true);
		}
	};

	/* ******************** functions END ***************************/

	return (
		<>
			<div className="orders-main__content" data-testid="ordersScreen">
				<div className="orders-container">
					<header data-testid="ordersHeader" className="orders-header">
						<div className="row">
							<div className="col-sm-4">
								<h1 className="section__title">Your Orders</h1>
							</div>
							<div className="col-xs-offset-5 col-sm-3 ordersSearchContainer">
								<SearchFilter
									setSearchText={setSearchText}
									searchTextValue={searchText}
									placeholderText="Enter style number or reference number"
								/>
							</div>
						</div>
						<div className="toolbarAndFiltersContainer">
							<OrdersHeaderActionsToolbar
								setSelectedOrdersForBulkAction={setSelectedOrdersForBulkAction}
								selectedOrdersForBulkAction={selectedOrdersForBulkAction}
								forceRefreshOrders={forceRefreshOrders}
							/>
							<OrdersHeaderFilters
								accountExecutivesInfo={accountExecutivesInfo}
								accountExecutiveBrands={accountExecutiveBrands}
								filteredAccountExecutive={filteredAccountExecutive}
								setFilteredAccountExecutive={setFilteredAccountExecutive}
								filteredBrand={filteredBrand}
								setFilteredBrand={setFilteredBrand}
								filteredStatus={filteredStatus}
								setFilteredStatus={setFilteredStatus}
								setSelectedOrdersForBulkAction={setSelectedOrdersForBulkAction}
							/>
						</div>
					</header>

					{isLoadingOrders && orders.length === 0 && (
						<div style={{ height: "100%" }} className="flex-center-both-axis">
							<Loading
								show={true}
								text={`Loading...`}
								imgClass=""
								divClass=""
							/>
						</div>
					)}

					<div className="flex flex-1 pos-relative" ref={ordersRef}>
						{orders && orders.length > 0 && (
							<div className="orders-list">
								<OrdersListHeader
									headerRef={headerRef}
									scrollPadding={scrollPadding}
								/>
								<section className="overflow-y-auto flex-1" ref={ordersListRef}>
									{orders.map((order: any, index) => {
										return (
											<div
												key={order.id}
												style={{ height: "74px" }}
												data-testid={`orders-row-${index}`}
											>
												<OrdersListItem
													refVar={
														orders.length === index + 1
															? lastOrderElementRef
															: null
													}
													order={order}
													accountTypeId={account_type_id}
													setLabelMakerData={setLabelMakerData}
													selectedOrdersForBulkAction={
														selectedOrdersForBulkAction
													}
													setSelectedOrdersForBulkAction={
														setSelectedOrdersForBulkAction
													}
												/>
											</div>
										);
									})}
									<div>
										<Loading
											show={isLoadingOrders}
											text={`Loading...`}
											imgClass=""
											divClass="center-lg"
										/>
									</div>
								</section>
							</div>
						)}

						{!isLoadingOrders && orders && orders.length === 0 && (
							<div className="emptyOrdersContainer flex-1">
								<div className="box box--sm box--bordered" id="emptyOrders">
									<h1 className="text--quiet">No Orders</h1>
									<Icon name="order" className="noOrdersIcon" />
									<div
										className="txtc text-sm text--quiet mt--base"
										style={{ whiteSpace: "pre-line" }}
									>
										You have no orders at the moment.
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<LabelMakerActions
				labelMakerData={labelMakerData}
				setLabelMakerData={setLabelMakerData}
				forceRefreshOrders={forceRefreshOrders}
			/>
		</>
	);
};

export default Orders;
