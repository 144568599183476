import { FC, useContext, useEffect } from "react";
import Icon from "./Icon";
import sprite from "images/icons.svg";
import { EndpointPrefix } from "Models/UserModels";
import { GlobalDispatch } from "Containers/Home";
import useDataApi from "Hooks/fetchHook";
import { toast } from "react-toastify";
import Loading from "./Loading";
import { PrinterStatuses } from "Models/OrderModels";
import { capitalize } from "Utils/utils";

interface IProps {
	chosenPrinter: any;
	chosenPrinterForEditing: any;
	showDrawer: boolean;

	setShowPrinterEditModal: React.Dispatch<React.SetStateAction<any>>;
	setChosenPrinter: React.Dispatch<React.SetStateAction<any>>;
	setChosenPrinterForEditing: React.Dispatch<React.SetStateAction<any>>;
	fetchPrinters: () => void;
}

const PrinterInfo: FC<IProps> = ({
	chosenPrinter,
	chosenPrinterForEditing,
	showDrawer,

	setShowPrinterEditModal,
	setChosenPrinter,
	setChosenPrinterForEditing,
	fetchPrinters,
}) => {
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const useGetPrinter = useDataApi();
	const useCheckIsPrinterOnline = useDataApi();
	const useConfirmPrinterMaterialChange = useDataApi();
	const usePrintTestLabel = useDataApi();
	const useRemoveStackerError = useDataApi();

	// Get Printer START *****************************************************

	useEffect(() => {
		if (showDrawer) {
			useGetPrinter.doFetch(
				`/${EndpointPrefix[account_type_id]}/printer/${chosenPrinter.id}`
			);
		}
	}, [showDrawer]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useGetPrinter;
		if (data && data.message) {
			setChosenPrinter(data.message);
			setChosenPrinterForEditing(data.message);
		}
	}, [useGetPrinter.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetPrinter;
		if (error) {
			toast.error("Unable to get Printer info.");
		}
	}, [useGetPrinter.error]); // eslint-disable-line react-hooks/exhaustive-deps

	// Get Printer END *****************************************************

	// Check if printer is online START **************************************

	useEffect(() => {
		const { data } = useCheckIsPrinterOnline;
		if (data && data.message) {
			setChosenPrinter(data.message);
			setChosenPrinterForEditing(data.message);
			toast.success("Printer is back online.");
		}
	}, [useCheckIsPrinterOnline.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useCheckIsPrinterOnline;

		if (error && error === "Printer is offline") {
			toast.error(`Printer is still offline!`);
		} else if (error) {
			toast.error(`Unable to check if printer is online. ${error}`);
		}
	}, [useCheckIsPrinterOnline.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const checkIsPrinterOnline = () => {
		useCheckIsPrinterOnline.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/checkOnline`,
			{
				printerId: chosenPrinter.id,
			},
			"POST"
		);
	};

	// Check if printer is online END **************************************

	// Confirm printer material change START **************************************

	useEffect(() => {
		const { data } = useConfirmPrinterMaterialChange;
		if (data && data.message) {
			setChosenPrinter(data.message);
			setChosenPrinterForEditing(data.message);

			fetchPrinters();
			toast.success("Printer material successfully changed.");
		}
	}, [useConfirmPrinterMaterialChange.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useConfirmPrinterMaterialChange;

		if (error) {
			toast.error(`Unable to confirm printer material change. ${error}`);
		}
	}, [useConfirmPrinterMaterialChange.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const onConfirmMaterialChange = (item: string) => {
		useConfirmPrinterMaterialChange.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/confirmChange`,
			{
				printerId: chosenPrinter.id,
				settings: chosenPrinterForEditing.settings,
				item,
			},
			"POST"
		);
	};

	// Confirm printer material change END **************************************

	// Print test label START **************************************

	useEffect(() => {
		const { data } = usePrintTestLabel;
		if (data && data.message) {
			let printerInfo = data.message;
			setChosenPrinter(printerInfo);
			setChosenPrinterForEditing(printerInfo);
			if (printerInfo?.status_code >= PrinterStatuses.ERROR_OUT_OF_PAPER.code) {
				toast.error(`Unable to print test label.`);
			} else {
				toast.success("Test label successfully printed.");
			}
		}
	}, [usePrintTestLabel.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = usePrintTestLabel;

		if (error) {
			toast.error(`Unable to print test label. ${error}`);
		}
	}, [usePrintTestLabel.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const printTestLabel = () => {
		usePrintTestLabel.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/printTestLabel`,
			{
				printerId: chosenPrinter.id,
			},
			"POST"
		);
	};

	// Print test label END **************************************

	// Remove stacker full START **************************************

	useEffect(() => {
		const { data } = useRemoveStackerError;
		if (data && data.message) {
			let printerInfo = data.message;
			setChosenPrinter(printerInfo);
			setChosenPrinterForEditing(printerInfo);
			if (printerInfo?.status_code >= PrinterStatuses.ERROR_OUT_OF_PAPER.code) {
				toast.error(`Unable to remove stacker error status.`);
			} else {
				toast.success("Stacker error status successfully removed.");
			}
		}
	}, [useRemoveStackerError.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useRemoveStackerError;

		if (error) {
			toast.error(`Unable to remove stacker error status. ${error}`);
		}
	}, [useRemoveStackerError.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const removeStackerFull = () => {
		useRemoveStackerError.doFetch(
			`/${EndpointPrefix[account_type_id]}/printer/removeStackerError`,
			{
				printerId: chosenPrinter.id,
			},
			"POST"
		);
	};

	// Remove stacker full END **************************************

	const printerHasError =
		chosenPrinter?.status_code >= PrinterStatuses.ERROR_OUT_OF_PAPER.code;

	const printerOffline =
		chosenPrinter?.status_code === PrinterStatuses.ERROR_OFFLINE.code;

	const printerOutOfRibbon =
		chosenPrinter?.status_code === PrinterStatuses.ERROR_OUT_OF_RIBBON.code;

	const printerOutOfPaper =
		chosenPrinter?.status_code === PrinterStatuses.ERROR_OUT_OF_PAPER.code;

	const printerCallSupportErrors = [
		PrinterStatuses.ERROR_CONNECTION_LOST.code,
		PrinterStatuses.ERROR_QUANTITY.code,
		PrinterStatuses.ERROR_SYNTAX.code,
		PrinterStatuses.ERROR_UNKNOWN.code,
	].includes(chosenPrinter?.status_code);

	const printerIconColor = () => {
		if (printerOffline) {
			return "red";
		} else if (!printerOffline && usePrintTestLabel.isLoading) {
			return "#2f80ed";
		} else if (!printerOffline && !usePrintTestLabel.isLoading) {
			return "rgb(0 194 0)";
		}
	};

	const printerIconTitle = () => {
		if (printerOffline) {
			return "Printer offline";
		} else if (!printerOffline && usePrintTestLabel.isLoading) {
			return "Printing";
		} else if (!printerOffline && !usePrintTestLabel.isLoading) {
			return "Printer online";
		}
	};

	let animatePrinterIcon =
		(usePrintTestLabel.isLoading || useCheckIsPrinterOnline.isLoading) &&
		"printer-info-print-icon-printing";

	return (
		<div>
			<div
				style={{ height: "112px" }}
				className="flex flex-center-secondary-axis ml--base text--lg"
			>
				<>PRINTER INFO</>
			</div>

			<div
				style={{
					height: "100%",
					overflow: "auto",
				}}
			>
				<Loading
					show={useGetPrinter.isLoading}
					imgClass={""}
					divClass={"flex flex-center-both-axis"}
				/>

				{!useGetPrinter.isLoading && (
					<>
						<div style={{ borderBottom: "5px solid #DFDFDF" }}>
							<div className="flex flex-center-both-axis">
								<h1
									className="pos-relative flex flex-center-both-axis "
									style={{ height: "60px" }}
								>
									<span>{chosenPrinter?.name}</span>
									<span
										title={printerIconTitle()}
										className="pos-absolute"
										style={{ right: "-100px" }}
									>
										<svg
											className={`printer-info-print-icon ${animatePrinterIcon}`}
											style={{
												fill: `${printerIconColor()}`,
											}}
										>
											<use xlinkHref={`${sprite}#icon-printer-online`} />
										</svg>
									</span>
								</h1>
								{printerOffline && (
									<div
										className={`mb--sm flex flex-center-secondary-axis`}
										style={{ marginLeft: "120px" }}
										onClick={
											!useCheckIsPrinterOnline.isLoading
												? checkIsPrinterOnline
												: () => {}
										}
									>
										{!useCheckIsPrinterOnline.isLoading && (
											<div
												title="Reconnect"
												className="flex check-network cursor-pointer"
											>
												<span>
													<Icon name="refresh" className="toolbarIcon" />
												</span>
												<span className="ml--xs">Reconnect</span>
											</div>
										)}
										{useCheckIsPrinterOnline.isLoading && (
											<span style={{ width: "100px" }}>Checking...</span>
										)}
									</div>
								)}
							</div>
							<div className="flex flex-center-both-axis">
								<h3
									style={{
										color: `${
											chosenPrinter?.status_code <
											PrinterStatuses.ERROR_OUT_OF_PAPER.code
												? "initial"
												: "red"
										}`,
									}}
								>
									Printer status:{" "}
									{usePrintTestLabel.isLoading
										? "Printing"
										: Object.values(PrinterStatuses).find(
												(printerStatus: any) => {
													return (
														printerStatus.code === chosenPrinter?.status_code
													);
												}
										  )?.name}
								</h3>
							</div>
							{printerCallSupportErrors && (
								<div className="flex flex-center-both-axis mb--base">
									<div style={{ color: "red" }}>
										Please contact Cacotec support at support@cacotec.com
									</div>
								</div>
							)}
							{printerOffline && (
								<div className="flex flex-center-both-axis mb--base">
									<div style={{ color: "red" }}>
										Please check printer internet connection and click Reconnect
									</div>
								</div>
							)}
							<div className="flex flex-center-both-axis">
								<div
									className={`button button--primary button--md mb--base ${
										printerHasError && "button--disabled"
									}`}
									onClick={printTestLabel}
								>
									Print test label
								</div>
							</div>
							{chosenPrinter?.status_code >= 27 && (
								<div className="flex flex-center-both-axis">
									<div
										className="button button--primary button--md mb--base"
										onClick={removeStackerFull}
									>
										{chosenPrinter?.status_code === 27
											? "Remove stacker full"
											: "Remove cutter cover open"}
									</div>
								</div>
							)}
						</div>
						<div className="pd--base">
							<span className="strong upcase text--sm">Printing type:</span>
							<span className="ml--base">
								{chosenPrinter?.settings?.double_sided
									? "Double sided"
									: "One sided"}
							</span>

							{/* Ribbon START *************************** */}
							<p
								className="mt--md"
								style={{ color: `${printerOutOfRibbon ? "red" : "inherit"}` }}
							>
								<span className="strong upcase text--sm">Ribbon</span>
								{printerOutOfRibbon && (
									<span className="ml--base">
										Please change ribbon and click Confirm
									</span>
								)}
							</p>
							<div className="flex">
								<div className="flex-4">
									<div className="flex pd--xs strong">
										<div className="flex-2">Name</div>
										<div className="flex-2">Width (mm)</div>
										<div className="flex-2">Color</div>
									</div>
									<div className="flex" style={{ padding: "0 5px" }}>
										<div className="flex-2">
											{chosenPrinterForEditing?.settings?.ribbon.name}
										</div>
										<div className="flex-2">
											{chosenPrinterForEditing?.settings?.ribbon.width}
										</div>
										<div className="flex-2">
											{capitalize(
												chosenPrinterForEditing?.settings?.ribbon?.color
											)}
										</div>
									</div>
								</div>
								<div className="flex-1 flex-center-secondary-axis">
									<div
										title="Confirm ribbon change"
										className="button button--primary button--sm"
										onClick={() => onConfirmMaterialChange("ribbon")}
									>
										Confirm
									</div>
								</div>
								<div
									className="flex flex-center-secondary-axis"
									style={{ justifyContent: "end" }}
								>
									<span
										onClick={() => {
											setShowPrinterEditModal({
												item: "ribbon",
												info: chosenPrinterForEditing.settings.ribbon,
											});
										}}
										className="cursor-pointer"
										title="Enter ribbon info"
									>
										<svg className="edit-printer-icon-blue">
											<use xlinkHref={`${sprite}#icon-edit`} />
										</svg>
									</span>
								</div>
							</div>
							{/* Ribbon END *************************** */}

							{/* Paper START *************************** */}
							<p
								className="mt--md"
								style={{ color: `${printerOutOfPaper ? "red" : "inherit"}` }}
							>
								<span className="strong upcase text--sm">Paper</span>
								{printerOutOfPaper && (
									<span className="ml--base">
										Please change paper and click Confirm
									</span>
								)}
							</p>
							<div className="flex">
								<div className="flex-4">
									<div className="flex pd--xs strong">
										<div className="flex-2">Material</div>
										<div className="flex-2">Width (mm)</div>
										<div className="flex-2">Color</div>
									</div>
									<div className="flex" style={{ padding: "0 5px" }}>
										<div className="flex-2">
											{capitalize(
												chosenPrinterForEditing?.settings?.paper?.material
											)}
										</div>
										<div className="flex-2">
											{chosenPrinterForEditing?.settings?.paper?.width}
										</div>
										<div className="flex-2">
											{capitalize(
												chosenPrinterForEditing?.settings?.paper?.color
											)}
										</div>
									</div>
								</div>
								<div className="flex-1 flex-center-secondary-axis">
									<div
										title="Confirm paper change"
										className="button button--primary button--sm"
										onClick={() => onConfirmMaterialChange("paper")}
									>
										Confirm
									</div>
								</div>
								<div
									className="flex flex-center-secondary-axis"
									style={{ justifyContent: "end" }}
								>
									<span
										onClick={() => {
											setShowPrinterEditModal({
												item: "paper",
												info: chosenPrinterForEditing.settings.paper,
											});
										}}
										className="cursor-pointer"
										title="Enter new paper info"
									>
										<svg className="edit-printer-icon-blue">
											<use xlinkHref={`${sprite}#icon-edit`} />
										</svg>
									</span>
								</div>
							</div>
							{/* Paper END *************************** */}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default PrinterInfo;
