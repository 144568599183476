import React, { useMemo } from "react";

import useDataApi from "Hooks/fetchHook";
import { IOrderState } from "Models/OrderModels";

import BasicInformation from "./Components/BasicInformation";
import AdditionalInformation from "./Components/AdditionalInformation";
import Loading from "Components/Shared/Loading";
import EmptyOrderForm from "./Components/EmptyOrderForm";
import UnitInformation from "./Components/UnitInformation";
import FabricAndCare from "./Components/FabricAndCare";
import MissingTranslationsModal from "./Components/MissingTranslationsModal";
import Languages from "./Components/Languages";
import { useFetchInitialValues } from "Hooks/queryHooks/useFetchInitialValues";
import { languagesCodeFullname } from "Models/GlobalModels";
import LabelSettings from "./Components/LabelSettings";

interface IProps {
	order: IOrderState;
	isLoadingSaveOrder: boolean;
	currentOrderItemIndex: number;

	saveOrder: (shouldShowSaveResultNotification: boolean) => void;
	setShowAddExistingStyleModal: (modalState: boolean) => void;
}

const OrderItem: React.FunctionComponent<IProps> = ({
	order,
	isLoadingSaveOrder,
	currentOrderItemIndex,

	saveOrder,
	setShowAddExistingStyleModal,
}) => {
	const useSaveOrderItem = useDataApi();

	const { orderItems } = order;

	const { initialValues } = useFetchInitialValues(order, order.brandId);

	let codeLanguageList =
		order.labelTemplate.settings?.default_values?.all_languages;

	let codeNameLanguageList = useMemo(() => {
		return codeLanguageList.map((code: string) => {
			return { code, name: languagesCodeFullname[code] };
		});
	}, [codeLanguageList]);

	return (
		<>
			{orderItems?.length === 0 && (
				<EmptyOrderForm
					setShowAddExistingStyleModal={setShowAddExistingStyleModal}
				/>
			)}

			{orderItems?.length > 0 && (
				<div id="orderItemForm">
					<Loading
						show={useSaveOrderItem.isLoading}
						text={"Loading..."}
						imgClass="contain center"
						divClass="col-sm-12"
					/>

					<BasicInformation
						madeInsList={initialValues.madeIns}
						currentOrderItemIndex={currentOrderItemIndex}
					/>
					<UnitInformation />
					<FabricAndCare
						fabricContentList={initialValues.fabricContent}
						careInstructionsList={initialValues.careInstructions}
						additionalComponentsList={initialValues.additionalComponents}
						componentsList={initialValues.components}
						fibersList={initialValues.fibers}
						currentOrderItemIndex={currentOrderItemIndex}
					/>

					{!order.labelTemplate?.settings?.form_settings
						?.hide_languages_dropdown && (
						<Languages allLanguagesList={codeNameLanguageList} />
					)}
					<AdditionalInformation />
					<LabelSettings />
					<Loading
						show={useSaveOrderItem.isLoading}
						text={"Loading..."}
						imgClass="contain center"
						divClass="col-sm-12"
					/>
				</div>
			)}

			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				{orderItems?.length > 0 && !isLoadingSaveOrder && (
					<button
						className="button button--primary"
						form="orderItemForm"
						data-testid="orderItem-saveOrder"
						onClick={() => saveOrder(true)}
					>
						Save
					</button>
				)}
				<Loading
					show={isLoadingSaveOrder}
					text={`Loading...`}
					imgClass="saveOrderLoading"
					divClass=""
				/>
			</div>

			<MissingTranslationsModal />
		</>
	);
};

export default OrderItem;
