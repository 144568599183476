import { OrderStatusesFlow } from "./../../Models/OrderModels";
import { useNavigate } from "react-router-dom-v5-compat";
import { GlobalDispatch } from "Containers/Home";
import useDataApi from "Hooks/fetchHook";
import { initialOrderItemState, IOrderState } from "Models/OrderModels";
import { isBrand, isLabelMaker } from "Models/UserModels";
import { useContext, useEffect } from "react";
import { deepCopy, transformOrderItemForUseOnFrontend } from "Utils/utils";

const useGetOrder = (
	orderIdFromParams: any,
	currentOrderItemIndex: number,
	setCurrentBrandId: any,
	setOrder: any,
	setLoadedOrder: any,
	setOrderItem: any
) => {
	const useGetOrder = useDataApi();
	const { user, setError } = useContext(GlobalDispatch);
	const { account_type_id } = user;

	const navigate = useNavigate();

	useEffect(() => {
		const parseOrderLog = (messageLog: any) => {
			let log = {
				createdBy: "",
				doneAt: "",
				lastChangeBy: "",
				lastChangeAt: "",
			};
			if (messageLog && messageLog[0] && messageLog[1]) {
				const { name: createdBy, done_at: doneAt } = messageLog[0];
				const { name: lastChangeBy, done_at: lastChangeAt } = messageLog[1];
				log = { createdBy, doneAt, lastChangeBy, lastChangeAt };
			}

			return log;
		};

		const { data } = useGetOrder;
		if (data && data.message) {
			const {
				order_items,
				label_template,
				brand_id,
				lot_number,
				status,
				log,
				factory_id,
				factory_name,
				note,
				extra_info,
				shipping_address,
				billing_address,
				preferred_shipping_method,
			} = data.message;

			if (status >= OrderStatusesFlow.PLACED.code) {
				navigate(`/order/preview/${orderIdFromParams}`, {
					replace: true,
				});
			}

			if (status === OrderStatusesFlow.REVIEW_REQUESTED.code) {
				navigate(`/order/approve/${orderIdFromParams}`, {
					replace: true,
				});
			}

			if (!label_template?.id || !brand_id) {
				setError({
					title: "Something Went Wrong",
					text: "Received incomplete data. Please try again later.",
					primary: {
						text: "Back to orders",
						action: () => navigate(`/orders`),
					},
					secondary: {
						text: "Refresh page",
						action: () =>
							navigate(`/order/${orderIdFromParams}`, { replace: true }),
					},
				});
			} else {
				if (!isBrand(account_type_id)) {
					setCurrentBrandId(brand_id);
				}

				const orderItemsForState = order_items.map((orderItem: any) =>
					transformOrderItemForUseOnFrontend(orderItem)
				);

				const order: IOrderState = {
					orderId: orderIdFromParams,
					labelTemplate: label_template,
					lotNumber: lot_number,
					log: parseOrderLog(log),
					orderItems: orderItemsForState,
					brandId: brand_id,
					factoryId: factory_id,
					factoryName: factory_name,
					status: status,
					shippingAddress: shipping_address,
					billingAddress: billing_address,
					preferredShippingMethod: preferred_shipping_method,
					note,
				};
				if (extra_info && Object.keys(extra_info).length > 0) {
					order.extraInfo = extra_info;
				}
				setOrder(order);
				setLoadedOrder(order);

				let initialOrderItem;

				if (orderItemsForState.length > 0) {
					initialOrderItem = orderItemsForState[currentOrderItemIndex];
				} else {
					initialOrderItem = deepCopy({
						...initialOrderItemState,
						units: [
							{
								...initialOrderItemState.units[0],
								quantity:
									label_template?.settings?.form_settings?.quantity?.minimum ||
									0,
							},
						],
					});
				}
				setOrderItem(initialOrderItem);
			}
		}
	}, [useGetOrder.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useGetOrder;
		if (error) {
			setError({
				title: "Order Not Found",
				text: "",
				primary: {
					text: "Back to orders",
					action: () => navigate(`/orders`),
				},
				secondary: {
					text: !isLabelMaker(account_type_id) ? "Create a New Order" : "",
					action: () => navigate(`/order/`),
				},
			});
		}
	}, [useGetOrder.error]); // eslint-disable-line react-hooks/exhaustive-deps

	let getOrderDoFetch = useGetOrder.doFetch;
	let getOrderIsLoading = useGetOrder.isLoading;

	return { getOrderDoFetch, getOrderIsLoading };
};

export default useGetOrder;
