import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";

import Icon from "Components/Shared/Icon";
import DropDownSelection from "../SharedComponents/DropDownSelection";

import { toast } from "react-toastify";
import { LabelTemplateDispatch } from "Containers/LabelTemplate";
import { ITemplateState } from "Models/TemplateModels";

interface IProps {
	currentSelectedItem: any;
	clickedOutsideEditSectionTooltip: boolean;

	setIsSectionSettingsOpen: Dispatch<SetStateAction<any>>;
	setClickedOutsideEditSectionTooltip: Dispatch<SetStateAction<boolean>>;
	setImagesHoveredSectionId: Dispatch<SetStateAction<number | undefined>>;
	removeSectionById: (id: number, getImagesFlag: boolean) => void;
}

const DynamicSections: React.FunctionComponent<IProps> = ({
	currentSelectedItem,
	clickedOutsideEditSectionTooltip,

	setIsSectionSettingsOpen,
	setClickedOutsideEditSectionTooltip,
	setImagesHoveredSectionId,
	removeSectionById,
}) => {
	const { setTemplate, getImages, template, imagesSectionsData } = useContext(
		LabelTemplateDispatch
	);

	const { sections } = template;

	let { sections: imageSections } = imagesSectionsData;

	const [uploadedFileNames, setUploadedFileNames] = useState<{
		[key: string]: string;
	}>({});

	const onCancelSectionEdit = () => {
		// check if it is first adding of section
		// if it is, remove section on cancel/click outside
		let sectionAdded = imageSections.flat().find((section: any) => {
			return section.id === currentSelectedItem.id;
		});

		if (!sectionAdded) {
			removeSectionById(currentSelectedItem.id, false);
		} else {
			let newSections = [...template.sections];
			let sectionToRevertIndex = newSections.findIndex((section: any) => {
				return section.id === currentSelectedItem.id;
			});
			newSections[sectionToRevertIndex] = {
				...currentSelectedItem,
			};

			setTemplate((prevTemplate: ITemplateState) => {
				let newTemplate = {
					...prevTemplate,
					sections: newSections,
				};

				return newTemplate;
			});
		}
		setClickedOutsideEditSectionTooltip(false);
		setIsSectionSettingsOpen(undefined);
	};

	useEffect(() => {
		if (clickedOutsideEditSectionTooltip) {
			onCancelSectionEdit();
		}
	}, [clickedOutsideEditSectionTooltip]); // eslint-disable-line react-hooks/exhaustive-deps

	const setTemplateSections = (newSections: any[]) => {
		setTemplate({ ...template, sections: [...newSections] });
	};

	const loadImage = (e: any, item: any) => {
		let files;

		if (e.type === "drop" && e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target && e.target.files) {
			files = e.target.files;
		} else {
			console.log("No files found or event type not supported");
			return;
		}

		if (files.length === 0) {
			return;
		}

		const file = files[0];
		const reader = new FileReader();

		// Check file size
		if (file.size > 50000) {
			alert("File size should not exceed 50KB");
			return;
		}

		reader.onload = (e) => {
			const arrayBuffer: any = e.target?.result;
			const uint8Array = new Uint8Array(arrayBuffer);
			const regularArray = Array.from(uint8Array);

			// If you need a base64-encoded string
			const base64String = btoa(String.fromCharCode.apply(null, regularArray));

			// If you need to work with Uint8Array directly
			console.log(base64String);

			let tmp = [...sections];
			let index = tmp.findIndex((i) => i.id === item.id);
			tmp[index].file = base64String;
			setTemplateSections(tmp);
			setUploadedFileNames((prevState) => ({
				...prevState,
				[item.id]: file.name,
			}));
			toast.success("File uploaded successfully!");
		};

		reader.onerror = (e) => {
			toast.error("An error occurred while uploading the file");
		};

		reader.readAsArrayBuffer(file);
	};

	const minFontSize = 4.5;

	const missingData = (id: number) => {
		let itemToCheck = template.sections.find((item: any) => {
			return item.id === id;
		});

		if (itemToCheck.type === "MADE IN") {
			if (itemToCheck.fontSize < minFontSize) return true;
		}

		if (itemToCheck.type === "CONTENT COMPOSITION") {
			if (itemToCheck.fontSize < minFontSize) return true;
		}

		if (itemToCheck.type === "CARE INSTRUCTIONS") {
			if (itemToCheck.fontSize < minFontSize || itemToCheck.symbolSize < 2.8)
				return true;
		}

		if (itemToCheck.type === "SEPARATOR") {
		}

		if (itemToCheck.type === "IMAGE") {
			if (itemToCheck.separator < 0 || !itemToCheck.file) return true;
		}

		if (itemToCheck.type === "QR") {
			if (
				(itemToCheck.qrPhase === 0 && itemToCheck.link === "") ||
				itemToCheck.separator < 0
			)
				return true;
		}

		if (itemToCheck.type === "FIXED INFO") {
			if (
				itemToCheck.text === "" ||
				itemToCheck.fontSize < minFontSize ||
				itemToCheck.separator < 0
			)
				return true;
		}

		if (itemToCheck.type === "STYLE NUMBER") {
			if (
				itemToCheck.text === "" ||
				itemToCheck.fontSize < minFontSize ||
				itemToCheck.separator < 0
			)
				return true;
		}

		if (itemToCheck.type === "LINE") {
			if (itemToCheck.separator < 0) return true;
		}

		return false;
	};

	return (
		<>
			{template.sections &&
				template.sections.map((item: any) => {
					if (item.id === currentSelectedItem?.id) {
						let tmp = [...template.sections];
						let index = tmp.findIndex((i) => i.id === item.id);

						return (
							<div key={item.id} className="dynamic-section">
								<div
									className="pretitle flex-center-secondary-axis mt--sm mb--sm"
									style={{ fontWeight: 900 }}
								>
									<span>{item.type}</span>
									{/* <HelpTooltip helpFor={item.type + " SECTION"} /> */}
								</div>
								<fieldset className="dynamic-section-inner">
									{[
										"MADE IN",
										"CONTENT COMPOSITION",
										"CARE INSTRUCTIONS",
									].includes(item.type) && (
										<>
											<DropDownSelection
												name={"Capitalization" + item.id}
												options={[
													"ALL CAPITALIZED",
													"FIRST LETTER CAPITALIZED",
													"EVERY FIRST LETTER CAPITALIZED",
												]}
												state={template.sections}
												setState={(newSelectedItems: any) => {
													setTemplate({
														...template,
														sections: newSelectedItems,
													});
												}}
												item={item}
											/>

											<div className="flex">
												<div className="flex-1">
													<div className="alignment-title">Alignment</div>
													<div className="flex">
														<div
															className={`alignment-icons-wrapper mr--sm ${
																tmp[index].align === "left" && "selected"
															}`}
															onClick={(e) => {
																tmp[index].align = "left";
																setTemplateSections(tmp);
															}}
														>
															<Icon
																name="left-alignment"
																className="alignment-icons"
															/>
														</div>
														<div
															className={`alignment-icons-wrapper mr--sm ${
																tmp[index].align === "center" && "selected"
															}`}
															onClick={(e) => {
																tmp[index].align = "center";
																setTemplateSections(tmp);
															}}
														>
															<Icon
																name="center-alignment"
																className="alignment-icons"
															/>
														</div>
														<div
															className={`alignment-icons-wrapper ${
																tmp[index].align === "right" && "selected"
															}`}
															onClick={(e) => {
																tmp[index].align = "right";
																setTemplateSections(tmp);
															}}
														>
															<Icon
																name="right-alignment"
																className="alignment-icons"
															/>
														</div>
													</div>
												</div>

												<div className="form-group  flex-1">
													<input
														type="number"
														className="form-control"
														name={"fontSize" + item.id}
														id={"fontSize" + item.id}
														value={item.fontSize <= 0 ? "" : item.fontSize}
														data-empty={item.fontSize <= 0}
														onChange={(e) => {
															tmp[index].fontSize = Number(e.target.value);
															setTemplateSections(tmp);
														}}
														min={minFontSize}
														step={0.5}
													/>
													<label
														className="form-control-label"
														htmlFor={"fontSize" + item.id}
													>
														Font size(pt)
													</label>
												</div>
											</div>
										</>
									)}

									{item.type === "CONTENT COMPOSITION" && (
										<div className="form-group">
											<div className="form-check">
												<input
													className="form-check__input"
													type="checkbox"
													name={"spaceBetweenLanguages" + item.id}
													id={"spaceBetweenLanguages" + item.id}
													checked={item.addNewLine}
													onChange={(e) => {
														tmp[index].addNewLine = !tmp[index].addNewLine;
														setTemplateSections(tmp);
													}}
												/>
												<label
													className="form-check__label"
													htmlFor={"spaceBetweenLanguages" + item.id}
												>
													Add new line between translations
												</label>
											</div>
										</div>
									)}

									{["CONTENT COMPOSITION", "CARE INSTRUCTIONS"].includes(
										item.type
									) && (
										<DropDownSelection
											name={"Split character" + item.id}
											options={[
												"NEW LINE",
												"/",
												"COMMA + SPACE",
												"DOT + SPACE",
											]}
											state={template.sections}
											setState={(newSelectedItems: any) => {
												setTemplate({
													...template,
													sections: newSelectedItems,
												});
											}}
											item={item}
										/>
									)}

									{item.type === "CARE INSTRUCTIONS" && (
										<>
											<div className="form-group">
												<label className="simple-label">Symbol set</label>
												<div className="flex">
													<div className="form-check mr--md">
														<input
															className="form-check__input"
															type="radio"
															name={"symbols" + item.id}
															id={"usaSymbols" + item.id}
															data-empty={false}
															checked={tmp[index].symbolSet === "usa"}
															onChange={(e) => {
																tmp[index].symbolSet = "usa";
																setTemplateSections(tmp);
															}}
														/>
														<label
															className="form-check__label"
															htmlFor={"usaSymbols" + item.id}
														>
															USA
														</label>
													</div>
													<div className="form-check">
														<input
															className="form-check__input"
															type="radio"
															name={"symbols" + item.id}
															id={"chinaSymbols" + item.id}
															checked={tmp[index].symbolSet === "china"}
															onChange={(e) => {
																tmp[index].symbolSet = "china";
																setTemplateSections(tmp);
															}}
														/>
														<label
															className="form-check__label"
															htmlFor={"chinaSymbols" + item.id}
														>
															China
														</label>
													</div>
												</div>
											</div>
											<div className="form-group">
												<label className="simple-label">
													Symbols positioning
												</label>
												<div className="flex">
													<div className="form-check mr--md">
														<input
															className="form-check__input"
															type="radio"
															name={"symbolsPos" + item.id}
															id={"beforeText" + item.id}
															data-empty={false}
															checked={tmp[index].symbolBeforeText}
															onChange={(e) => {
																tmp[index].symbolBeforeText = true;
																setTemplateSections(tmp);
															}}
														/>
														<label
															className="form-check__label"
															htmlFor={"beforeText" + item.id}
														>
															Before text
														</label>
													</div>
													<div className="form-check">
														<input
															className="form-check__input"
															type="radio"
															name={"symbolsPos" + item.id}
															id={"afterText" + item.id}
															checked={!tmp[index].symbolBeforeText}
															onChange={(e) => {
																tmp[index].symbolBeforeText = false;
																setTemplateSections(tmp);
															}}
														/>
														<label
															className="form-check__label"
															htmlFor={"afterText" + item.id}
														>
															After text
														</label>
													</div>
												</div>
											</div>
											<div className="form-group">
												<input
													type="number"
													className="form-control"
													name="symbolSize"
													id="symbolSize"
													value={item.symbolSize === 0 ? "" : item.symbolSize}
													data-empty={item.symbolSize === 0}
													onChange={(e) => {
														tmp[index].symbolSize = Number(e.target.value);
														setTemplateSections(tmp);
													}}
													min={2.8}
													step={0.1}
												/>
												<label
													className="form-control-label"
													htmlFor="symbolSize"
												>
													Symbol size(mm)
												</label>
											</div>
										</>
									)}

									{item.type === "LINE" && (
										<div className="form-group">
											<div className="form-check">
												<input
													className="form-check__input"
													type="radio"
													name={"radio" + item.id}
													id={"dashed" + item.id}
													onChange={(e) => {
														tmp[index].dashed = true;
														setTemplateSections(tmp);
													}}
													checked={item.dashed}
												/>
												<label
													className="form-check__label"
													htmlFor={"dashed" + item.id}
												>
													Dashed
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check__input"
													type="radio"
													name={"radio" + item.id}
													id={"straight" + item.id}
													onChange={(e) => {
														tmp[index].dashed = false;
														setTemplateSections(tmp);
													}}
													checked={!item.dashed}
												/>
												<label
													className="form-check__label"
													htmlFor={"straight" + item.id}
												>
													Straight
												</label>
											</div>
										</div>
									)}

									{item.type === "IMAGE" && (
										<div className="form-group">
											<div
												className="form-control-file__label"
												onDragOver={(event) => event.preventDefault()}
												onDrop={(e) => {
													e.stopPropagation();
													e.preventDefault();
													loadImage(e, item); // Pass the original event
												}}
											>
												<div>Drop file here</div>
												<div className="mb--sm">or</div>
												<label className="form-control-styles-file__label">
													Select File
													<input
														className="form-control-styles-file"
														type="file"
														onChange={(e) => loadImage(e, item)} // Pass the original event
														accept=".jpg, .jpeg, .png, .svg"
													/>
												</label>
											</div>
											{uploadedFileNames[item.id] && (
												<div className="uploaded-file-name">
													Uploaded: {uploadedFileNames[item.id]}
												</div>
											)}

											<div className="form-group flex-center-secondary-axis mt--base">
												<input
													type="range"
													min={50}
													max={100}
													className="ml--sm form-control"
													name={"imageWidth" + item.id}
													id={"imageWidth" + item.id}
													value={item.width}
													data-empty={false}
													onChange={(e) => {
														tmp[index].width = parseInt(e.target.value);
														setTemplateSections(tmp);
													}}
												/>
												<label
													className="form-control-label"
													htmlFor={"imageWidth" + item.id}
												>
													Image width (%)
												</label>
												<span className="ml--base mr--xs">{item.width}</span>
											</div>
										</div>
									)}

									{item.type === "QR" && (
										<>
											<p>
												QR code will be shown on your label at this position
											</p>
											<div className="form-group">
												<div className="form-group">
													<label className="simple-label">
														QR code destination
													</label>
													<div className="form-check">
														<input
															className="form-check__input"
															type="radio"
															name={"qrLink" + item.id}
															id={"qrLink" + item.id}
															data-empty={false}
															checked={item.qrPhase === 0}
															onChange={(e) => {
																tmp[index].qrPhase = 0;
																setTemplateSections(tmp);
															}}
														/>
														<label
															className="form-check__label"
															htmlFor={"qrLink" + item.id}
														>
															Fixed link (URL)
														</label>
													</div>
													<div className="form-check">
														<input
															className="form-check__input"
															type="radio"
															name={"qrDPP" + item.id}
															id={"qrDPP" + item.id}
															checked={item.qrPhase === 1}
															onChange={(e) => {
																tmp[index].qrPhase = 1;
																setTemplateSections(tmp);
															}}
														/>
														<label
															className="form-check__label"
															htmlFor={"qrDPP" + item.id}
														>
															DPP
														</label>
													</div>
												</div>
											</div>
											{item.qrPhase === 0 && (
												<div className="form-group">
													<input
														type="text"
														className="form-control"
														name="qrFixedLink"
														id={"qrFixedLink" + item.id}
														data-empty={item.link === ""}
														value={tmp[index].link}
														onChange={(e) => {
															tmp[index].link = e.target.value;
															setTemplateSections(tmp);
														}}
													/>
													<label
														className="form-control-label"
														htmlFor={"qrFixedLink" + item.id}
													>
														QR code link
													</label>
												</div>
											)}
											<div className="form-group flex-center-secondary-axis">
												<input
													type="range"
													min={30}
													max={70}
													className="ml--sm form-control"
													name={"qrWidth" + item.id}
													id={"qrWidth" + item.id}
													value={item.width}
													data-empty={false}
													onChange={(e) => {
														tmp[index].width = parseInt(e.target.value);
														setTemplateSections(tmp);
													}}
												/>
												<label
													className="form-control-label"
													htmlFor={"qrWidth" + item.id}
												>
													QR code width (%)
												</label>
												<span className="ml--base mr--xs">{item.width}</span>
											</div>
										</>
									)}

									{item.type === "FIXED INFO" && (
										<>
											<div className="form-group">
												<textarea
													autoFocus={true}
													className="form-control"
													name="orderNote"
													id="orderNote"
													rows={4}
													value={item.text}
													data-empty={item.text === ""}
													maxLength={500}
													onChange={(e) => {
														tmp[index].text = e.target.value;
														setTemplateSections(tmp);
													}}
												/>
											</div>
											<div className="flex">
												<div className="flex-1">
													<div className="alignment-title">Alignment</div>
													<div className="flex">
														<div
															className={`alignment-icons-wrapper mr--sm ${
																tmp[index].align === "left" && "selected"
															}`}
															onClick={(e) => {
																tmp[index].align = "left";
																setTemplateSections(tmp);
															}}
														>
															<Icon
																name="left-alignment"
																className="alignment-icons"
															/>
														</div>
														<div
															className={`alignment-icons-wrapper mr--sm ${
																tmp[index].align === "center" && "selected"
															}`}
															onClick={(e) => {
																tmp[index].align = "center";
																setTemplateSections(tmp);
															}}
														>
															<Icon
																name="center-alignment"
																className="alignment-icons"
															/>
														</div>
														<div
															className={`alignment-icons-wrapper ${
																tmp[index].align === "right" && "selected"
															}`}
															onClick={(e) => {
																tmp[index].align = "right";
																setTemplateSections(tmp);
															}}
														>
															<Icon
																name="right-alignment"
																className="alignment-icons"
															/>
														</div>
													</div>
												</div>

												<div className="form-group  flex-1">
													<input
														type="number"
														className="form-control"
														name={"fontSize" + item.id}
														id={"fontSize" + item.id}
														value={item.fontSize <= 0 ? "" : item.fontSize}
														data-empty={item.fontSize <= 0}
														onChange={(e) => {
															tmp[index].fontSize = Number(e.target.value);
															setTemplateSections(tmp);
														}}
														min={minFontSize}
														step={0.5}
													/>
													<label
														className="form-control-label"
														htmlFor={"fontSize" + item.id}
													>
														Font size(pt)
													</label>
												</div>
											</div>
										</>
									)}

									{item.type === "SEPARATOR" && (
										<div className="form-group">
											<input
												type="number"
												className="form-control"
												name="separator"
												id={"separator" + item.id}
												data-empty={item.height < 0}
												value={item.height < 0 ? "" : item.height}
												onChange={(e: any) => {
													let tmp = [...sections];
													let index = tmp.findIndex((i) => i.id === item.id);
													tmp[index].height = Number(
														(e.target as HTMLInputElement).value
													);
													setTemplateSections(tmp);
												}}
												min={0}
												step={0.1}
											/>
											<label
												className="form-control-label"
												htmlFor={"separator" + item.id}
											>
												Height(mm)
											</label>
										</div>
									)}

									{item.type === "STYLE NUMBER" && (
										<>
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													name="styleNumberText"
													id="styleNumberText"
													value={item.text}
													data-empty={item.text === ""}
													onChange={(e) => {
														tmp[index].text = e.target.value;
														setTemplateSections(tmp);
													}}
												/>
												<label
													className="form-control-label"
													htmlFor="styleNumberText"
												>
													Text before style number
												</label>
											</div>
											<div className="flex">
												<div className="flex-1">
													<div className="alignment-title">Alignment</div>
													<div className="flex">
														<div
															className={`alignment-icons-wrapper mr--sm ${
																tmp[index].align === "left" && "selected"
															}`}
															onClick={(e) => {
																tmp[index].align = "left";
																setTemplateSections(tmp);
															}}
														>
															<Icon
																name="left-alignment"
																className="alignment-icons"
															/>
														</div>
														<div
															className={`alignment-icons-wrapper mr--sm ${
																tmp[index].align === "center" && "selected"
															}`}
															onClick={(e) => {
																tmp[index].align = "center";
																setTemplateSections(tmp);
															}}
														>
															<Icon
																name="center-alignment"
																className="alignment-icons"
															/>
														</div>
														<div
															className={`alignment-icons-wrapper ${
																tmp[index].align === "right" && "selected"
															}`}
															onClick={(e) => {
																tmp[index].align = "right";
																setTemplateSections(tmp);
															}}
														>
															<Icon
																name="right-alignment"
																className="alignment-icons"
															/>
														</div>
													</div>
												</div>

												<div className="form-group  flex-1">
													<input
														type="number"
														className="form-control"
														name={"fontSize" + item.id}
														id={"fontSize" + item.id}
														value={item.fontSize <= 0 ? "" : item.fontSize}
														data-empty={item.fontSize <= 0}
														onChange={(e) => {
															tmp[index].fontSize = Number(e.target.value);
															setTemplateSections(tmp);
														}}
														min={minFontSize}
														step={0.5}
													/>
													<label
														className="form-control-label"
														htmlFor={"fontSize" + item.id}
													>
														Font size(pt)
													</label>
												</div>
											</div>
										</>
									)}

									{item.type !== "SEPARATOR" && item.type !== "NEW PAGE" && (
										<div className="form-group">
											<input
												type="number"
												className="form-control"
												name={"separator" + item.id}
												id={"separator" + item.id}
												value={item.separator}
												data-empty={false}
												onChange={(e) => {
													tmp[index].separator = Number(e.target.value);
													setTemplateSections(tmp);
												}}
												min={0}
												step={0.1}
											/>
											<label
												className="form-control-label"
												htmlFor="symbolSize"
											>
												Separator(mm)
											</label>
										</div>
									)}
									{item.type === "NEW PAGE" && (
										<p>All sections after this will be drawn on the new page</p>
									)}
								</fieldset>

								{/* Buttons */}
								<div className="flex justify-around dynamic-section-buttons">
									<div
										className="button btn-link btn-no-style button--xs"
										onClick={onCancelSectionEdit}
									>
										Cancel
									</div>
									<div
										className={`button button--primary button--xs ${
											missingData(item.id) && "button--disabled"
										}`}
										onClick={() => {
											getImages(template);
											setImagesHoveredSectionId(undefined);
											setIsSectionSettingsOpen(undefined);
										}}
									>
										Apply
									</div>
								</div>
							</div>
						);
					}
					return null;
				})}
		</>
	);
};

export default DynamicSections;
