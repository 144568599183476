import React, { useState, useEffect, useCallback, useRef } from "react";

import useIncrementalSearchHook from "Hooks/incrementalSearchHook";
import useOnClickOutside from "Hooks/outsideClickHook";
import { IDefaultOption } from "Models/OrderModels";

import SelectedItemsList from "Components/Order/OrderSharedComponents/SelectedItemsList";
import IncrementalSearchResults from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchResults";
import IncrementalSearchInput from "Components/Order/OrderSharedComponents/IncrementalSearch/IncrementalSearchInput";
import Icon from "Components/Shared/Icon";

interface IProps {
	list: IDefaultOption[];
	buttonName: string;
	selectedItemsValues: any;
	fieldName: string;

	onChooseItemCallbackCustom: (item?: any) => void;
	onChangeOrderOfSelectedItems: (ids?: any) => void;
	onRemoveItem: (itemId: number) => void;
}

const IncrementalSearchComponent: React.FunctionComponent<IProps> = ({
	list,
	buttonName,
	selectedItemsValues,
	fieldName,

	onChooseItemCallbackCustom,
	onChangeOrderOfSelectedItems,
	onRemoveItem,
}) => {
	const [focusOnSearchList, setFocusOnSearchList] = useState(false);
	const [showComponent, setShowComponent] = useState(false);

	const incrementalSearchHookProps = useIncrementalSearchHook(list);

	const { setInputText } = incrementalSearchHookProps;

	const dropdownRef = useRef(null);
	useOnClickOutside(
		dropdownRef,
		useCallback(() => setShowComponent(false), [setShowComponent])
	);

	const onChooseItemCallback = (item: any) => {
		onChooseItemCallbackCustom(item);
		if (item) {
			setShowComponent(false);
		}
	};

	useEffect(() => {
		setInputText("");
	}, [selectedItemsValues, setInputText]);

	return (
		<div style={{ marginTop: "30px" }}>
			<button
				className="button button--outline-dark button--sm"
				id="blueBorderButton"
				onClick={() => {
					setShowComponent(true);
				}}
				data-testid={`add-${fieldName}`}
			>
				Add {buttonName}
			</button>

			{showComponent && (
				<div className="form-control" id="dropdownList" ref={dropdownRef}>
					<div className="form-group-search mt--md" style={{ margin: "10px" }}>
						<IncrementalSearchInput
							labelText="Start typing to add care instructions"
							name={fieldName}
							type="search"
							setFocusOnSearchList={setFocusOnSearchList}
							onChangeInputText={() => {
								setFocusOnSearchList(false);
							}}
							onInputBlur={onChooseItemCallback}
							incrementalSearchHookProps={incrementalSearchHookProps}
						/>
					</div>

					<IncrementalSearchResults
						focusOnSearchList={focusOnSearchList}
						testId={fieldName}
						onChooseItemCallback={onChooseItemCallback}
						incrementalSearchHookProps={incrementalSearchHookProps}
					/>
				</div>
			)}

			{selectedItemsValues.length === 0 && (
				<div className="infoTextContainer">
					<div className="blueIcon">
						<Icon name="info-rounded" />
					</div>
					<button
						className="text--quiet btn-no-style"
						data-testid={`${fieldName}-emptyMessage`}
						onClick={() => setShowComponent(true)}
					>
						Once you select {buttonName} they will appear here
					</button>
				</div>
			)}

			<SelectedItemsList
				changeOrderOfItems={onChangeOrderOfSelectedItems}
				selectedItemsIds={selectedItemsValues}
				allItems={list}
				itemId={"id"}
				removeItem={onRemoveItem}
				testId={fieldName}
			/>
		</div>
	);
};

export default IncrementalSearchComponent;
