import React, { useState, useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom-v5-compat";

import Icon from "Components/Shared/Icon";
import Logo from "images/logo.svg";
import { GlobalDispatch } from "Containers/Home";
import {
	isBrand,
	isFactory,
	isLabelMaker,
	UserAccountType,
} from "Models/UserModels";
import {
	brandDppAppLabelTemplates,
	brandPrintLabelTemplates,
} from "Utils/utils";
import { useFetchLabelTemplates } from "Hooks/queryHooks/useFetchLabelTemplates";

interface IStateProps {
	onLogout: () => void;
}

const Navigation: React.FunctionComponent<IStateProps> = (props) => {
	const {
		user,
		setResetOrdersFilters,
		setResetStylesFilters,
		setResetTemplatesFilters,
		setResetPrintBatchesFilters,
		setError,
	} = useContext(GlobalDispatch);

	const { onLogout } = props;
	const { name, org_logo, account_type_id } = user;

	const { labelTemplates } = useFetchLabelTemplates(account_type_id, setError);

	const [showDrawer, setShowDrawer] = useState(false);

	const location = useLocation();

	const toggleDrawer = () => {
		setShowDrawer((showDrawer: boolean) => !showDrawer);
	};

	const hideDrawer = () => {
		setShowDrawer(false);
	};

	const resetOrdersFilters = () => {
		setShowDrawer(false);
		setResetOrdersFilters(true);
	};

	const resetStylesFilters = () => {
		setShowDrawer(false);
		setResetStylesFilters(true);
	};

	const resetTemplatesFilters = () => {
		setShowDrawer(false);
		setResetTemplatesFilters(true);
	};

	const resetPrintBatchesFilters = () => {
		setShowDrawer(false);
		setResetPrintBatchesFilters(true);
	};

	const version = process.env.REACT_APP_VERSION
		? `v.${process.env.REACT_APP_VERSION}`
		: "";

	return (
		<React.Fragment>
			<nav className={`navigation ${showDrawer ? "show" : ""}`} id="navigation">
				<div className="navigation__content">
					<div>
						<div className="navigation__block">
							<Link
								data-testid="navigation-logout"
								className="link--quiet right"
								onClick={onLogout}
								to="/login"
							>
								<Icon name="off" />
							</Link>
							<span data-testid="navigation-userName">{name}</span>
							<br />
							{account_type_id === UserAccountType.BRAND_ADMIN && (
								<span
									data-testid="navigation-accountType"
									className="text--quiet upcase regular"
								>
									admin
								</span>
							)}
							{account_type_id === UserAccountType.BRAND_MEMBER && (
								<span
									data-testid="navigation-accountType"
									className="text--quiet upcase regular"
								>
									member
								</span>
							)}
							{isLabelMaker(account_type_id) && (
								<span
									data-testid="navigation-accountType"
									className="text--quiet upcase regular"
								>
									LabelKings
								</span>
							)}
							{isFactory(account_type_id) && (
								<span
									data-testid="navigation-accountType"
									className="text--quiet upcase regular"
								>
									Factory
								</span>
							)}
						</div>

						{!isLabelMaker(account_type_id) && (
							<Link
								className="button button--primary button--block button--square txtc"
								to="/order/"
								onClick={toggleDrawer}
								data-testid="navigation-newOrder"
							>
								<Icon
									name="plus-rounded"
									className="icon valign-middle mr--xs"
								/>
								New order
							</Link>
						)}

						<ul className="navigation__menu">
							<li>
								<NavLink
									data-testid="navigation-ordersLink"
									className={({ isActive }) =>
										"navigation__menu__item" + (isActive ? " active" : "")
									}
									to="/orders"
									onClick={resetOrdersFilters}
								>
									<Icon name="grid" />
									Orders
								</NavLink>
							</li>

							{!isLabelMaker(account_type_id) && (
								<li>
									<NavLink
										data-testid="navigation-stylesLink"
										className={({ isActive }) =>
											"navigation__menu__item" + (isActive ? " active" : "")
										}
										to="/styles"
										onClick={resetStylesFilters}
									>
										<Icon name="order-item" />
										Styles
									</NavLink>
								</li>
							)}
							<li>
								<NavLink
									data-testid="navigation-settingsLink"
									className={({ isActive }) =>
										"navigation__menu__item" + (isActive ? " active" : "")
									}
									to="/settings"
									onClick={hideDrawer}
								>
									<Icon name="cog" />
									Settings
								</NavLink>
							</li>

							{((isBrand(account_type_id) &&
								brandPrintLabelTemplates(labelTemplates).length > 0) ||
								isFactory(account_type_id)) && (
								<li>
									<NavLink
										data-testid="navigation-helpLink"
										className={({ isActive }) =>
											"navigation__menu__item" + (isActive ? " active" : "")
										}
										to="/printers"
										onClick={hideDrawer}
									>
										<Icon name="printer" />
										Printers
									</NavLink>
								</li>
							)}
							{((isBrand(account_type_id) &&
								brandPrintLabelTemplates(labelTemplates).length > 0) ||
								isFactory(account_type_id)) && (
								<li>
									<NavLink
										data-testid="navigation-helpLink"
										className={({ isActive }) =>
											"navigation__menu__item" + (isActive ? " active" : "")
										}
										to="/printBatches"
										onClick={resetPrintBatchesFilters}
									>
										<Icon name="printed-labels" />
										Print batches
									</NavLink>
								</li>
							)}

							{isBrand(account_type_id) &&
								brandDppAppLabelTemplates(labelTemplates).length > 0 && (
									<li>
										<NavLink
											data-testid="navigation-settingsLink"
											className={({ isActive }) =>
												"navigation__menu__item" + (isActive ? " active" : "")
											}
											to="/dpp-statistics"
											onClick={hideDrawer}
										>
											<Icon name="statistic" />
											DPP statistics
										</NavLink>
									</li>
								)}
							{isBrand(account_type_id) && user.brand_id === 6 && (
								<li>
									<NavLink
										data-testid="navigation-settingsLink"
										className={({ isActive }) =>
											"navigation__menu__item" + (isActive ? " active" : "")
										}
										to="/templates"
										onClick={resetTemplatesFilters}
									>
										<Icon name="label-templates-list" />
										Templates
									</NavLink>
								</li>
							)}
							<li>
								<NavLink
									data-testid="navigation-helpLink"
									className={({ isActive }) =>
										"navigation__menu__item" +
										(isActive || location.pathname.startsWith("/help")
											? " active active-help"
											: "")
									}
									to="/help/videos"
									style={{
										pointerEvents: `${
											location.pathname.startsWith("/help") ? "none" : "initial"
										}`,
									}}
									onClick={hideDrawer}
								>
									<Icon name="info-rounded" />
									Help
								</NavLink>

								{location.pathname.startsWith("/help") && (
									<>
										<NavLink
											data-testid="navigation-helpLink"
											className={({ isActive }) =>
												"navigation__menu__item normal-case" +
												(isActive ? " active" : "")
											}
											to="/help/videos"
											style={{ marginLeft: "30px" }}
											onClick={hideDrawer}
										>
											Videos
										</NavLink>
										<NavLink
											data-testid="navigation-helpLink"
											className={({ isActive }) =>
												"navigation__menu__item normal-case" +
												(isActive ? " active" : "")
											}
											to="/help/articles"
											style={{ marginLeft: "30px" }}
											onClick={hideDrawer}
										>
											Articles
										</NavLink>
										<NavLink
											data-testid="navigation-helpLink"
											className={({ isActive }) =>
												"navigation__menu__item  normal-case" +
												(isActive ? " active" : "")
											}
											to="/help/what-is-new"
											style={{ marginLeft: "30px" }}
											onClick={hideDrawer}
										>
											What is new
										</NavLink>
									</>
								)}
							</li>

							{isLabelMaker(account_type_id) && (
								<li>
									<NavLink
										data-testid="navigation-adminLink"
										className={({ isActive }) =>
											"navigation__menu__item" + (isActive ? " active" : "")
										}
										to="/admin"
									>
										<Icon name="admin-settings" />
										Admin
									</NavLink>
								</li>
							)}
						</ul>
					</div>

					<div className="navigation__block navigation__footer">
						<figure className="mb--base">
							<img className="contain center" src={org_logo} alt="" />
						</figure>
						<figure>
							<img src={Logo} alt="" width="100" />
						</figure>
						<br />
						<span className="text--quiet regular">{version}</span>
					</div>
				</div>
			</nav>
			<a
				className="navigation-toggle"
				href="#navigation"
				data-toggle="drawer"
				onClick={toggleDrawer}
			>
				<span>Menu</span>
			</a>
		</React.Fragment>
	);
};

export default Navigation;
