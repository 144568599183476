import React from "react";

import { IOrderState } from "Models/OrderModels";
import { fullTimeFormat } from "Utils/utils";

interface IProps {
	order: IOrderState;
}

const OrderLog: React.FunctionComponent<IProps> = ({ order }) => {
	const { log, orderId } = order;
	const { createdBy, doneAt, lastChangeAt, lastChangeBy } = log;

	let doneAtString = "---";
	if (doneAt) {
		if (doneAt !== "a few moments ago") {
			doneAtString = fullTimeFormat(doneAt);
		} else {
			doneAtString = doneAt;
		}
	}

	let lastChangeAtString = "---";
	if (lastChangeAt) {
		if (lastChangeAt !== "a few moments ago") {
			lastChangeAtString = fullTimeFormat(lastChangeAt);
		} else {
			lastChangeAtString = lastChangeAt;
		}
	}

	return (
		<div className="orderLogContainer">
			{orderId && (
				<>
					<div className="pretitle">Change Log</div>
					<ul className="list-unstyled list-spaced">
						<li>
							<div className="upcase text--quiet">Created by</div>
							<small>{createdBy ? createdBy : "---"}</small>
						</li>
						<li>
							<div className="upcase text--quiet">Date Created</div>
							<small>{doneAtString}</small>
						</li>
						<li>
							<div className="upcase text--quiet">Last change</div>
							<small>
								{lastChangeAtString}
								<br />
								{lastChangeBy ? lastChangeBy : ""}
							</small>
						</li>
					</ul>
				</>
			)}
		</div>
	);
};

export default OrderLog;
