import { UserAccountType } from "./UserModels";

export interface IOrderState {
	orderId: string;
	labelTemplate: any;
	lotNumber: string;
	orderItems: IOrderItemState[];
	log: ILog;
	brandId?: number;
	brandName?: string;
	factoryId?: number;
	factoryName?: string;
	status?: any;
	note?: string;
	shippingAddress?: IAddress;
	billingAddress?: IAddress;
	preferredShippingMethod?: string;
	placedBy?: string;
	extraInfo?: {};
}

interface ILog {
	createdBy: string;
	doneAt: string;
	lastChangeBy: string;
	lastChangeAt: string;
}

export interface IOrderItemState {
	orderItemId: number;
	styleNumber: string;
	styleDescription: string;
	season: string;
	customInformation: string;
	madeIn: number;
	units: IUnit[];
	fabricContent: number;
	selectedCareInstructions: number[];
	selectedAdditionalComponents: number[];
	selectedAdditionalInformation?: number[];
	allLabelLanguages: string[];
	chosenLabelLanguages: string[];
	translations: ITranslations;
	labelImages: ILabelImages[];
	includeQRCode: boolean;
	qrCodeId?: number;
	extraInfo?: {
		furOrigin?: number;
		insulationWeight?: number;
		sterilizationPermitNumber?: string;
		symbolsType: string;
	};
}

export interface ILabelImages {
	name: string;
	image: string;
}

export interface IUnit {
	upc: string;
	color: string;
	size: string;
	quantity: number;
	blackLabel: boolean;
	qrCodeId?: string;
}

interface ITranslations {
	madeInTranslations: {
		[languageCode: string]: string;
	};
	fabricContentTranslations: {
		[languageCode: string]: string;
	};
	careInstructionsTranslations: {
		[careInstructionId: number]: {
			[languageCode: string]: string;
		};
	};
	additionalComponentsTranslations: {
		[additionalComponentId: number]: {
			[languageCode: string]: string;
		};
	};
}

export interface IComposition {
	component: string;
	fibers: IFiberItem[];
	showPercentage: boolean;
}

export interface IFiberItem {
	fiber: string;
	percentage: number;
}

export const initialComposition = {
	component: "",
	fibers: [{ fiber: "", percentage: 0 }],
	showPercentage: true,
};

export interface ICompositionInvalidMessage {
	component: boolean;
	fibers: IFiberItemInvalidMessage[];
}

export interface IFiberItemInvalidMessage {
	fiber: boolean;
	percentage: boolean;
}

export interface ILabelTemplate {
	id: number;
	brand_id: number;
	name: string;
	additional_data: IAdditionalData[];
	settings: {
		form_settings: {
			quantity: {
				minimum: number;
				step: number;
			};
		};
	};
}

export interface IAdditionalData {
	data: any;
	fields: IField[];
}

export interface IField {
	field_name: string;
	field_type: string;
	select: string;
	type: number;
}

export interface IFieldOption {
	id: number;
	name: string;
	value: string;
}

export interface IFieldsValues {
	[field_type: number]: IFieldOption[];
}

export interface ILabelTemplateSettings {
	selected_languages: string[];
	form_settings: IFieldsValues;
}

export interface ISearchDataValues {
	madeIns: IDefaultOption[];
	fabricContent: IDefaultOption[];
	fibers: IDefaultOption[];
	components: IDefaultOption[];
	careInstructions: IDefaultOption[];
	translationLanguages: ILanguage[];
	additional_form_data: IFieldsValues;
	settings: any;
}

export interface IDefaultOption {
	id: number;
	name: string;
	translatable_to: string[];
}

export interface ILanguage {
	code: string;
	name: string;
}

export interface IOrderStatus {
	code: number;
	name: string;
	nextStatusText: string;
	nextStatusCode?: number;
	owners: number[];
	updatableTo: number[];
}

export interface ILabelImage {
	name: string;
	image?: string;
	size?: string;
}

export const OrderStatusesFlow = Object.freeze({
	OPEN: {
		code: 1,
		name: "Open",
		nextStatusText: "Send to factory review",
		nextStatusCode: 2,
		owners: [UserAccountType.BRAND_ADMIN, UserAccountType.BRAND_MEMBER],
		get updatableTo() {
			return [
				OrderStatusesFlow.REVIEW_REQUESTED.code,
				OrderStatusesFlow.CANCELED.code,
				OrderStatusesFlow.PRINT_READY.code,
			];
		},
	},
	REVIEW_REQUESTED: {
		code: 2,
		name: "Review Requested",
		owners: [UserAccountType.BRAND_ADMIN, UserAccountType.BRAND_MEMBER],
		get updatableTo() {
			return [
				OrderStatusesFlow.CHANGE_REQUESTED.code, // request changes to the order
				OrderStatusesFlow.APPROVED.code, // approve the order
			];
		},
	},
	CHANGE_REQUESTED: {
		code: 3,
		name: "Change Requested",
		nextStatusText: "Approve Order",
		owners: [UserAccountType.FACTORY],
		get updatableTo() {
			return [
				OrderStatusesFlow.REVIEW_REQUESTED.code, // re-request a review
				OrderStatusesFlow.CANCELED.code, // cancel the order
			];
		},
	},
	APPROVED: {
		code: 4,
		name: "Approved",
		nextStatusText: "Place Order",
		nextStatusCode: 5,
		owners: [UserAccountType.FACTORY],
		get updatableTo() {
			return [
				OrderStatusesFlow.PLACED.code,
				OrderStatusesFlow.CANCELED.code,
				OrderStatusesFlow.PRINT_READY.code,
			];
		},
	},
	PLACED: {
		code: 5,
		name: "Placed",
		nextStatusText: "",
		nextStatusCode: 6,
		owners: [UserAccountType.LABEL_MAKER_ADMIN],
		get updatableTo() {
			return [OrderStatusesFlow.IN_PRODUCTION.code];
		},
	},
	IN_PRODUCTION: {
		code: 6,
		name: "In Production",
		nextStatusText: "",
		nextStatusCode: undefined,
		owners: [UserAccountType.LABEL_MAKER_ADMIN],
		get updatableTo() {
			return [
				OrderStatusesFlow.PARTIALLY_SHIPPED.code,
				OrderStatusesFlow.SHIPPED.code,
			];
		},
	},
	PARTIALLY_SHIPPED: {
		code: 7,
		name: "Partially Shipped",
		nextStatusText: "",
		nextStatusCode: undefined,
		owners: [UserAccountType.LABEL_MAKER_ADMIN],
		get updatableTo() {
			return [OrderStatusesFlow.SHIPPED.code];
		},
	},
	SHIPPED: {
		code: 8,
		name: "Shipped",
		nextStatusText: "",
		nextStatusCode: undefined,
		owners: [UserAccountType.LABEL_MAKER_ADMIN],
		get updatableTo() {
			return [OrderStatusesFlow.COMPLETED.code];
		},
	},
	COMPLETED: {
		code: 9,
		name: "Completed",
		nextStatusText: "",
		nextStatusCode: undefined,
		owners: [],
		get updatableTo() {
			return [];
		},
	},
	CANCELED: {
		code: 10,
		name: "Canceled",
		nextStatusText: "",
		owners: [],
		get updatableTo() {
			return [];
		},
	},
	GENERATING_LAYOUTS: {
		code: 20,
		name: "Generating Layouts",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [
				OrderStatusesFlow.PRINT_READY.code,
				OrderStatusesFlow.GENERATING_LAYOUTS_FAILED.code,
			];
		},
	},
	GENERATING_LAYOUTS_FAILED: {
		code: 21,
		name: "Gen. Layouts Failed",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [
				OrderStatusesFlow.GENERATING_LAYOUTS.code,
				OrderStatusesFlow.PRINTING_CANCELED.code,
			];
		},
	},
	PRINT_READY: {
		code: 22,
		name: "Print ready",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [
				OrderStatusesFlow.PRINTING.code,
				OrderStatusesFlow.PRINTING_CANCELED.code,
			];
		},
	},
	PRINTING: {
		code: 23,
		name: "Printing",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [
				OrderStatusesFlow.PARTIALLY_PRINTED.code,
				OrderStatusesFlow.PRINTING_STOPPED.code,
				OrderStatusesFlow.PRINTED.code,
			];
		},
	},
	PRINTING_STOPPED: {
		code: 24,
		name: "Printing Stopped",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [
				OrderStatusesFlow.PRINTING.code,
				OrderStatusesFlow.PRINTING_CANCELED.code,
			];
		},
	},
	PARTIALLY_PRINTED: {
		code: 25,
		name: "Partially Printed",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [
				OrderStatusesFlow.PRINTING.code,
				OrderStatusesFlow.PRINTING_CANCELED.code,
			];
		},
	},
	PRINTED: {
		code: 26,
		name: "Printed",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [];
		},
	},
	PRINTING_CANCELED: {
		code: 27,
		name: "Printing Canceled",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [];
		},
	},
});

export const PrintStatusesFlow = Object.freeze({
	GENERATING_LAYOUTS: {
		code: 1,
		name: "Generating Layouts",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [
				PrintStatusesFlow.PRINT_READY.code,
				PrintStatusesFlow.GENERATING_LAYOUTS_FAILED.code,
			];
		},
	},
	GENERATING_LAYOUTS_FAILED: {
		code: 2,
		name: "Generating Layouts Failed",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [];
		},
	},
	PRINT_READY: {
		code: 3,
		name: "Print ready",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [PrintStatusesFlow.PRINTING.code];
		},
	},
	PRINTING: {
		code: 4,
		name: "Printing",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [
				PrintStatusesFlow.PRINTING_STOPPED.code,
				PrintStatusesFlow.PRINTED.code,
			];
		},
	},
	PRINTING_STOPPED: {
		code: 5,
		name: "Printing Stopped",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [
				PrintStatusesFlow.PRINTING.code,
				PrintStatusesFlow.PRINTING_CANCELED.code,
			];
		},
	},
	PRINTED: {
		code: 6,
		name: "Printed",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [];
		},
	},
	PRINTING_CANCELED: {
		code: 7,
		name: "Printing Canceled",
		owners: [
			UserAccountType.BRAND_ADMIN,
			UserAccountType.BRAND_MEMBER,
			UserAccountType.FACTORY,
		],
		get updatableTo() {
			return [];
		},
	},
});

export const PrinterStatuses = Object.freeze({
	READY: { code: 0, name: "Ready" },
	PRINTING: { code: 1, name: "Printing" },
	ERROR_OUT_OF_PAPER: { code: 20, name: "Out of paper" },
	ERROR_SYNTAX: { code: 21, name: "Error syntax" },
	ERROR_OFFLINE: { code: 22, name: "Offline" },
	ERROR_QUANTITY: { code: 23, name: "Error quantity" },
	ERROR_UNKNOWN: { code: 24, name: "Error unknown" },
	ERROR_OUT_OF_RIBBON: { code: 25, name: "Out of ribbon" },
	ERROR_CONNECTION_LOST: { code: 26, name: "Error connection lost" },
	ERROR_STACKER_FULL: { code: 27, name: "Error stacker full" },
	ERROR_CUTTER: { code: 28, name: "Error cutter issue" },
});

export const TemplateStatuses = Object.freeze({
	INACTIVE: { code: 0, name: "Inactive" },
	ACTIVE: { code: 1, name: "Active" },
	ARCHIVED: { code: 2, name: "Archived" },
});

export const getPrintStatusesFlowName = (printJobStatusCode: number) => {
	for (const key in PrintStatusesFlow) {
		let keyOfPrintStatusesFlow = key as keyof typeof PrintStatusesFlow;

		if (PrintStatusesFlow[keyOfPrintStatusesFlow].code === printJobStatusCode) {
			return PrintStatusesFlow[keyOfPrintStatusesFlow].name;
		}
	}
};

export const ShippingMethod = Object.freeze({
	DHL: { code: 1, name: "DHL" },
	UPS: { code: 2, name: "UPS" },
	FEDEX: { code: 3, name: "FedEx" },
	"SF EXPRESS": { code: 4, name: "SF Express" },
	"Freight Forwarder": { code: 5, name: "Freight Forwarder" },
	"Messenger Service": { code: 6, name: "Messenger Service" },
	"Pick-Up": { code: 7, name: "Pick-Up" },
	"To Be Advised": { code: 8, name: "To Be Advised" },
	"Nonstop Express (NSE)": { code: 9, name: "Nonstop Express (NSE)" },
}) as any;

export const shippingMethodsTransform = (shippingMethods: string[]) => {
	let shippingMethodsObject = {} as any;
	shippingMethods.forEach(
		(method: string, index: number) =>
			(shippingMethodsObject[method] = { code: index + 1, name: method })
	);

	return shippingMethodsObject;
};

export const initialShipment = {
	id: undefined,
	shippingMethod: "---",
	trackingCode: "",
	comment: "",
	isDirty: false,
};

export const initialOrder = {
	orderId: "",
	labelTemplate: {
		id: -1,
		name: "",
		additional_data: {},
		settings: {},
	},
	lotNumber: "",
	orderItems: [],
	log: {
		createdBy: "",
		doneAt: "",
		lastChangeBy: "",
		lastChangeAt: "",
	},
	brandId: undefined,
	factoryId: undefined,
	factoryName: "",
	status: OrderStatusesFlow.OPEN.code,
	note: undefined,
	shippingAddress: undefined,
	billingAddress: undefined,
	preferredShippingMethod: undefined,
};

export const initialValuesState = {
	madeIns: [],
	fabricContent: [],
	fibers: [],
	components: [],
	careInstructions: [],
	additionalComponents: [],
	translationLanguages: [],
};

export const initialOrderItemState = {
	orderItemId: -1,
	styleNumber: "",
	styleDescription: "",
	season: "",
	customInformation: "",
	madeIn: -1,
	units: [
		{ upc: "", color: "", size: "", quantity: 0, blackLabel: false },
	] as IUnit[],
	fabricContent: -1,
	selectedAdditionalComponents: [],
	selectedAdditionalInformation: undefined,
	selectedCareInstructions: [],
	allLabelLanguages: [],
	chosenLabelLanguages: [],
	translations: {
		madeInTranslations: {},
		fabricContentTranslations: {},
		careInstructionsTranslations: {},
		additionalComponentsTranslations: {},
	},
	labelImages: [],
	includeQRCode: false,
	qrCodeId: undefined,
} as IOrderItemState;

export const INSULATION_WEIGHT_INFO = {
	insulation: "填充物",
	fibers: {
		DOWN: "羽绒",
		"GREY DUCK": "灰鸭绒",
		"GREY GOOSE": "灰鹅绒",
		"WHITE DUCK": "白鸭绒",
		"WHITE GOOSE": "白鹅绒",
	},
	percentage: "含绒量",
	weight: "充绒量",
};

export const FUR_ORIGIN_LIST = Object.freeze([
	{
		id: 1,
		name: "SPAIN",
		translations: { en: "SPAIN", fr: "ESPAGNE", "zh-Hans": "西班牙" },
	},
	{
		id: 2,
		name: "UNITED KINGDOM",
		translations: {
			en: "UNITED KINGDOM",
			fr: "ROYAUME-UNI",
			"zh-Hans": "英国",
		},
	},
	{
		id: 3,
		name: "CHINA",
		translations: { en: "CHINA", fr: "CHINE", "zh-Hans": "中国" },
	},
	{
		id: 4,
		name: "TURKEY",
		translations: { en: "TURKEY", fr: "TURQUIE", "zh-Hans": "土耳其" },
	},
	{
		id: 5,
		name: "AUSTRALIA",
		translations: { en: "AUSTRALIA", fr: "AUSTRALIE", "zh-Hans": "澳大利亚" },
	},
	{
		id: 6,
		name: "BULGARIA",
		translations: { en: "BULGARIA", fr: "BULGARIE", "zh-Hans": "保加利亚" },
	},
]) as any;

export const FUR_ORIGIN_TRANSLATED = Object.freeze({
	en: "FUR ORIGIN",
	fr: "ORIGINE DE LA FOURRERE",
	"zh-Hans": "毛皮原产地",
}) as any;

export const SHEARLING_NEED_FUR_ORIGIN = Object.freeze(["羊毛革", "羊毛皮"]);

export const ORDER_PREVIEW_STATES = Object.freeze({
	HIDDEN: 0,
	GENERATING_LAYOUTS: 1,
	SHOW: 2,
});

export const ORDER_SYMBOLS_TYPES = Object.freeze({
	CHINA: "china",
	US: "usa",
});

export const XLSX_STYLE_COLUMNS_NAMES = Object.freeze({
	STYLE_NUMBER: "Style Number",
	STYLE_DESCRIPTION: "Style Description",
	SEASON: "Season",
	MADE_IN: "Made In",
	FABRIC_CONTENT: "Content Composition",
	CARE_INSTRUCTIONS: "Care Instructions",
	ADDITIONAL_COMPONENTS: "Additional Components",
	INCLUDE_QR_CODE: "QR Code",
});

export type XLSX_STYLE_COLUMNS_NAMES_KEYS =
	keyof typeof XLSX_STYLE_COLUMNS_NAMES;

export type XLSX_STYLE_COLUMNS_NAMES_VALUES =
	(typeof XLSX_STYLE_COLUMNS_NAMES)[XLSX_STYLE_COLUMNS_NAMES_KEYS];

export interface IAddress {
	company_name: string;
	full_name: string;
	phone_number?: string;
	line1: string;
	line2?: string;
	postal_code: string;
	city: string;
	state?: string;
	country: string;
	default: boolean;
}

export const initialAddress = {
	company_name: "",
	full_name: "",
	phone_number: "",
	line1: "",
	line2: "",
	postal_code: "",
	city: "",
	state: "",
	country: "",
	default: false,
};

export const MAX_ITEMS_IN_ORDER = {
	WITHOUT_SIZE: 20,
	WITH_SIZE: 5,
};

export interface IObjectWithStringValues {
	[key: string]: string;
}

export const PSEUDO_URL_QUERY_VALUES: IObjectWithStringValues = {
	SEARCH_TEXT: "f",
	STATUS: "s",
	BRAND: "b",
	OFFSET: "o",
	LIMIT: "l",
	ACCOUNT_EXECUTIVE: "a",
	PRINTER: "p",
};

export const DEFAULT_STYLE_NAME = "NEW STYLE";

export const ORDER_ITEMS_PREVIEW_TEXT_LENGTH = {
	REFERENCE_NUMBER: 30,
	NOTE: 500,
};

export const ADDRESS_TEXT_LENGTH = {
	COMPANY_NAME: 100,
	LINE1: 100,
	LINE2: 100,
	CITY: 60,
	STATE: 60,
	COUNTRY: 60,
	FULL_NAME: 50,
	PHONE_NUMBER: 25,
	POSTAL_CODE: 15,
};

export interface PrintJob {
	size: number;
	quantity: number;
	printQuantity: number;
	style: string;
	images?: any;
	showImages?: boolean;
}

export const isPrintStatus = (orderStatus: number) => {
	return orderStatus >= OrderStatusesFlow.GENERATING_LAYOUTS.code;
};

export interface IOrderDispatch {
	order: IOrderState;
	setOrder: React.Dispatch<React.SetStateAction<IOrderState>>;
	orderItem: IOrderItemState;
	setOrderItem: React.Dispatch<React.SetStateAction<any>>;
	itemsWithMissingInfo: any;
	copyOrReorderWholeOrder: () => void;
	showPreviewOrder: number;
	setShowPreviewOrder: React.Dispatch<React.SetStateAction<number>>;
	saveOrderFinishedSuccessfully: React.MutableRefObject<boolean>;
}
export interface IOrderItemAction {
	type: string;
	payload?: any;
}

export interface IPrinter {
	id: number;
	name: string;
	ip_address: string;
	settings: any;
	status_code: number;
	status_name: string;
}
