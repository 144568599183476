import React, { useContext, useEffect, useRef, useState } from "react";

import { OrderDispatch } from "Containers/Order";
import HelpTooltip from "Components/Shared/HelpTooltip";
import { IUnit } from "Models/OrderModels";
import Popup from "Components/Shared/Popup";
import Icon from "Components/Shared/Icon";
import { blockFloatingPointNumbers } from "Utils/utils";

const UnitInformation: React.FunctionComponent = () => {
	const quantityInputRef = useRef(null) as any;
	const upcRef = useRef(null) as any;

	const [addAnotherUnitClicked, setAddAnotherUnitClicked] = useState(false);

	const {
		orderItem,
		setOrderItem,
		order: { labelTemplate },
	} = useContext(OrderDispatch);
	const { minimum, step } = labelTemplate.settings.form_settings.quantity;

	let hideUnits: any;
	if (labelTemplate?.settings?.form_settings?.units) {
		hideUnits = labelTemplate.settings.form_settings.units;
	}

	let sizes = {} as any;
	if (labelTemplate?.additional_data) {
		const { data } = labelTemplate.additional_data;
		if (data) {
			({ sizes } = data);
		}
	}

	const { units } = orderItem;

	useEffect(() => {
		if (units.length > 1 && upcRef.current && addAnotherUnitClicked) {
			upcRef.current.focus();
		}
	}, [units]); // eslint-disable-line react-hooks/exhaustive-deps

	const changeOrderData = (unitData: any, index: number) => {
		const unitsArray = [...units];
		unitsArray[index] = { ...unitsArray[index], ...unitData };
		setOrderItem({ units: unitsArray });
		if (addAnotherUnitClicked) {
			setAddAnotherUnitClicked(false);
		}
	};

	const onRemoveUnit = (index: any) => {
		units.splice(index, 1);
		setOrderItem({ units });
	};

	const onAddUnit = () => {
		setOrderItem({
			units: [
				...units,
				{
					upc: "",
					color: "",
					size: "",
					quantity: minimum,
					blackLabel:
						labelTemplate?.settings?.default_values?.default_black_label ||
						false,
				},
			],
		});
		setAddAnotherUnitClicked(true);
	};

	let totalQuantity = 0;
	if (orderItem.units.length > 0) {
		totalQuantity = orderItem.units
			.map((unit: IUnit) => unit.quantity)
			.reduce((prev: number, next: number) => Number(prev) + Number(next));
	}

	return (
		<>
			<div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>Units</span>
				<HelpTooltip helpFor="UnitsInformation" />
			</div>
			<fieldset className="box box--light box--shadowed mb--md">
				{units.map((oneUnit: IUnit, index: number) => {
					const { upc, color, size, quantity, blackLabel } = oneUnit;

					const invalidSize =
						!Array.isArray(sizes) && Object.keys(sizes)?.length > 0
							? size?.length > 0 &&
							  !size.startsWith("1SZ") &&
							  !Object.keys(sizes).find((sizeGroup: string) =>
									sizes[sizeGroup].includes(size)
							  )
							: false;
					return (
						<div
							key={index}
							className="mb--md mb-sm--base"
							style={{ marginBottom: "20px" }}
						>
							<div className="row" style={{ padding: "0 15px" }}>
								{(!hideUnits || (hideUnits && !hideUnits.hide_upc)) && (
									<div className="col-sm-6">
										<div className="form-group">
											<input
												ref={upcRef}
												className="form-control"
												type="text"
												name={`upc${index}`}
												id={`upc${index}`}
												value={upc ? upc : ""}
												data-empty={!upc}
												onChange={(e) =>
													changeOrderData({ upc: e.target.value.trim() }, index)
												}
												onBlur={(e) =>
													changeOrderData({ upc: e.target.value.trim() }, index)
												}
											/>
											<label
												className="form-control-label"
												htmlFor={`upc${index}`}
											>
												UPC
											</label>
										</div>
									</div>
								)}

								<div className="col-sm-6">
									<div className="form-group">
										<Popup
											show={quantity < minimum || quantity % step !== 0}
											text={`Value must be greater than or equal to ${minimum} and in increments of ${step}.`}
											testId="unitInformation-quantity"
										/>
										<input
											ref={quantityInputRef}
											className="form-control"
											type="number"
											min={minimum}
											step={step}
											name={`quantity${index}`}
											id={`quantity${index}`}
											data-empty={!quantity}
											value={!quantity ? "" : quantity}
											onInvalid={(e) => {
												e.preventDefault();
												quantityInputRef.current &&
													window.scrollTo(
														0,
														quantityInputRef.current.offsetTop
													);
											}}
											onChange={(e) =>
												changeOrderData(
													{ quantity: Number(e.target.value.trim()) },
													index
												)
											}
											onKeyDown={blockFloatingPointNumbers}
										/>
										<label
											className="form-control-label"
											htmlFor={`quantity${index}`}
										>
											Qty.
										</label>
									</div>
								</div>

								{(!hideUnits || (hideUnits && !hideUnits.hide_color)) && (
									<div className="col-sm-6">
										<div className="form-group">
											<input
												className="form-control"
												type="text"
												name={`color${index}`}
												id={`color${index}`}
												data-empty={!color}
												value={color ? color : ""}
												onChange={(e) =>
													changeOrderData(
														{ color: e.target.value.trim() },
														index
													)
												}
												onBlur={(e) =>
													changeOrderData(
														{ color: e.target.value.trim() },
														index
													)
												}
											/>
											<label
												className="form-control-label"
												htmlFor={`color${index}`}
											>
												Color
											</label>
										</div>
									</div>
								)}

								{(!hideUnits || (hideUnits && !hideUnits.hide_size)) && (
									<div className="col-sm-6">
										<div
											className={`form-group ${invalidSize && "has-danger"}`}
										>
											<Popup
												show={invalidSize}
												text={"Size not valid."}
												testId="unitInformation-size"
											/>
											{!Array.isArray(sizes) &&
											Object.keys(sizes)?.length > 0 &&
											(Object.keys(sizes).find((sizeGroup: string) =>
												sizes[sizeGroup].includes(size)
											) ||
												!size) &&
											!size?.startsWith("1SZ") ? (
												<div style={{ pointerEvents: "none" }}>
													<select
														value={size}
														className="form-control"
														id={`size${index}`}
														data-empty={!size}
														style={{
															paddingBottom: "13px",
															pointerEvents: "auto",
															cursor: "pointer",
														}}
														onChange={(e) =>
															changeOrderData(
																{ size: e.target.value.trim() },
																index
															)
														}
														onBlur={(e) =>
															changeOrderData(
																{ size: e.target.value.trim() },
																index
															)
														}
														data-testid="chooseSize-unitInformation"
													>
														<option value="" disabled hidden></option>

														{Object.keys(sizes).map((sizeGroup: string) => (
															<optgroup key={sizeGroup} label={sizeGroup}>
																{sizes?.[sizeGroup] &&
																	sizes[sizeGroup].map(
																		(value: any, index: number) => {
																			return (
																				<option
																					style={{ color: "#000" }}
																					key={`${value}-${index}`}
																					value={value}
																					data-testid={`unitInformation-sizes-${value}`}
																				>
																					{value}
																				</option>
																			);
																		}
																	)}
															</optgroup>
														))}
													</select>
													<label
														className="form-control-label"
														htmlFor={`size${index}`}
													>
														Size
													</label>
												</div>
											) : (
												<figure
													id="incrementalSearchInput"
													className={size ? "uploaded-images__figure" : ""}
												>
													<input
														className="form-control"
														type="text"
														name={`size${index}`}
														id={`size${index}`}
														data-empty={!size}
														value={size ? size : ""}
														onChange={(e) =>
															changeOrderData(
																{ size: e.target.value.trim() },
																index
															)
														}
														onBlur={(e) =>
															changeOrderData(
																{ size: e.target.value.trim() },
																index
															)
														}
													/>
													<label
														className="form-control-label"
														htmlFor={`size${index}`}
													>
														Size
													</label>
													<button
														className="uploaded-images__remove btn-no-style"
														style={{ top: "auto", right: "10px" }}
														id={"CloseInputSize"}
														onChange={(e) =>
															changeOrderData({ size: "" }, index)
														}
													>
														<Icon
															name="cross-rounded-filled"
															className="searchClearIcon"
														/>
													</button>
												</figure>
											)}
										</div>
									</div>
								)}

								{(!hideUnits || (hideUnits && !hideUnits.hide_black_label)) && (
									<div className="col-sm-12">
										<div className="form-group">
											<div className="form-check">
												<input
													className="form-check__input"
													type="checkbox"
													checked={blackLabel ? true : false}
													name={`blackLabel${index}`}
													id={`blackLabel${index}`}
													onChange={(e) =>
														changeOrderData(
															{ blackLabel: !units[index].blackLabel },
															index
														)
													}
												/>
												<label
													className="form-check__label"
													htmlFor={`blackLabel${index}`}
												>
													Black ground label
												</label>
											</div>
										</div>
									</div>
								)}
							</div>

							{units.length > 1 &&
								(!hideUnits || (hideUnits && !hideUnits.hide_add_another)) && (
									<button
										className="link--danger small remove-upc-block btn-no-style"
										onClick={() => onRemoveUnit(index)}
										data-testid="remove-unitInformation"
									>
										Remove
									</button>
								)}
						</div>
					);
				})}

				{(!hideUnits || (hideUnits && !hideUnits.hide_add_another)) && (
					<div className="row">
						<div className="col-sm-6">
							<button
								className="button button--outline-dark button--sm"
								id="blueBorderButton"
								onClick={onAddUnit}
								data-testid="addAnother-unitInformation"
							>
								Add Another
							</button>
						</div>

						<div
							className="col-sm-6"
							data-testid="unitInformation-totalQuantity"
						>
							Total quantity: {totalQuantity}
						</div>
					</div>
				)}
			</fieldset>
		</>
	);
};

export default UnitInformation;
