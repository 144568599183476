import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

import sprite from "images/icons.svg";

interface IStateProps {
	style: any;
	invalidInfo: any;
	refVar: any;
	accordionFixedOpen?: boolean;

	setNumberOfInvalidStyles?: (numberOfInvalidStyles: any) => void;
	setListWidth: (listWidth: number) => void;
}

const StylesListItem: React.FunctionComponent<IStateProps> = ({
	style,
	invalidInfo,
	refVar,
	accordionFixedOpen = false,

	setNumberOfInvalidStyles = () => {},
	setListWidth,
}) => {
	const {
		style_number,
		style_description,
		season,
		made_in,
		fabric_content,
		care_instructions,
		additional_components,
		include_qr_code,
	} = style;

	const [tooltipFor, setTooltipFor] = useState(undefined) as any;
	const [accordionIsOpen, setAccordionIsOpen] = useState<boolean | undefined>(
		accordionFixedOpen ? accordionFixedOpen : undefined
	);

	useEffect(() => {
		if (invalidStyle) {
			setNumberOfInvalidStyles(
				(numberOfInvalidStyles: number) => numberOfInvalidStyles + 1
			);
		}
	}, [invalidInfo]); // eslint-disable-line react-hooks/exhaustive-deps

	const invalidStyleNumberReason =
		invalidInfo?.invalid_style_numbers?.[style_number];

	const invalidStyleDescriptionReason =
		invalidInfo?.invalid_style_descriptions?.[style_description];

	const invalidSeasonReason = invalidInfo?.invalid_season?.[season];

	const invalidMadeInReason = invalidInfo?.invalid_made_ins?.[made_in];

	const invalidFabricContentReason =
		invalidInfo?.invalid_fabric_contents?.[fabric_content];

	const invalidCareInstructions = care_instructions?.filter(
		(instruction: any) => invalidInfo?.invalid_care_instructions?.[instruction]
	);

	const invalidAdditionalComponents = additional_components?.filter(
		(component: any) => invalidInfo?.invalid_additional_components?.[component]
	);

	// calculate heading right padding depending on the thickness of the scroll bar  === START

	const listItem = useRef<any>(null);

	const observer = useRef<any>(
		new ResizeObserver((entries) => {
			const { width } = entries[0].contentRect;
			setListWidth(width);
		})
	);

	useEffect(() => {
		if (listItem.current) {
			observer.current.observe(listItem.current);
		}

		let el = listItem.current;
		let obs = observer.current;
		return () => {
			obs.unobserve(el);
		};
	}, [listItem, observer]);

	// calculate heading right padding depending on the thickness of the scroll bar  === END

	const invalidStyle =
		invalidStyleNumberReason ||
		invalidStyleDescriptionReason ||
		invalidSeasonReason ||
		invalidMadeInReason ||
		invalidFabricContentReason ||
		invalidCareInstructions?.length > 0 ||
		invalidAdditionalComponents?.length > 0;

	return (
		<>
			{(!invalidInfo || invalidStyle) && (
				<div
					style={{
						borderBottom: "2px solid #f4f4f4",
						fontSize: "14px",
						cursor: accordionFixedOpen ? "initial" : "pointer",
					}}
					ref={refVar}
				>
					<article
						className={`list-item list-item--styles accordion  ${
							accordionIsOpen
								? "accordion-animation-open"
								: accordionIsOpen === undefined
								? ""
								: "accordion-animation-close"
						}
              `}
						onClick={() => {
							if (!accordionFixedOpen) {
								setAccordionIsOpen(!accordionIsOpen);
							}
						}}
						ref={listItem}
					>
						<div
							className={`${!style_number && "missing--info"} ${
								invalidStyleNumberReason && "link--danger invalidValue"
							} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
							data-tooltip-content={invalidStyleNumberReason}
							data-tooltip-id={`styleNumber${style_number}`}
							data-iscapture="true"
							onMouseOver={() =>
								invalidStyleNumberReason &&
								setTooltipFor(`styleNumber${style_number}`)
							}
							onMouseLeave={() => setTooltipFor(undefined)}
						>
							{style_number ?? ""}
						</div>

						<div
							className={` ${
								invalidStyleDescriptionReason && "link--danger invalidValue"
							} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
							data-tooltip-content={invalidStyleDescriptionReason}
							data-tooltip-id={`styleDesc${style_number}`}
							data-iscapture="true"
							onMouseOver={() =>
								invalidStyleDescriptionReason &&
								setTooltipFor(`styleDesc${style_number}`)
							}
							onMouseLeave={() => setTooltipFor(undefined)}
						>
							{(style_description ?? "") + "\n"}
						</div>

						<div
							className={` ${
								invalidSeasonReason && "link--danger invalidValue"
							} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
							data-tooltip-content={invalidSeasonReason}
							data-tooltip-id={`season-${style_number}`}
							data-iscapture="true"
							onMouseOver={() =>
								invalidSeasonReason && setTooltipFor(`season-${style_number}`)
							}
							onMouseLeave={() => setTooltipFor(undefined)}
						>
							{(season ?? "") + "\n"}
						</div>

						<div
							className={` ${
								invalidMadeInReason && "link--danger invalidValue"
							} ${!made_in && "missing--info"} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
							data-tooltip-content={invalidMadeInReason}
							data-tooltip-id={`madeIn${style_number}`}
							data-iscapture="true"
							onMouseOver={() =>
								invalidMadeInReason && setTooltipFor(`madeIn${style_number}`)
							}
							onMouseLeave={() => setTooltipFor(undefined)}
						>
							{(made_in ?? "") + "\n"}
						</div>

						<div
							className={` ${
								invalidFabricContentReason && "link--danger invalidValue"
							} ${!fabric_content && "missing--info"} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
							data-tooltip-content={invalidFabricContentReason}
							data-tooltip-id={`fabricContent${style_number}`}
							data-iscapture="true"
							onMouseOver={() =>
								invalidFabricContentReason &&
								setTooltipFor(`fabricContent${style_number}`)
							}
							onMouseLeave={() => setTooltipFor(undefined)}
						>
							{fabric_content && (
								<>
									{fabric_content
										.split(";")
										.map((value: any, index: number) => {
											return (
												<span key={index}>
													{!value ? <br /> : value + "\n"}
												</span>
											);
										})}
								</>
							)}
						</div>

						<div
							className={`${
								(!care_instructions || care_instructions.length === 0) &&
								"missing--info"
							} ${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
						>
							{care_instructions?.map((value: any, index: number) => {
								const invalidCareInstructionReason =
									invalidInfo?.invalid_care_instructions?.[value];
								return (
									<span
										key={index}
										className={`  
                      ${
												invalidCareInstructionReason &&
												"link--danger invalidValue"
											}
                      `}
										data-tooltip-content={invalidCareInstructionReason}
										data-tooltip-id={`care${style_number}${index}`}
										data-iscapture="true"
										onMouseOver={() =>
											invalidCareInstructionReason &&
											setTooltipFor(`care${style_number}${index}`)
										}
										onMouseLeave={() => setTooltipFor(undefined)}
									>
										{value + "\n"}
									</span>
								);
							})}
						</div>

						<div
							className={`${
								!accordionIsOpen
									? "truncate list-item-closed__details"
									: "list-item__details"
							}`}
						>
							{additional_components?.map((value: any, index: number) => {
								const invalidAdditionalComponentReason =
									invalidInfo?.invalid_additional_components?.[value];
								return (
									<span
										key={index}
										className={`${
											invalidAdditionalComponentReason &&
											"link--danger invalidValue"
										}`}
										data-tooltip-content={invalidAdditionalComponentReason}
										data-tooltip-id={`additional${style_number}${index}`}
										data-iscapture="true"
										onMouseOver={() =>
											invalidAdditionalComponentReason &&
											setTooltipFor(`additional${style_number}${index}`)
										}
										onMouseLeave={() => setTooltipFor(undefined)}
									>
										{value + "\n"}
									</span>
								);
							})}
						</div>

						<div className="list-item__details">
							{include_qr_code ? "YES" : "NO"}
						</div>
						{!accordionFixedOpen && (
							<div className="list-item__details">
								<div>
									<svg
										style={{
											height: "25px",
											width: "25px",
											fill: "black",
										}}
										className={`accordion-arrow ${
											accordionIsOpen ? "rotate-90" : "rotate-270"
										}`}
									>
										<use xlinkHref={`${sprite}#icon-double-left-arrow`} />
									</svg>
								</div>
							</div>
						)}
					</article>
				</div>
			)}

			<Tooltip
				className="reactTooltip errorTooltip"
				variant="light"
				id={tooltipFor}
				clickable={true}
				float
				isOpen={tooltipFor ? true : false}
			></Tooltip>
		</>
	);
};

export default StylesListItem;
