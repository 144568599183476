import React, { useContext, useEffect, useState } from "react";

import { LabelTemplateDispatch } from "../../../Containers/LabelTemplate";
import { ITemplateState } from "Models/TemplateModels";

const LabelDimensions: React.FunctionComponent = () => {
	const { template, setTemplate, checkGeneralLabelSettings, showMissingData } =
		useContext(LabelTemplateDispatch);

	const isDataMissing = (itemToCheck: string) => {
		return showMissingData && checkGeneralLabelSettings().includes(itemToCheck);
	};

	const [labelState, setLabelState] = useState({
		multiplePages: template?.dimensions?.multiplePages || false,
		width: template?.dimensions?.width || 0,
		height: template?.dimensions?.height || 0,
	});

	useEffect(() => {
		setTemplate((prevTemplate: ITemplateState) => ({
			...prevTemplate,
			dimensions: labelState,
		}));
	}, [labelState]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="flex-1">
			{/* <div className="pretitle flex-center-secondary-axis">
				<span style={{ lineHeight: 1 }}>Label dimensions</span>
				<HelpTooltip helpFor="LabelDimensions" />
			</div> */}
			<fieldset className="flex">
				<div className="flex flex-column">
					<div
						className={`flex-1 form-group ${
							isDataMissing("Label width") && "has-danger"
						} mb--base`}
					>
						<input
							type="number"
							className="form-control"
							name="width"
							id="width"
							value={labelState.width === 0 ? "" : labelState.width}
							data-empty={labelState.width === 0}
							onChange={(e) => {
								setLabelState({
									...labelState,
									width: Number(e.target.value),
								});
							}}
							min={25}
							step={1}
						/>
						<label className={`form-control-label`} htmlFor="width">
							Label width (mm)
						</label>
					</div>
					<div
						className={`form-group ${
							isDataMissing("Label height") && "has-danger"
						}`}
					>
						{labelState.multiplePages && (
							<>
								<input
									type="number"
									className="form-control"
									name="height"
									id="height"
									value={labelState.height === 0 ? "" : labelState.height}
									data-empty={labelState.height === 0}
									onChange={(e) =>
										setLabelState({
											...labelState,
											height: Number(e.target.value),
										})
									}
									min={25}
									step={1}
								/>
								<label
									className={`form-control-label ${
										isDataMissing("Label height") && "text--danger"
									}`}
									htmlFor="height"
								>
									Label height (mm)
								</label>
							</>
						)}
					</div>
				</div>
				<div className=" flex-1 form-group" style={{ paddingTop: "8px" }}>
					<div className="form-check ml--sm">
						<input
							className="form-check__input"
							type="checkbox"
							checked={labelState.multiplePages ? true : false}
							name="labelHeightCheckbox"
							id="labelHeightCheckbox"
							onChange={(e) => {
								setLabelState({
									...labelState,
									multiplePages: !labelState.multiplePages,
									height: labelState.multiplePages ? 0 : labelState.height,
								});
							}}
						/>
						<label className="form-check__label" htmlFor="labelHeightCheckbox">
							Fixed label height
						</label>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export default LabelDimensions;
