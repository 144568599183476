import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../react-query/constants";
import {
	FETCH_GET_PARAMS,
	URL_BASE,
	CustomError,
} from "../../Models/QueryModels";
import useErrorHandler from "Hooks/queryHooks/useErrorHandler";
import { toast } from "react-toastify";
import {
	EndpointPrefix,
	isBrand,
	USER_ACCOUNT_TYPE_VALUES,
} from "../../Models/UserModels";

async function manageError(response: Response) {
	if (!response.ok) {
		let responseJSON = await response.json();
		throw new CustomError(responseJSON.message, response.status);
	}
	return response;
}

let url = "";

async function getLabelTemplates(
	account_type_id: USER_ACCOUNT_TYPE_VALUES | undefined
	//currentBrandId: any
): Promise<any> {
	if (account_type_id) {
		url = `${URL_BASE.address}/${EndpointPrefix[account_type_id]}/data/labelTemplates`;
		const result = await fetch(url, FETCH_GET_PARAMS).then(manageError);
		return await result.json();
	}
}

export function useFetchLabelTemplates(
	account_type_id: USER_ACCOUNT_TYPE_VALUES | undefined /*brandId: any*/,
	setError: any
): any {
	const fallback: any = {};
	const errorHandler = useErrorHandler();

	const enabledCriteria = account_type_id && isBrand(account_type_id);

	const {
		isLoading,
		data = fallback,
		fetchStatus,
	} = useQuery(
		[QUERY_KEYS.LABEL_TEMPLATES, account_type_id], //, brandId],
		() => getLabelTemplates(account_type_id), //, brandId),
		{
			enabled: !!enabledCriteria,
			onSuccess: (data) => {
				return data;
			},
			onError: (error: CustomError) => {
				let errorMessage = errorHandler(error.status, error.message, url);
				toast.error(`Unable to get label templates. ${errorMessage}`);
				setError({
					title: "Something Went Wrong",
					text: "Please try again later",
					primary: {
						text: "",
						action: () => {},
					},
					secondary: {
						text: "",
						action: () => {},
					},
				});
			},
			cacheTime: 86400000, // 1 day
			staleTime: 86400000, // 1 day
		}
	);

	return {
		labelTemplates: data.message,
		isLoadingLabelTemplates: isLoading && fetchStatus !== "idle",
	};
}
