import Loading from "Components/Shared/Loading";
import { LabelTemplateDispatch } from "Containers/LabelTemplate";
import { Dispatch, FC, SetStateAction, useContext } from "react";

interface IProps {
	zoom: number;
	setZoom: Dispatch<SetStateAction<number>>;
}
const Zoom: FC<IProps> = ({ zoom, setZoom }) => {
	const { isLoadingDrawTemplate } = useContext(LabelTemplateDispatch);
	return (
		<div className="zoom">
			<div className="flex">
				<div
					className="zoom-item"
					onClick={() => {
						setZoom((prevZoom: number) => {
							if (zoom !== 70) {
								return prevZoom - 10;
							}
							return 70;
						});
					}}
				>
					-
				</div>
				<div className="zoom-item">{zoom}%</div>
				<div
					className="zoom-item"
					style={{ marginRight: 0 }}
					onClick={() => {
						setZoom((prevZoom: number) => {
							if (zoom !== 130) {
								return prevZoom + 10;
							}
							return 130;
						});
					}}
				>
					+
				</div>
			</div>
			<div className="draw-images-loading">
				<Loading
					show={isLoadingDrawTemplate}
					text="Loading..."
					imgClass=""
					divClass=""
				/>
			</div>
		</div>
	);
};

export default Zoom;
