import { LabelTemplateDispatch } from "Containers/LabelTemplate";
import {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import SectionSelection from "./SectionSelection";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Icon from "Components/Shared/Icon";
import sprite from "images/icons.svg";
import { Tooltip } from "react-tooltip";
import useOnClickOutside from "Hooks/outsideClickHook";
import DynamicSections from "./DynamicSections";

interface IProps {
	imagesHoveredSectionId: number | undefined;

	setImagesHoveredSectionId: Dispatch<SetStateAction<number | undefined>>;
	removeSectionById: (id: number) => void;
}

const ContentEditor: FC<IProps> = ({
	imagesHoveredSectionId,
	setImagesHoveredSectionId,
	removeSectionById,
}) => {
	const { template, setTemplate, getImages } = useContext(
		LabelTemplateDispatch
	);

	const [showSections, setShowSections] = useState(false);
	const [countForId, setCountForId] = useState(0);
	const [dragOn, setDragOn] = useState(false);

	useEffect(() => {
		let sectionWithMaxId = template?.sections?.reduce(
			(currentSection: any, nextSection: any) => {
				return nextSection.id > currentSection.id
					? nextSection
					: currentSection;
			},
			template.sections[0]
		);
		setCountForId(sectionWithMaxId ? sectionWithMaxId.id : -1);
	}, [template.sections.length]); // eslint-disable-line react-hooks/exhaustive-deps

	const tooltipRef = useRef() as any;

	const handleOnDragEnd = (result: any) => {
		if (!result.destination) return;

		const items = Array.from(template.sections);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		let newTemplate = { ...template, sections: [...items] };
		setTemplate(newTemplate);

		getImages(newTemplate);
	};

	const { sections } = template;

	const [isSectionSettingsOpen, setIsSectionSettingsOpen] =
		useState<any>(undefined);

	const [
		clickedOutsideEditSectionTooltip,
		setClickedOutsideEditSectionTooltip,
	] = useState(false);

	useOnClickOutside(
		tooltipRef,
		useCallback(() => setClickedOutsideEditSectionTooltip(true), [])
	);

	return (
		<div className="content-editor">
			<div className="content-editor-inner">
				<div className="text--base text-uppercase mb--xs font-weight-500">
					Content Editor
				</div>
				<div style={{ paddingRight: "10px" }}>
					<SectionSelection
						position={0}
						countForId={countForId}
						showSections={showSections}
						setShowSections={setShowSections}
						setIsSectionSettingsOpen={setIsSectionSettingsOpen}
						setCountForId={setCountForId}
					/>
				</div>
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId="selectedItems">
						{(provided) => (
							<div
								className="section-picker-droppable"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{sections?.map((section: any, index: number) => {
									return (
										<Draggable
											key={section.type + index}
											draggableId={`${section.type + index}`}
											index={index}
											isDragDisabled={!dragOn}
										>
											{(provided) => (
												<div
													id={`${index}`}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={{
														...provided.draggableProps.style,
													}}
												>
													<div key={section.type + index}>
														<div
															className={`section-item ${
																imagesHoveredSectionId === section.id &&
																"section-item-hovered-on-images"
															}`}
															onMouseEnter={() => {
																setImagesHoveredSectionId(section.id);
															}}
															onMouseLeave={() => {
																setImagesHoveredSectionId(undefined);
															}}
														>
															<div
																className="section-item-drag"
																onMouseEnter={() => {
																	setDragOn(true);
																}}
																onMouseLeave={() => {
																	setDragOn(false);
																}}
																style={{ backgroundColor: "white" }}
															>
																<Icon
																	name="draggable"
																	className="icon section-item-drag-icon"
																/>
																<div style={{ marginTop: "-3px" }}>
																	<Icon
																		name="draggable"
																		className="icon section-item-drag-icon"
																	/>
																</div>
															</div>
															<div
																className="section-item-edit-remove"
																data-event="click"
																data-tooltip-id={section.type + section.id}
																onClick={() => {
																	setIsSectionSettingsOpen({ ...section });
																}}
															>
																<div className="flex flex-center-secondary-axis">
																	<span
																		style={{
																			verticalAlign: "baseline",
																			lineHeight: "16px",
																		}}
																	>
																		{section.type}
																	</span>
																</div>
																<div className="flex flex-center-secondary-axis">
																	<div className="section-item-edit text-uppercase">
																		<svg className="section-item-edit-icon icon mr--sm">
																			<use xlinkHref={`${sprite}#icon-edit`} />
																		</svg>
																	</div>
																	<div
																		className="section-item-remove btn-no-style"
																		style={{ position: "relative" }}
																		onClick={() =>
																			removeSectionById(section.id)
																		}
																	>
																		<Icon name="cross-rounded-filled" />
																	</div>
																</div>
															</div>

															<Tooltip
																className="drawing-area-react-tooltip box--shadowed fixed"
																id={section.type + section.id}
																aria-haspopup="true"
																clickable={true}
																place="right"
																openOnClick
																isOpen={
																	isSectionSettingsOpen?.id === section?.id
																}
															>
																<div
																	ref={
																		isSectionSettingsOpen?.id === section?.id
																			? tooltipRef
																			: null
																	}
																>
																	<DynamicSections
																		currentSelectedItem={isSectionSettingsOpen}
																		setIsSectionSettingsOpen={
																			setIsSectionSettingsOpen
																		}
																		clickedOutsideEditSectionTooltip={
																			clickedOutsideEditSectionTooltip
																		}
																		setClickedOutsideEditSectionTooltip={
																			setClickedOutsideEditSectionTooltip
																		}
																		setImagesHoveredSectionId={
																			setImagesHoveredSectionId
																		}
																		removeSectionById={removeSectionById}
																	/>
																</div>
															</Tooltip>
														</div>

														<div className="mb--xs mt--xs">
															<SectionSelection
																position={index + 1}
																countForId={countForId}
																setCountForId={setCountForId}
																showSections={showSections}
																setShowSections={setShowSections}
																setIsSectionSettingsOpen={
																	setIsSectionSettingsOpen
																}
															/>
														</div>
													</div>
												</div>
											)}
										</Draggable>
									);
								})}

								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</div>
	);
};

export default ContentEditor;
