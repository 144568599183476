import React, { useCallback, useContext, useRef } from "react";

import { OrderDispatch } from "Containers/Order";

import useOnClickOutside from "Hooks/outsideClickHook";

import Icon from "Components/Shared/Icon";

import { ORDER_ITEMS_PREVIEW_TEXT_LENGTH } from "Models/OrderModels";

interface IProps {
	localNote: string;

	setShowNoteModal: (show: boolean) => void;
	setLocalNote: (note: string | undefined) => void;
}

const NoteModal: React.FunctionComponent<IProps> = ({
	localNote,

	setShowNoteModal,
	setLocalNote,
}) => {
	const { order, setOrder } = useContext(OrderDispatch);

	const { note } = order;

	const modalRef = useRef(null) as any;

	const onClearNote = () => {
		setLocalNote("");
	};

	const moveCaretAtEnd = (e: any) => {
		var temp_value = e.target.value;
		e.target.value = "";
		e.target.value = temp_value;
	};

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onCancelNote();
		}
	};

	const onCancelNote = () => {
		setLocalNote(note);
		setShowNoteModal(false);
	};

	const onSaveNote = () => {
		setOrder({ ...order, note: localNote?.replace(/\s+/g, " ").trim() });
		setShowNoteModal(false);
	};

	useOnClickOutside(modalRef, useCallback(onCancelNote, [setShowNoteModal])); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="modal" onKeyDown={onKeyDown}>
			<div
				className="modal-content block-center show"
				ref={modalRef}
				id="noteModal"
				tabIndex={1}
			>
				<button
					className="drawer__close btn-no-style"
					data-dismiss="drawer"
					aria-label="Close"
					onClick={onCancelNote}
				>
					<Icon name="cross-rounded" />
				</button>

				<p style={{ marginTop: "25px" }}>
					Enter any notes you have about this order:
				</p>
				<div className="form-group" style={{ marginTop: "0px" }}>
					<textarea
						autoFocus={true}
						onFocus={moveCaretAtEnd}
						className="form-control"
						name="orderNote"
						id="orderNote"
						rows={8}
						value={localNote ? localNote : ""}
						data-empty={!localNote}
						maxLength={ORDER_ITEMS_PREVIEW_TEXT_LENGTH.NOTE}
						onChange={(e) => setLocalNote(e.target.value)}
					/>
					<label
						className="form-control-label"
						htmlFor="note"
						style={{ top: "10px", left: "5px" }}
					>
						Note
					</label>
				</div>

				<div style={{ marginTop: "20px" }}>
					<div
						className="button button--primary button--sm"
						onClick={onSaveNote}
					>
						&nbsp;&nbsp;Ok&nbsp;&nbsp;
					</div>
					<div
						className="button button--primary button--sm"
						onClick={onClearNote}
						style={{ marginLeft: "30px" }}
					>
						Clear
					</div>
					<div
						className="button button--primary button--sm"
						onClick={onCancelNote}
						style={{ marginLeft: "30px" }}
					>
						Cancel
					</div>
				</div>
			</div>
		</div>
	);
};

export default NoteModal;
