import { useContext } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../react-query/constants";
import { toast } from "react-toastify";

import { GlobalDispatch } from "../../Containers/Home";
import {
	FETCH_GET_PARAMS,
	URL_BASE,
	CustomError,
} from "../../Models/QueryModels";
import {
	EndpointPrefix,
	USER_ACCOUNT_TYPE_VALUES,
} from "../../Models/UserModels";
import { IOrderState, OrderStatusesFlow } from "Models/OrderModels";

import useErrorHandler from "Hooks/queryHooks/useErrorHandler";

async function manageError(response: Response) {
	if (!response.ok) {
		let responseJSON = await response.json();
		throw new CustomError(responseJSON.message, response.status);
	}
	return response;
}

let url = "";

async function getInitialValues(
	account_type_id: USER_ACCOUNT_TYPE_VALUES,
	currentBrandId: any
): Promise<any> {
	url = `${URL_BASE.address}/${EndpointPrefix[account_type_id]}/data/initialValues?brandId=${currentBrandId}`;
	const result = await fetch(url, FETCH_GET_PARAMS).then(manageError);
	return await result.json();
}

export function useFetchInitialValues(order: IOrderState, brandId: any): any {
	const { setError } = useContext(GlobalDispatch);
	const navigate = useNavigate();

	const fallback: any = {
		madeIns: [],
		fabricContent: [],
		fibers: [],
		careInstructions: [],
		translationLanguages: [],
	};

	const errorHandler = useErrorHandler();
	const {
		user: { account_type_id },
	} = useContext(GlobalDispatch);

	const enabledCriteria = !!(
		brandId &&
		(order.status === OrderStatusesFlow.OPEN.code ||
			order.status === OrderStatusesFlow.CHANGE_REQUESTED.code ||
			order.status === OrderStatusesFlow.APPROVED.code)
	);

	const {
		isLoading,
		data = fallback,
		fetchStatus,
	} = useQuery(
		[QUERY_KEYS.INITIAL_VALUES, account_type_id, brandId],
		() => getInitialValues(account_type_id, brandId),
		{
			enabled: enabledCriteria,
			onSuccess: (data) => {
				return data;
			},
			onError: (error: CustomError) => {
				let errorMessage = errorHandler(error.status, error.message, url);
				toast.error(`Unable to get initial values. ${errorMessage}`);
				setError({
					title: "Something Went Wrong",
					text: "Please try again later",
					primary: {
						text: "Back to orders",
						action: () => navigate(`/orders`),
					},
					secondary: {
						text: "",
						action: () => {},
					},
				});
			},
			cacheTime: 604800000, // 7 days
			staleTime: 86400000, // 1 day
		}
	);

	return {
		initialValues: data.message,
		isLoadingInitialValues: isLoading && fetchStatus !== "idle",
	};
}
