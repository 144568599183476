interface IProps {
	headerRef: any;
	scrollPadding: number;
}
const PrintBatchesListHeader = ({ headerRef, scrollPadding }: IProps) => {
	return (
		<section
			ref={headerRef}
			className={`listing-header listing-header--print-batches`}
			style={{
				paddingRight: scrollPadding > 0 ? scrollPadding + "px" : 0,
			}}
		>
			<span className="listing-header__label">Reference number</span>

			<span className="listing-header__label">Template name</span>

			<span className="listing-header__label">Color / width</span>

			<span className="listing-header__label">Total (print) quantity</span>

			<span className="listing-header__label ordersStatusListItem">Status</span>
		</section>
	);
};

export default PrintBatchesListHeader;
